import {
    Component,
    OnInit,
    Output,
    EventEmitter,
    Type as AngularCoreType
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { ControlType, ValidationInfo, ValidationMessageInfo, FormFieldBaseComponent } from '../form-field-base/form-field-base.component';
import { FormFieldPropertyEnum } from '../../../models/form-builder/form-field-property-enum.model';
import { FormField } from '../../../models/form-builder/form-field.model';
import { GridRowViewModel } from '../../../models/form-builder/grid-row.model';
import { FootnoteRowModel } from '../../../models/form-builder/footnote-row.model';
import { FormInstanceElement } from '../../../models/form-builder/form-instance-element.model';
import { GridCellDataForSerialization } from '../../../models/grid/grid-cell-data.model';
import {
    IntegerFieldType,
    RichTextFieldType,
    ShortTextFieldType
} from '../../../models/form-builder/form-field-types';
import { Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Keys } from '@progress/kendo-angular-common';
import { CellClickEvent, CellCloseEvent, EditEvent, CommandColumnComponent } from '@progress/kendo-angular-grid';

// Define an internally used class.
class ChildFormFieldAttribute {
    public fieldDefinitionClassName: string;

    public name: string;
    public displayName: string;

    public valueType: string;
}

// Note:  please note the 'providers' definition below, as it is needed.
//        Without it, you will get the following exception:
//
//             No value accessor for form control with unspecified name
//
// The above exception gets thrown when a component, in this case our
// base class, implements interface 'ControlValueAccessor' and does not
// provide the 'providers' definition below.  Implementing the
// 'ControlValueAccessor' interface allows a form field component to
// support [(ngModel)], so users of the component can use [(ngModel)].
@Component({
    selector: 'app-footnote-form-field',
    templateUrl: './footnote-form-field.component.html',
    styleUrls: ['./footnote-form-field.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: FootnoteFormFieldComponent,
            multi: true
        }
    ],
    standalone: false
})
export class FootnoteFormFieldComponent extends FormFieldBaseComponent implements OnInit {
    // Properties.
    @Output() onInit = new EventEmitter();

    private readonly formFieldNames: string[] =
        [
            FormFieldPropertyEnum.REQUIRED,
            FormFieldPropertyEnum.DISPLAY_NAME,
            FormFieldPropertyEnum.HELP_TEXT,
            FormFieldPropertyEnum.FORM_INSTANCE_SERVICE_REQUIRED, //TEAMS-424: KLW - Needed for the Form Renderer
        ];

    // (Some of these properties get confusing - it's not always easy to see how they are distguished from the base class's prperties like this.FormInstanceElement)
    // PJH - it's not clear how this is used or what role it plays
    private gridFormField: FormField = null;
    // PJH - gets initialized in ngOnit with empty childGridRows array, which will hold the foonotes. This array is then populated in
    // writeValueTriggered() on form load. This property is the [(ngModel)] for the HTML template's <app-grid-form-field> - so at the end
    // of the day, it is this that needs to get populated with grid rows
    // This FormInstanceElement is distinct from FormFieldBase's FormInstanceElement property and on save, values have to get copied to the base class
    private gridFormInstanceElement: FormInstanceElement = null;
    // PJH - I see this getting set in the GridFormInstanceElement setter, but I don't see it being used for anything
    private updatedGridFormInstanceElement: FormInstanceElement = null;
    // PJH - this is used (at least) to set information on the FormInstanceElement which contains the data for this footnote field
    private gridComponent: FormFieldBaseComponent = null;
    // The store of data to which the KendoGrid instanceis bound
    private footnotesForKendoGrid: FootnoteRowModel[];

    private editOfRowInProgress: boolean = false;

    private readonly gridChildFormFieldsAttributes: ChildFormFieldAttribute[] =
        [
            {
                fieldDefinitionClassName: ShortTextFieldType,
                name: 'c0',
                displayName: 'Id',
                valueType: 'text'
            },
            {
                fieldDefinitionClassName: ShortTextFieldType,
                name: 'c1',
                displayName: 'Text',
                valueType: 'text'
            }
        ];

    // Constructor.
    public constructor(private formBuilder: UntypedFormBuilder) {
        super();

        return;
    }

    // Implement abstract methods.
    public getProperties(): any {
        let hshOnInitProperties = {
            component: this,
            formField: this.FormField,
            properties: this.formFieldNames,

            saveConfigurationRequired: true,
            saveDataRequired: true,

            isFootnote: true
        };

        return (hshOnInitProperties);
    }

    // Life cycle methods.
    public ngOnInit(): void {
        if ((this.mode === 'design') ||
            (this.mode === 'preview') ||
            (this.mode === 'instance')) {
            // Build my form field definition.
            this.gridFormField = new FormField();
            this.gridFormField.isFootnote = true; // Added 05-13-2022.
            this.gridFormField.childFormFields = [];

            for (let iChildFF: number = 0; iChildFF < this.gridChildFormFieldsAttributes.length; iChildFF++) {
                let childFFAttrs: ChildFormFieldAttribute = this.gridChildFormFieldsAttributes[iChildFF];

                let childFormField: FormField = new FormField();
                childFormField.fieldDefinitionClassName = childFFAttrs.fieldDefinitionClassName;
                childFormField.name = childFFAttrs.name;
                childFormField.displayName = childFFAttrs.displayName;
                this.gridFormField.childFormFields.push(childFormField);
            }

            // If they have not been added, add my child form fields to my form field.
            let myFormField: FormField = this.FormField;

            if ((!myFormField.childFormFields) || (myFormField.childFormFields.length == 0)) {
                myFormField.childFormFields = this.gridFormField.childFormFields;
            }
            this.gridFormInstanceElement = new FormInstanceElement();
            this.gridFormInstanceElement.childGridRows = []; 
        }

        // Let my container know my properties.
        let hshOnInitProperties = this.getProperties();
        this.onInit.emit(hshOnInitProperties);

        return;
    }

    /* Kendo Grid Event Handlers */
    public handleEditRow(args: any) {
        if (this.ignoreEditEvent(args)) return;

        let fg = this.createFormGroupForFootnoteRowModel(args.dataItem);
        args.sender.editRow(args.rowIndex, fg);
    }

    public handleAddOrUpdate(event) {
        if (event.isNew) {
            if (!this.footnotesForKendoGrid) this.footnotesForKendoGrid = [];
            this.footnotesForKendoGrid.push(event.dataItem);
            this.handleCancel(event);
        } else {
            let savedItem = this.footnotesForKendoGrid.find(x => { return x.identifier == event.dataItem.identifier}); // Better to do this by position/order
            console.log(savedItem);
            savedItem.identifier = event.formGroup.value.identifier;
            savedItem.text = event.formGroup.value.text;
            console.log(savedItem);
            this.handleCancel(event);
        }
    }

    public handleRemove(event) {
        let footnote = this.footnotesForKendoGrid.filter(x => { return !x.isDeleted })[event.rowIndex];
        footnote.isDeleted = true;
    }

    public addRowToUI(args) {
        let newFootnote = new FootnoteRowModel();
        // set a default identifier
        newFootnote.identifier = args.sender.data.data.length + 1;
        args.sender.addRow(this.createFormGroupForFootnoteRowModel(newFootnote));
    }

    public handleCancel(event) {
        event.sender.closeRow(event.rowIndex);
    }

    // Override the method used to get my class.
    public getFormFieldClass(): AngularCoreType<any> {
        return (FootnoteFormFieldComponent);
    }

    // Define accessor methods.
    public get FootnoteData() {
        if (this.footnotesForKendoGrid)
        return this.footnotesForKendoGrid.filter(x => { return !x.isDeleted });
    }

    public get HasDesignConfiguration(): boolean {
        let bHasConfig: boolean =
            (this.Mode === 'design') &&
            (this.gridFormField && this.gridFormField.childFormFields) &&
            (this.gridFormField.childFormFields.length > 0);

        return (bHasConfig);
    }

    public get HasPreviewInstanceConfiguration(): boolean {
        let bHasConfig: boolean =
            ((this.Mode == 'preview') || (this.Mode == 'instance')) &&
            (this.gridFormField && this.gridFormField.childFormFields) &&
            (this.gridFormField.childFormFields.length > 0);

        return (bHasConfig);
    }

    public get FootnotesTitle(): string {
        let count = this.FootnoteData ? this.FootnoteData.length : 0;
        return `Footnotes for ${this.DisplayName} (${count})`;
    }
    
    protected formInstanceElementReceived(): void {
        throw "Footnote.formInstanceElementReceived() NOT IMPLEMENTED";
    }

    // This is called on form load, 
    protected writeValueTriggered(): void {
        if ((this.Mode === 'preview') || (this.Mode === 'instance')) {
            this.populateFootnotesForKendoGrid();
        }

        return;
    }

    // This gets called from FormRenderer via FormFieldWrapper when a Form Instance is saved
    public saveData(hshComponentDataToSave: any): void {
        if (!(this.footnotesForKendoGrid?.length > 0)) return;

        this.FormInstanceElement.childFootnoteRows = [];
        this.FormInstanceElement.totalFootnoteRows = this.footnotesForKendoGrid.length;

        // For each Kendo Footnote, convert it into a GridRowViewModel which FormRenderer and the backend knows how to deal with 
        for (let footnote of this.footnotesForKendoGrid) {
            let gridRowVM = new GridRowViewModel();
            gridRowVM.id = footnote.underLyingGridRowId;
            gridRowVM.isDeleted = footnote.isDeleted;
            gridRowVM.cellDataHash = {};

            let identifierGridCell = new GridCellDataForSerialization();
            identifierGridCell.valueType = 'text';
            identifierGridCell.textValue = footnote.identifier;
            gridRowVM.cellDataHash['c0'] = identifierGridCell;

            let textGridCell = new GridCellDataForSerialization();
            textGridCell.valueType = 'text';
            textGridCell.textValue = footnote.text;
            gridRowVM.cellDataHash['c1'] = textGridCell;
            
            this.FormInstanceElement.childFootnoteRows.push(gridRowVM);
        }
    }
    
    private createFormGroupForColumn(columnIndex: number, dataItem: FootnoteRowModel): UntypedFormGroup {
        if (columnIndex == 0) {
            return this.formBuilder.group({
                identifier: dataItem.identifier
            });
        } else if (columnIndex == 1) {
            return this.formBuilder.group({
                text: dataItem.text
            });
        }
    }

    private createFormGroupForFootnoteRowModel(dataItem: FootnoteRowModel): UntypedFormGroup {
        return this.formBuilder.group({
            identifier: dataItem.identifier,
            text: dataItem.text
        });
    }

    // On form load, converts FormInstanceElement.childFootnoteRows into FootnoteRowModel's which Kendo Grid works with
    private populateFootnotesForKendoGrid() {
        if (this.FormInstanceElement.childFootnoteRows) {
            let newGridFIE: FormInstanceElement = new FormInstanceElement();
            newGridFIE.id = this.gridFormInstanceElement.id;
            newGridFIE.formFieldId = this.gridFormInstanceElement.formFieldId;
            newGridFIE.childGridRows = this.FormInstanceElement.childFootnoteRows;
            newGridFIE.totalChildGridRows = this.FormInstanceElement.totalFootnoteRows;

            this.gridFormInstanceElement = newGridFIE;

            // New for Kendo
            if (this.footnotesForKendoGrid == null) {
                this.footnotesForKendoGrid = [];
            }

            // Convert the incoming childFootnoteRows which are GridRowViewModels into FootnoteRowModel which the Kendo grid deals with
            for (let footnote of this.FormInstanceElement.childFootnoteRows) {
                let footnoteRow = new FootnoteRowModel();
                footnoteRow.underLyingGridRowId = footnote.id;
                footnoteRow.identifier = footnote.cellDataHash['c0'].textValue;
                footnoteRow.text = footnote.cellDataHash['c1'].textValue;
                this.footnotesForKendoGrid.push(footnoteRow);
            }
        }
    }

    // Sometimes, when you click on an icon in the command column, it triggers events you don't want to handle 
    private ignoreEditEvent(args: any) {
        return args.column instanceof CommandColumnComponent || args.rowIndex < 0;
    }
}
