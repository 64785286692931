import { Component, EventEmitter, Input, OnInit, Output, Type as AngularCoreType, ViewEncapsulation } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { AsyncSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { FormFieldProcessingPhaseEnum } from '../../../enums/form-field-processing-phase.enum';
import { IGridRow } from '../../../interfaces/grid-row.interface';
import { FormFieldOnInitPropertyEnum } from '../../../models/form-builder/form-field-on-init-output-property.enum';
import { FormField } from '../../../models/form-builder/form-field.model';
import { FormInstanceElementForHistory } from '../../../models/form-builder/form-instance-element-for-history.model';
import { FormInstanceElement } from '../../../models/form-builder/form-instance-element.model';
import { FormFieldPropertyEnum } from '../../../models/form-builder/form-field-property-enum.model';
import { GridFormInstanceElementWrapper } from '../../../models/grid/grid-form-instance-element-wrapper.model';
import { FormInstanceService } from '../../../services/form-instance.service';
import { ButtonConfig } from '../../list-view/list-view-button-config.model';
import { ControlType, FormFieldBaseComponent } from '../form-field-base/form-field-base.component';
import { TextInputFormFieldBaseComponent } from '../input-form-field-base/text-input-form-field-base.component';

@Component({
    selector: 'app-rich-text-form-field',
    templateUrl: './rich-text-form-field.component.html',
    styleUrls: ['./rich-text-form-field.component.scss', '../form-fields.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: RichTextFormFieldComponent,
            multi: true
        }
    ],
    standalone: false
})
export class RichTextFormFieldComponent extends FormFieldBaseComponent implements OnInit {
    // Instance data.
    @Input() showHistoryLink: boolean = true;
    @Output() onInit = new EventEmitter();

    private readonly formFieldProperties: string[] =
        [
            FormFieldPropertyEnum.NAME,
            FormFieldPropertyEnum.FIELD_GROUP,
            FormFieldPropertyEnum.REQUIRED,
            FormFieldPropertyEnum.DISPLAY_NAME,
            FormFieldPropertyEnum.BLANK_VALUE,
            FormFieldPropertyEnum.HELP_TEXT,
            FormFieldPropertyEnum.PLACEHOLDER_TEXT,
            FormFieldPropertyEnum.TOOL_TIP,
            FormFieldPropertyEnum.MAX_LENGTH,
            FormFieldPropertyEnum.DISPLAY_FORMAT,
            FormFieldPropertyEnum.READ_ONLY,
            FormFieldPropertyEnum.INSTRUCTIONS_TEXT,
            FormFieldPropertyEnum.GRID_COLUMN_WIDTH
        ];

    // Added to convert KendoEditor's HTML to XHTML so publishing won't be broken
    private domParser: DOMParser = new DOMParser();
    private xmlSerializer: XMLSerializer = new XMLSerializer();
    private initialHtmlValue: string;

    private editor: any;
    private editorSubject: Subject<any> = new AsyncSubject();
    private readonly basic: string = 'bold italic underline superscript';
    private readonly bullets: string = 'bullist outdent indent';
    private readonly TOOLBARS: any = {
        "Simple Text": this.basic,
        "Simple Text with Bullets": `${this.basic} | ${this.bullets}`,
        "Just Bullets": this.bullets,
        "Rich Text": `${this.basic} subscript strikethrough fontselect formatselect forecolor | 
            alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | 
            table delete_col delete_row col_after col_before row_after row_before | undo redo | hr link`
    }

    private readonly displayFormats: string[] =
        [
            'Simple Text With Bullets',
            'Simple Text'
        ];

    private simpleText: string = 'bold italic underline';
    private simpleTextWithBullets: string = 'undo redo | bold italic underline | bullist numlist';
    private selectedToolbar = this.simpleTextWithBullets;

    // Constructor.
    public constructor(private formInstanceService: FormInstanceService = null) {
        super();

        return;
    }

    public getEditorCssDefaults(): string {
        return `
        .k-content {
            font-family:Arial;
        }`
    };

    // STUFF FOR HISTORY
    showHistory: boolean;
    showComparison: boolean;
    private listItems: any[];
    private buttonConfig = {
        right: [
            new ButtonConfig('Compare Selected', 'compare'),
            new ButtonConfig('Close History', 'close')
        ],
        item_actions: []
    };

    public get ShowHistoryLink() {
        return this.showHistoryLink && !this.ShowHistory && this.mode != 'design';
    }
    public get ListItems() {
        return this.listItems;
    }
    public get ButtonConfig() {
        return this.buttonConfig;
    }
    public get FormInstanceElementForHistory() {
        let ret = new FormInstanceElementForHistory();
        ret.id = this.FormInstanceElement.id;
        ret.formInstanceId = this.FormInstanceElement.formInstanceId;
        return ret;
    }

    // Handle control events.
    public doOnButtonClick(event): void {
        console.log(event);
        if (event.button == 'close') {
            this.showHistory = false;
            this.showComparison = false;
        } else if (event.button == 'compare') {
            this.showComparison = true;
        }
    }
    public onHistoryClick(event): void {
        this.formInstanceService.getFormInstanceElementHistory(this.FormInstanceElement.id).then(elements => {
            this.listItems = elements;
            this.showHistory = true;
        });
    }
    public onVersionRestore(event: FormInstanceElementForHistory): void {
        this.initialHtmlValue = event.value;
        this.FormInstanceElement.textValue = event.value;
        this.FormInstanceElement.UserUpdatedData = true;

        this.showHistory = false;
        this.versionToShow = null;
    }
    public versionToShow: FormInstanceElementForHistory;
    public onViewVersion(event: FormInstanceElementForHistory): void {
        this.versionToShow = event;
    }
    public onCloseHistory(event: any): void {
        this.showHistory = false;
        this.versionToShow = null;
    }
    public get ShowHistory(): boolean {
        return this.showHistory;
    }
    public set ShowHistory(val: boolean) {
        this.showHistory = val;
    }
    public get ShowComparison() {
        return this.showComparison;
    }
    public get VersionToShow(): FormInstanceElementForHistory {
        return this.versionToShow;
    }

    // Implement abstract methods.
    public getProperties(): any {
        let hshOnInitProperties = {
        };
        hshOnInitProperties[FormFieldOnInitPropertyEnum.COMPONENT] = this;
        hshOnInitProperties[FormFieldOnInitPropertyEnum.FORM_FIELD] = this.FormField;
        hshOnInitProperties[FormFieldOnInitPropertyEnum.PROPERTIES] = this.formFieldProperties;
        hshOnInitProperties[FormFieldOnInitPropertyEnum.DISPLAY_FORMAT_VALUES] = Object.keys(this.TOOLBARS);
        hshOnInitProperties[FormFieldOnInitPropertyEnum.PROPERTY_UPDATE_REQUIRED] = true;
        hshOnInitProperties[FormFieldOnInitPropertyEnum.REQUIRED_PREVIEW_INSTANCE_MODE_HEIGHT] = 150;
        hshOnInitProperties[FormFieldOnInitPropertyEnum.REQUIRED_PREVIEW_INSTANCE_MODE_HEIGHT_UNIT] = 'px';

        return (hshOnInitProperties);
    }

    // Override/implement the method used to get my class.
    public getFormFieldClass(): AngularCoreType<any> {
        return (RichTextFormFieldComponent);
    }

    public get canHaveFieldConditionalLogic(): boolean {
        return false;
    }

    // Implement Angular life cycle methods.
    public ngOnInit(): void {
        this.initRichTextControl();

        // Set a default constraint value.
        if (this.FormField) {
            if (!this.FormField.constraintName) {
                this.FormField.constraintName = "None";
            }
        }

        // Assemble my proeprties hash.
        // USED IN NGIFs in FORM FIELD PROPERTIES
        let hshOnInitProperties = this.getProperties();

        this.onInit.emit(hshOnInitProperties);
    }

    public propertyUpdated(formField: FormField, propertyName: string): void {
        if (propertyName === 'displayFormat') {
            this.initRichTextControl();
        } else if (propertyName == 'placeholderText') {
            this.editor.setContent(this.FormField.placeholderText);
        }
    }

    // Can be deleted
    //public handleEditorInit(e: any) {
    //    this.editor = e.editor;
    //    this.editorSubject.next(this.editor);
    //    this.editorSubject.complete();

    //    if (this.editor.getContent() == '' && this.FormField.placeholderText != null) {
    //        this.editor.setContent(this.FormField.placeholderText);
    //    }
    //}

    public initRichTextControl(): void {
        this.selectedToolbar = this.TOOLBARS[this.FormField.displayFormat];
        if (this.selectedToolbar == null) {
            this.selectedToolbar = this.TOOLBARS["Simple Text with Bullets"];
        }
    }

    public includeSimpleTextButtons(): boolean {
        return !this.FormField.displayFormat ||
            ['Simple Text', 'Simple Text with Bullets', 'Rich Text'].indexOf(this.FormField.displayFormat) > -1;
    }

    public includeBulletButtons(): boolean {
        return !this.FormField.displayFormat ||
            ['Just Bullets', 'Simple Text with Bullets', 'Rich Text'].indexOf(this.FormField.displayFormat) > -1;
    }

    public includeAllButtons(): boolean {
        return ['Rich Text'].indexOf(this.FormField.displayFormat) > -1;
    }

    protected notifyValueChanged(): void {
        super.notifyValueChanged();

        if ((this.FormControl != null) && (this.FormInstanceElement != null))
            this.FormInstanceElement.textValue = this.convertToXHTML(this.FormControl.value);
    }

    public formFieldUpdated(): void {
        // 03-14-2024 note:  added this method so it can be called by the field conditional
        //                   logic to indicate that a component's form field has been updated.

        // Make sure the form control's disabled state agrees with the form field's 'isReadOnly' attribute.
        this.toggleFormControlDisabledBasedOnReadOnlyAttribute()
    }

    protected formInstanceElementReceived(): void {
        if ((this.Mode === 'preview') || (this.Mode === 'instance')) {
            if (this.ControlType === ControlType.REACTIVE_FORMS) {
                this.setupTextFormControl();
            }
        }
    }

    // Note: This is not using Reactive Forms currently
    public get HtmlValue(): string {
        if (!this.initialHtmlValue) {
            this.initialHtmlValue = this.FormInstanceElement?.textValue;
        }
        return this.initialHtmlValue;
    }

    public set HtmlValue(value: string) {
        if (this.FormInstanceElement != null) {
            this.FormInstanceElement.textValue = this.convertToXHTML(value);;
        }
    }

    // Convert Kendo Editor's HTML into XHTML (Has to be XHTML for Doc Publishing)
    private convertToXHTML(html: string) {
        let xhtml = "";
        // DOMParser.parseFromString() creates a full HTML doc but we only want the childen of the <body> tag
        var nodes = Array.from(this.domParser.parseFromString(html, 'text/html').body.children);
        for (let node of nodes) {
            // serialize each node, removing any namespaces (which mess up stylesheets' processing of the resulting XHTML)
            xhtml += this.xmlSerializer.serializeToString(node)?.replace(/xmlns="[^"]+"/, '');
        }

        this.FormInstanceElement.UserUpdatedData = true;

        return xhtml;
    }

    protected writeValueTriggered(): void {
        if ((this.Mode === 'preview') || (this.Mode === 'instance')) {
            if (this.ControlType === ControlType.REACTIVE_FORMS) {
                this.SetupFormControlFromWriteValue();
            }
        }
    }

    // Added for VNEXT-1355 to override call to base class saveData() which undoes the essential work of this.convertToXHTML()
    public saveData(formInstance: any): void {
        // NOOP by design
    }

    // Override the getDisplayValue() base class method.
    // Define a method that allows a component to return its display value.
    public getDisplayValue(formFieldParam: FormField, formInstanceElementParam: FormInstanceElement): string {
        if (!formInstanceElementParam.UserUpdatedData) {
            // Set a default value.
            if (formInstanceElementParam.textValue == null) formInstanceElementParam.TextValue = '';
        }

        return (formInstanceElementParam.textValue);
    }

    public pseudoStatic_pasteValue(value: string, elementWrapper: GridFormInstanceElementWrapper, formField: FormField): void {
        TextInputFormFieldBaseComponent.pasteValue(value, elementWrapper);
    }

    public pseudoStatic_getDisplayValue(formFieldParam: FormField, formInstanceElementParam: FormInstanceElement, gridRow: IGridRow, processingPhase: FormFieldProcessingPhaseEnum): string {
        if (!formInstanceElementParam.UserUpdatedData) {
            if (formInstanceElementParam.textValue == null) formInstanceElementParam.TextValue = '';
        }

        return formInstanceElementParam.textValue;
    }

    // 04-05-2022 note:  added the method implementation/override.
    public get substantivelyChangedLogicApplies(): boolean {
        return true;
    }

    // Override
    // For maxLength property it returns an asynchronous validator function
    // according to expectations of Angular's FormControl component used in
    // reactive forms
    // https://www.tiny.cloud/blog/angular-rich-text-editor/
    // https://angular.io/guide/form-validation#async-validation
    protected asyncValidatorFn(validationPropertyName: string, formField: FormField): AsyncValidatorFn {
        if (validationPropertyName == 'maxLength') {
            let fn = (control: AbstractControl): Observable<ValidationErrors | null> => {
                return this.editorSubject.pipe(
                    map(editor => {
                        let characterCount = editor.plugins.wordcount.body.getCharacterCount();
                        let maxLength = formField.maxLength;

                        if (characterCount > maxLength) {
                            return {
                                maxlength: {
                                    requiredLength: maxLength,
                                    actualLength: characterCount
                                }
                            }
                        } else {
                            return null;
                        }
                    })
                );
            };
            return fn;
        }
    };
}
