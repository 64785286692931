import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DocumentPublishingSettings } from '../../models/document-publishing-settings.model';
import { DocumentPublishingService } from '../../services/document-publishing.service';
import { PickerItem } from '../../models/picker-item.model';

@Component({
    selector: 'app-document-publish-settings',
    templateUrl: './document-publishing-settings-editor.dialog.html',
    styleUrls: ['./document-publishing-settings-editor.dialog.scss'],
    standalone: false
})
export class DocumentPublishingSettingsEditorDialog implements OnInit {


    public readonly VIEWABLE_BY_ALL: string = "ALL";
    public readonly VIEWABLE_BY_NONE: string = "NONE";
    public readonly VIEWABLE_BY_SITE_ADMINS: string = "SITE_ADMIN";
    public readonly VIEWABLE_BY_GROUP: string = "GROUP";

    public title: string;
    public outputTypes: any[] = [
        { name: 'PDF' },
        { name: 'Word' },
        { name: 'Excel' },
        { name: 'HTML' }
    ];
    public settings: DocumentPublishingSettings;
    public ngFormGroup: UntypedFormGroup;

    private viewableByGroup: string;

    constructor(private fb: UntypedFormBuilder, @Inject(MAT_DIALOG_DATA) data, private documentPublishingService: DocumentPublishingService) {
        if (data) {
            this.settings = data;
            this.title = `Edit ${data.name}`
        } else {
            this.title = 'New Document Publish Settings';
        }

        if (this.settings) {
            this.documentPublishingService.getDocumentPublishingSettings(this.settings.id).then(settings => {
                this.ngFormGroup = this.fb.group({
                    id:[settings.id],
                    name: [settings.name, Validators.required],
                    outputType: [settings.outputType, Validators.required],
                    viewableBy: settings.viewableBy || this.VIEWABLE_BY_SITE_ADMINS,
                    viewableByMaxGroup: settings.viewableByMaxGroup,
                    xslt: [settings.xslt, Validators.required]
                });
            });
        } else {
            this.ngFormGroup = this.fb.group({
                name: [],
                outputType: [],
                viewableBy: this.VIEWABLE_BY_SITE_ADMINS,
                xslt: []
            });
        }
    }

    ngOnInit(): void {
    }

    public get ShowGroupPicker() {
        return this.ngFormGroup.value.viewableBy == this.VIEWABLE_BY_GROUP && !this.ngFormGroup.value.viewableByMaxGroup;
    }

    public get ShowSelectedMaxGroup() {
        return this.ngFormGroup.value.viewableBy == this.VIEWABLE_BY_GROUP && this.ngFormGroup.value.viewableByMaxGroup;
    }

    public onGroupSet(pickerItem: PickerItem): void {
        this.ngFormGroup.value.viewableByMaxGroup = pickerItem.displayName;
        this.ngFormGroup.markAsDirty();
    }

}
