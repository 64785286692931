<div style="padding: 15px; background-color: #fafafa; margin-top: 64px; height: 100%;">
    <div *ngIf="Url != '/unavailable'">
        {{Message}}
    </div>

    <div *ngIf="Url == '/unavailable'" class="unavailable">
        <p>
            1/22/2025: OMB Collect is currently offline for scheduled maintenance. Access will be restored later this morning.
            <!--
                <br/>
                If you are receiving this message outside that timeframe, please contact <a href="mailto:maxsupport@max.gov">maxsupport&#64;max.gov</a>.</p>
                <br/>
            -->
        </p>
        <p>
            Thank you,<br />
            The OMB Collect Team
        </p>
    </div>
</div>
