import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

export class RouterLinkData {
    public relativeURL: string = '';
    public title: string = '';
}

@Component({
    selector: 'app-router-link',
    templateUrl: './router-link.component.html',
    styleUrls: ['./router-link.component.scss'],
    standalone: false
})
export class RouterLinkComponent implements OnInit {
    // Properties.
    // Inputs.
    //@Input() relativeURL: string;
    //@Input() title: string;
    @Input() linkData: RouterLinkData = new RouterLinkData();

    // Outputs.
    //@Output() linkClicked: EventEmitter<RouterLinkData> = new EventEmitter<RouterLinkData>();

    // Constructor.
    constructor() { }

    // Life cycle methods.
    public ngOnInit(): void {
    }

    // Methods called by my HTML.
    public get LinkData(): RouterLinkData {
        return this.linkData;
    }
}
