import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormControl } from '@angular/forms'; // Used for Reactive Forms

export class EditValueResult {
    public value: string;
    public hasDateAndTimeSuffix: boolean = false;
}

@Component({
    selector: 'app-edit-value',
    templateUrl: './edit-value.dialog.html',
    styleUrls: ['./edit-value.dialog.scss'],
    standalone: false
})
export class EditValueDialog implements OnInit {
    /*
    private value: string;
    private hasDateAndTimeSuffix: boolean = false;
    */
    private editValueResult: EditValueResult = new EditValueResult();
    private valueFormControl: UntypedFormControl = null;
    private hasDateTimeSuffixFormControl: UntypedFormControl = null;

    public constructor(@Inject(MAT_DIALOG_DATA) public existingValue: string, private dialogRef: MatDialogRef<EditValueDialog>) {
    }

    public ngOnInit(): void {
        this.editValueResult.value = this.existingValue;

        this.setupFormControls();
    }

    // Methods called by HTML code.
    public get Value(): string {
        return this.editValueResult.value;
    }
    /*
    public set Value(value: string) {
        this.value = value;
    }

    public get HasDateAndTimeSuffix(): boolean {
        return this.hasDateAndTimeSuffix;
    }
    public set HasDateAndTimeSuffix(value: boolean) {
        this.hasDateAndTimeSuffix = value;
    }
    */

    public get ValueFormControl(): UntypedFormControl {
        return this.valueFormControl;
    }
    public get HasDateTimeSuffixFormControl(): UntypedFormControl {
        return this.hasDateTimeSuffixFormControl;
    }

    public get OkButtonDisabled(): boolean {
        return (this.editValueResult.value == null) || (this.editValueResult.value.trim() == '');
    }

    // Handle control events.
    public okButtonClicked(): void {
        this.dialogRef.close(this.editValueResult);
    }

    public handleOnModelChange(propertyName: string): void {
    }

    // Helper methods.
    private setupFormControls(): void {
        let valueControlProperties = {
            value: this.editValueResult.value,
            disabled: false
        };
        this.valueFormControl = new UntypedFormControl(valueControlProperties);
        this.valueFormControl.valueChanges.subscribe(updatedValue => {
            this.editValueResult.value = updatedValue;
        });

        this.hasDateTimeSuffixFormControl = new UntypedFormControl({ value: false, disabled: false });
        this.hasDateTimeSuffixFormControl.valueChanges.subscribe(updatedValue => {
            this.editValueResult.hasDateAndTimeSuffix = updatedValue;
        })
    }
}
