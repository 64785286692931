import {
    Component, OnDestroy, OnInit,
    Renderer2, Type as AngularCoreType
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { CurrentUserService } from '../../../../security/current-user.service';
import { FormFieldProcessingPhaseEnum } from '../../../enums/form-field-processing-phase.enum';
import { IGridRow } from '../../../interfaces/grid-row.interface';
import { CascadingDropDownFormFieldConfig } from '../../../models/cascading-dropdown/cascading-dropdown-config.model';
import { CascadingDropDownFormFieldData } from '../../../models/cascading-dropdown/cascading-dropdown-data.model';
import { FormField } from '../../../models/form-builder/form-field.model';
import { FormInstanceElement, FormInstanceElementValueTypeEnum } from '../../../models/form-builder/form-instance-element.model';
import { GridFormInstanceElementWrapper } from '../../../models/grid/grid-form-instance-element-wrapper.model';
import { CascadingDropDownFormFieldBaseComponent } from '../drop-down-form-field-base/drop-down-form-field-base.component';

// Note:  please note the 'providers' definition below, as it is needed.
//        Without it, you will get the following exception:
//
//             No value accessor for form control with unspecified name
//
// The above exception gets thrown when a component, in this case our
// base class, implements interface 'ControlValueAccessor' and does not
// provide the 'providers' definition below.  Implementing the
// 'ControlValueAccessor' interface allows a form field component to
// support [(ngMode)], so users of the component can use [(ngModel)].
@Component({
    selector: 'app-cascading-drop-down-form-field',
    templateUrl: '../drop-down-form-field-base/drop-down-form-field-base.component.html',
    styleUrls: [
        '../drop-down-form-field-base/drop-down-form-field-base.component.scss',
        '../form-fields.scss'
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: CascadingDropDownFormFieldComponent,
            multi: true
        }
    ],
    standalone: false
})

export class CascadingDropDownFormFieldComponent extends CascadingDropDownFormFieldBaseComponent implements OnInit, OnDestroy {
    // Instance data.
    // Note: all instance data is now in base class CascadingDropDownFormFieldBaseComponent.

    // Constructor.
    public constructor(private renderer: Renderer2,
        private injectedCurrentUserServiceForBaseClass: CurrentUserService)
    {
        super(injectedCurrentUserServiceForBaseClass);

        return;
    }

    // Life cycle methods.
    public ngOnInit(): void {
        super.ngOnInit();
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    // Accessor methods.
    // Note:  this methods have been moved into my base class.

    // Implement protected methods defined in my base class.
    // Handle getting this field's form instance element.

    // Override the getDisplayValue() base class method.
    // Define a method that allows a component to return its display value.
    public pseudoStatic_getDisplayValue(formFieldParam: FormField,
        formInstanceElementParam: FormInstanceElement,
        gridRow: IGridRow,
        processingPhase: FormFieldProcessingPhaseEnum): string {
        let displayValue: string = '';

        if ((!formInstanceElementParam.UserUpdatedData) || (!formInstanceElementParam.textValue)) {
            // Set a default value.
            formInstanceElementParam.TextValue = '';
        } else if ((formFieldParam.jsonConfig != null) && (formFieldParam.jsonConfig.trim() != '') && (formInstanceElementParam.textValue != null) && (formInstanceElementParam.textValue.trim() != '')) {
            let config: CascadingDropDownFormFieldConfig = CascadingDropDownFormFieldConfig.parseJsonConfig(formFieldParam.jsonConfig);

            displayValue = CascadingDropDownFormFieldData.getDisplayValue(config, formInstanceElementParam.textValue);
        }

        return displayValue;
    }

    public pseudoStatic_pasteValue(value: string, elementWrapper: GridFormInstanceElementWrapper, formField: FormField): void {
        if ((formField.jsonConfig != null) && (formField.jsonConfig.trim() != '')) {
            elementWrapper.formInstanceElement.TextValue = value;
            elementWrapper.formInstanceElement.valueType = FormInstanceElementValueTypeEnum.TypeText;
            let config: CascadingDropDownFormFieldConfig = CascadingDropDownFormFieldConfig.parseJsonConfig(formField.jsonConfig);
            elementWrapper.standinDisplayValue = CascadingDropDownFormFieldData.getDisplayValue(config, elementWrapper.formInstanceElement.TextValue);
        }
    }

    // Override a method used to get my class.
    public getFormFieldClass(): AngularCoreType<any> {
        return (CascadingDropDownFormFieldComponent);
    }
}
