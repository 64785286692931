import { Component, EventEmitter, OnInit, Output, Type as AngularCoreType, } from '@angular/core';
import { ValidatorFn, AsyncValidatorFn } from '@angular/forms';
import { FormFieldProcessingPhaseEnum } from '../../../enums/form-field-processing-phase.enum';
import { IGridRow } from '../../../interfaces/grid-row.interface';
import { IFieldNameToFormField } from '../../../interfaces/iform-field-component';
import { FormFieldPropertyEnum } from '../../../models/form-builder/form-field-property-enum.model';
import { FormField } from '../../../models/form-builder/form-field.model';
import { FormInstanceElement } from '../../../models/form-builder/form-instance-element.model';
import { FormFieldBaseComponent, ValidationMessageInfo } from '../form-field-base/form-field-base.component';

@Component({
    selector: 'app-url-builder-form-field',
    templateUrl: './url-builder-form-field.component.html',
    styleUrls: ['./url-builder-form-field.component.scss'],
    standalone: false
})
export class UrlBuilderFormFieldComponent extends FormFieldBaseComponent implements OnInit {
    @Output() onInit = new EventEmitter();

    private readonly formFieldProperties: string[] =
        [
            FormFieldPropertyEnum.DISPLAY_NAME,
            FormFieldPropertyEnum.TOOL_TIP,
            FormFieldPropertyEnum.URL_BUILDER,
            FormFieldPropertyEnum.URL_BUILDER_LABEL,
            FormFieldPropertyEnum.INSTRUCTIONS_TEXT,
            FormFieldPropertyEnum.GRID_COLUMN_WIDTH
        ];

    constructor() {
        super();
    }

    public ngOnInit(): void {
        this.onInit.emit(this.getProperties());
    }

    public getFormFieldClass(): AngularCoreType<any> {
        return UrlBuilderFormFieldComponent;
    }

    protected writeValueTriggered(): void {
        super.writeValueTriggered();
    }

    protected buildValidatorFunctionsAndMessages(
        arrValidatorsParam: ValidatorFn[],
        arrValidationMessagesParam: ValidationMessageInfo[],
        arrAsyncValidatorsParam?: AsyncValidatorFn[]): void {
    }

    public receiveFormFieldNames(formFieldNamesParam: string[], hshColNameToFormFieldParam: IFieldNameToFormField): void {
    }

    public propertyUpdated(formField: FormField, propertyName: string): void {
    }

    public requiresFieldValueUpdate(): boolean {
        return true;
    }

    public formFieldValueUpdated(iColIndex: number, formFieldParam: FormField, formInstanceElement: FormInstanceElement, gridRow: IGridRow): boolean {
        return false;
    }

    public pseudoStatic_getDisplayValue(formFieldParam: FormField, formInstanceElementParam: FormInstanceElement, gridRow: IGridRow, processingPhase: FormFieldProcessingPhaseEnum): string {
        return 'pseudoStatic_getDisplayValue';
    }

    public getProperties(): any {
        let hshEventProperties = {
            component: this,
            displayFormatValues: [],
            formField: this.FormField,
            properties: this.formFieldProperties,
            formFieldNamesRequired: false,
            formFieldValueUpdatesRequired: false,
            propertyUpdateRequired: false
        };

        return (hshEventProperties);
    }

    public hasNumericData(): boolean {
        return false;
    }

    public get ButtonLabel(): string {
        let label = this.formField.transientUrlBuilderButtonLabel?.trim();
        if (label) {
            return label;
        } else {
            return 'Open';
        }
    }

}
