import { Component } from "@angular/core";



@Component({
    selector: 'app-DO-NOT-USE-kendo-grid-base',
    template: '',
    standalone: false
})
export abstract class KendoGridBaseComponent {



    //VNEXT-980: KLW - Property to set the number of Kendo grid rows to display
    public static CalculateGridHeight(rowHeight: number,
        toolbarHeadersFilters: number,
        detailRowHeight: number,
        extraHeightWhenEditingRow: number,
        rowCount: number,
        rowsDisplayed: number,
        wrapHeaderTextEnabled: boolean,
        showFooter: boolean)
    {
        let gridHeight: number;
        // let rowHeight: number = 23.14; // 23.14;
        // let toolbarHeadersFilters = 107; // height of grid with no rows
        let toolbarHeadersFiltersDetailRowHeight: number = toolbarHeadersFilters + detailRowHeight + extraHeightWhenEditingRow;

        if (rowsDisplayed < 1) {
            rowsDisplayed = 5;
        } 

        // The goal here is to calculate a reasonable fixed height for the grid when there are more than a few records
        // When there are fewer records the returned value of gridHeight will be undefined and when passed to Kendo Grid's [height] @Input
        // that will cause it to automatically size itself. The advantage of this is that tall rows will be displayed correctly
        // I played around a bit with the cutoff for this, and this seems to work pretty well.

        if (rowCount <= rowsDisplayed) {
            rowsDisplayed = rowCount;
        }

        // 05-03-2024:  add to the displayed row count if we have wrapped header text and/or are showing a footer.
        if (wrapHeaderTextEnabled)
            rowsDisplayed += 2;
        if (showFooter)
            rowsDisplayed += 1;

        gridHeight = (rowHeight * rowsDisplayed) + toolbarHeadersFiltersDetailRowHeight;

        return gridHeight;
    }


}



