import { FormField } from '../form-field.model';
import { FormInstanceElement } from '../form-instance-element.model';
import { NumericFieldDefinitionLogicBase } from './numeric-compare-base-field-def-logic';
import { IGridRow } from '../../../interfaces/grid-row.interface';
import { FormFieldProcessingPhaseEnum } from '../../../enums/form-field-processing-phase.enum';
import { FormFieldBaseComponent } from '../../../components/form-fields/form-field-base/form-field-base.component';
import { FormInstanceGridRow } from '../../site-content/form-instance-grid-row.model';
import { IFieldDefinitionLogic } from '../../../interfaces/ifield-definition-logic.interface';

export class DecimalFieldDefLogic extends NumericFieldDefinitionLogicBase implements IFieldDefinitionLogic {
    // Constructor.
    public constructor() {
        super();
    };

    // pasteDataForInto():  only allow the pasting of values that are valid for a given form field.
    public pasteDataForInto(formField: FormField, formInstanceElement: FormInstanceElement, pasteValue: string): void {
        if (pasteValue != null) {
            let floatValue: number = parseFloat(pasteValue);
            formInstanceElement.decimalValue = floatValue;
        }

        // TO DO:  ADD VALIDATION TO THIS METHOD.
    }

    // Sorting.
    public compareAscendingFor(formField: FormField, value1: FormInstanceElement, value2: FormInstanceElement): number {
        let compareResult: number = 0;

        if ((value1.decimalValue != null) && (value2.decimalValue != null)) {
            if (value1.decimalValue == value2.decimalValue)
                compareResult = 0;
            else if (value1.decimalValue > value2.decimalValue)
                compareResult = 1;
            else
                compareResult = -1;
        } else if (value1.decimalValue != null) {
            compareResult = 1;
        } else {
            compareResult = -1;
        }

        return compareResult;
    }

    public getDisplayValue(formFieldParam: FormField, formInstanceElementParam: FormInstanceElement, gridRow: IGridRow, processingPhase: FormFieldProcessingPhaseEnum, gridRowColumnDefs: FormField[] = null): string {
        if (!formInstanceElementParam.UserUpdatedData) {
            FormFieldBaseComponent.setDefaultDecimalValueFor(formFieldParam, formInstanceElementParam);
        }

        let strValue: string = (formInstanceElementParam.decimalValue ?
            new Intl.NumberFormat('en-us', { minimumFractionDigits: 0 }).format(formInstanceElementParam.decimalValue) :
            '0');

        return strValue;
    }

    public getNumericValue(formFieldParam: FormField, otherFormFields: FormField[], formInstanceElementParam: FormInstanceElement, gridRow: IGridRow, processingPhase: FormFieldProcessingPhaseEnum): number {
        if (!formInstanceElementParam.UserUpdatedData) {
            FormFieldBaseComponent.setDefaultDecimalValueFor(formFieldParam, formInstanceElementParam);
        }

        return (formInstanceElementParam.decimalValue ? formInstanceElementParam.decimalValue : 0);
    }

    // Implement protected methods.
    protected formatValueForCompare(value: any): any {
        let result: number = 0.0;

        if (value != null) {
            let valueAsString: string = value.toString();
            valueAsString = valueAsString.replace(/,/g,""); // A formatted value could contain formatting characters that will confuse parseFloat().
            result = parseFloat(valueAsString);
        }            

        return result;
    }

    public includeInFormTemplateInstancesGrid(): boolean {
        return true;
    }

    public unpackTemplateInstancesRow(formField: FormField, colValue: FormInstanceElement, row: FormInstanceGridRow): any {
        let fieldValue: any = colValue.decimalValue != null ? colValue.decimalValue : 0;
        return fieldValue;
    }

    public get canParticipateInFlexFieldBehavior(): boolean {
        return true;
    }
}
