<!--
    PJH - 12/11/2024 - this component (HTML and TS) were modified to work with flex fields for VNEXT-1534
    
    This component defines a <mat-form-field> which at its core contains an input.
    This is the input the user types into which triggers the call to the server which looks up matching options
    It can also display a chip list when multi select is configured

    If the field making use of this AutoCompleteComponent is a flex field, the options that the server will return
    Come from ListConstraintValues, not the field's own SelectOptions

    See in the TS code for calls to the server (look for the autocompleteServiceSearch() method)
-->
<mat-form-field appearance="outline" [floatLabel]="'always'" class="full-width" [hideRequiredMarker]="this.HideRequiredMarker">

    <mat-label class="field-label">{{this.Title}} <span *ngIf="this.Required" style="color:red">*</span></mat-label>


    <div *ngIf="this.ShowChipList; else noChipList">
        <div class="chip-list-wrapper">
            <mat-chip-grid #chipList
                           [formControl]="chipControl"
                           [required]="this.Required">
                <ng-container *ngFor="let select of SelectedData">
                    <mat-chip-row (keyup.delete)="removeChip(select)"
                                  [removable]="true"
                                  class="mat-elevation-z2 regular-chip">
                        {{ select.item }}
                        <mat-icon class="mat-chip-remove" (click)="removeChip(select)">cancel</mat-icon>
                    </mat-chip-row>
                </ng-container>
                <mat-chip-row class="mat-elevation-z3 remove-all-chip"
                              *ngIf="this.ShowRemoveAll" (click)="this.removeAllChips()" (keyup.enter)="this.removeAllChips()">
                    Remove All
                </mat-chip-row>

            </mat-chip-grid>
        </div>

        <div class="input-group">
            <input #trigger="matAutocompleteTrigger"
                   #autocompleteInput
                   (blur)="this.blurred()"
                   matInput
                   type="text"
                   class="input-styling full-width"
                   [matAutocomplete]="auto"
                   [matChipInputFor]="chipList"
                   [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                   [formControl]="selectControl"
                   (focusout)="this.clearOptions()"
                   (keydown.backspace)="this.preventBackspaceExitingFocus()">

            <mat-icon (click)="this.setTriggerStatusIconClick()"
                      class="custom-icon custom-form-field-icon">
                {{this.DropDownIcon}}
            </mat-icon>
        </div>

        <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let data of filteredData | async">
                <div (click)="optionClicked($event, data)">
                    <mat-checkbox [checked]="data.selected"
                                  (change)="toggleSelection(data)"
                                  (click)="$event.stopPropagation()">
                        {{ data.item }}
                    </mat-checkbox>
                </div>
            </mat-option>
        </mat-autocomplete>
    </div>

    <ng-template #noChipList>
        <div class="input-group">
            <!--
                In the TS, this.selectControl is subscribed to for changes, and these changes result in calls to the server for options based on
                what the user has typed in so far
            -->
            <input #trigger="matAutocompleteTrigger"
                   #autocompleteInput
                   (blur)="this.singleBlurred()"
                   matInput
                   type="text"
                   class="input-styling full-width"
                   [matAutocomplete]="auto"
                   [required]="this.Required"
                   [formControl]="selectControl"
                   (focusout)="this.clearOptions()"
                   (keydown.backspace)="this.preventBackspaceExitingFocus()">
        </div>

        <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let data of filteredData | async"
                        [value]="data.item"
                        (onSelectionChange)="optionSelectedSingle($event, data)">
                {{ data.item }}
            </mat-option>
        </mat-autocomplete>
    </ng-template>

</mat-form-field>







