import {
    Component,
    Renderer2,
    AfterViewInit
} from '@angular/core';

//import { FormFieldBaseComponent } from '../form-field-base/form-field-base.component';
import { InputFormFieldBaseComponent } from '../input-form-field-base/input-form-field-base.component';

@Component({
    selector: 'app-numeric-input-form-field-base',
    templateUrl: './num-input-form-field-base.component.html',
    styleUrls: ['./num-input-form-field-base.component.scss'],
    standalone: false
})
export abstract class NumericInputFormFieldBaseComponent extends InputFormFieldBaseComponent implements AfterViewInit {
    // Properties.
    // None at this time.

    // Constructor.
    constructor(protected renderer: Renderer2) {
        super(renderer);

        return;
    }

    // Implement methods available to derived classes.
    //
    // Note:  the following methods have to be public
    //        as they are called from .html code.
    public get ShowFormattedValue(): boolean {
        //return (this.bShowFormattedValue);
        return (this.FormField ? this.FormField.showPreview : false);
    }

    public get DesignModePreviewValue(): string {
        let previewValue: string = this.DefaultValue;

        if (previewValue == '') {
            previewValue = '0';
        }

        return (previewValue);
    }

    public get DesignModeOuterDivCssClass(): string {
        let cssClassName: string = null;

        if (this.HasTooltipText) {
            if (this.ShowFormattedValue) {
                cssClassName = 'design-mode-with-tooltip-with-formatted-value-div';
            } else {
                cssClassName = 'design-mode-with-tooltip-without-formatted-value-div';
            }
        } else {
            if (this.ShowFormattedValue) {
                cssClassName = 'design-mode-with-formatted-value-div';
            } else {
                cssClassName = 'design-mode-without-formatted-value-div';
            }
        }

        return (cssClassName);
    }

    public get PreviewInstanceModesOutDivCssClass(): string {
        let cssClassName: string = null;

        if (this.HasTooltipText) {
            if (this.ShowFormattedValue) {
                cssClassName = 'input-with-tooltip-with-formatted-value-div';
            } else {
                cssClassName = 'input-with-tooltip-without-formatted-value-div';
            }
        } else {
            if (this.ShowFormattedValue) {
                cssClassName = 'input-with-formatted-value-div';
            } else {
                cssClassName = 'input-without-formatted-value-div';
            }
        }

        return (cssClassName);
    }

}
