import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter, ComponentFactoryResolver, ViewContainerRef, Renderer2, Type as AngularCoreType, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { FormField } from '../../../models/form-builder/form-field.model';
import { FormFieldPropertyEnum } from '../../../models/form-builder/form-field-property-enum.model';
import { FormFieldBaseComponent } from '../form-field-base/form-field-base.component';
import { ShowToFieldType } from '../../../models/form-builder/form-field-types';
import { StringUtil } from '../../../utility-classes/string.util';

@Component({
    selector: 'app-show-to-hide-from',
    templateUrl: './show-to-hide-from.component.html',
    styleUrls: ['./show-to-hide-from.component.scss', '../form-fields.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: ShowToHideFromComponent,
            multi: true
        }
    ],
    standalone: false
})
export class ShowToHideFromComponent extends FormFieldBaseComponent implements OnInit, AfterViewInit {
    // Properties.
    // Output(s).
    @Output() onInit = new EventEmitter();

    // Read only properties.
    protected readonly formFieldProperties: string[] =
        [
            FormFieldPropertyEnum.NAME,
            FormFieldPropertyEnum.DISPLAY_NAME,
            FormFieldPropertyEnum.FIELD_GROUP,
            FormFieldPropertyEnum.DISPLAY_FORMAT, // NOTE:  temporarily using display format to display optional validations.
            FormFieldPropertyEnum.ROLE_NAMES
        ];

    // Additional properties.
    private isShowTo: boolean; // Otherwise it is a "Hide From".
    //private specifiedRoleNames: string[] = []; // If this contains one or more role names, this field's conditional logic is operable.

    //private matchingBeginOrEndField: FormField = null;

    // Constructor.
    public constructor(private renderer: Renderer2) {
        super();
    }

    // Life cycle methods.
    public ngOnInit(): void {
        // Determine whether this field is a "Show To" or a "Hide From" conditional.
        this.isShowTo = (this.FormField.fieldDefinitionClassName == ShowToFieldType);

        // Emit my properties.
        let hshOnInitProperties = this.getProperties();
        this.onInit.emit(hshOnInitProperties);
    }

    public ngAfterViewInit(): void {
        return;
    }

    // Implement abstract methods.
    public getProperties(): any {
        let hshOnInitProperties = {
            component: this,
            formField: this.FormField,
            properties: this.formFieldProperties,

            propertyUpdateRequired: this.FormField.isConditionalBeginElement, // Need to update the end field's 'beginFieldName' property.
            displayPropertiesEdit: this.FormField.isConditionalBeginElement
        };

        return (hshOnInitProperties);
    }

    // Override the method used to get my class.
    public getFormFieldClass(): AngularCoreType<any> {
        return ShowToHideFromComponent;
    }

    public propertyUpdated(formField: FormField, propertyName: string): void {
        if (propertyName == 'displayName') {
            /*
            if (this.formField.transientMatchingBeginOrEndField != null)
                this.formField.transientMatchingBeginOrEndField.beginFieldName =
                    (StringUtil.isEmptyString(this.formField.displayName) ? this.formField.name : this.formField.displayName);
            */
        } else if (propertyName == 'selectedRoleNames') {
            //console.log('Check this.formField.selectedRoleNames ...');
        }
    }

    public get canHaveInstructions(): boolean {
        return false;
    }
    public get canHaveFieldConditionalLogic(): boolean {
        return false;
    }

    // Methods called by my HTML code.
    public get FieldTypeName(): string {
        return (this.isShowTo ? 'Show To' : 'Hide From');
    }
    public get BeginOrEndTag(): string {
        return (this.FormField.isConditionalBeginElement ? 'Begin' : 'End');
    }

    public get DescriptiveDisplayName(): string {
        let name: string = '';

        if (this.FormField.isConditionalBeginElement)
            name = `${this.BeginOrEndTag} ${this.FieldTypeName}: ${this.DisplayName}`;
        else {
            if (this.FormField.transientMatchingBeginOrEndField != null) {
                if (!StringUtil.isEmptyString(this.FormField.transientMatchingBeginOrEndField.displayName))
                    name = `${this.BeginOrEndTag} ${this.FieldTypeName}: ${this.FormField.transientMatchingBeginOrEndField.displayName}`;
                else
                    name = `${this.BeginOrEndTag} ${this.FieldTypeName}: ${this.FormField.transientMatchingBeginOrEndField.name}`;
            } else if (this.FormField.transientBeginFieldDisplayName != null) {
                name = `${this.BeginOrEndTag} ${this.FieldTypeName}: ${this.FormField.transientBeginFieldDisplayName}`;
            } else {
                //name = `this.formField.transientMatchingBeginOrEndField is null!`;
                if (this.FormField.transientMatchingBeginOrEndField != null)
                    name = `${this.BeginOrEndTag} ${this.FieldTypeName}: ${this.FormField.transientMatchingBeginOrEndField.name}`;
                else
                    name = 'Matching begin or end field error';
            }
        }            

        return name;
    }

    /*
    public get HasConditionalExpression(): boolean {
        return this.specifiedRoleNames.length > 0;
    }
    */

    public get ShowConfigurationHint(): boolean {
        let show: boolean = (this.FormField.isConditionalBeginElement && StringUtil.isEmptyString(this.FormField.showToHideFromRoleNames));
        return show;
    }
    public get ConfigurationHintText(): string {
        let hint: string = `Use properties to configure "${this.FieldTypeName}" load options`;
        return hint;
    }

    public get ShowConfiguredRoleNames(): boolean {
        let show: boolean = (this.FormField.isConditionalBeginElement && (!StringUtil.isEmptyString(this.FormField.showToHideFromRoleNames)));
        return show;
    }
    public get ConfiguredRoleNames(): string {
        return this.FormField.showToHideFromRoleNames;
    }

    public get ConditionalNestingLevel(): number {
        return this.FormField.transientConditionalNestingLevel;
    }
    public get IndentationStyle(): string {
        let indentationStyle: string = '';

        if (this.FormField.transientConditionalNestingLevel > 1)
            indentationStyle = `margin-left: ${(this.FormField.transientConditionalNestingLevel - 1) * 20}px`;

        return indentationStyle;
    }

    public get DebugMode(): boolean {
        return false;
    }

    // Helper methods.
    // No helper methods so far.
}
