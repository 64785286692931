import { IFieldDefinitionLogic } from '../../../interfaces/ifield-definition-logic.interface';
import { MetadataFieldDefinitionLogicBase } from './metadata-field-def-logic-base';
import { TextFieldDefinitionLogicBase } from './text-logic-base';
import { TextMetadataFieldDefLogic } from './text-metadata-field-def-logic'

export class WorkflowStateFieldDefinitionLogic extends TextMetadataFieldDefLogic implements IFieldDefinitionLogic {
    // Constructor.
    public constructor() {
        super('workflowState');
    }

    public get canParticipateInFlexFieldBehavior(): boolean {
        return true;
    }
}
