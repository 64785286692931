import { Component, Renderer2, Output, EventEmitter, } from '@angular/core';

import { NumericInputFormFieldBaseComponent } from './num-input-form-field-base.component';
//import { IFormFieldComponent } from '../../../interfaces/iform-field-component';
import { GridFormInstanceElementWrapper } from '../../../models/grid/grid-form-instance-element-wrapper.model';
//import { FormFieldMode } from '../form-field-mode.enum';
import { ControlType, FormFieldBaseComponent } from '../form-field-base/form-field-base.component';
import { FormField } from '../../../models/form-builder/form-field.model';
import { FormInstanceElement, FormInstanceElementValueTypeEnum } from '../../../models/form-builder/form-instance-element.model';
import { FormFieldPropertyEnum } from '../../../models/form-builder/form-field-property-enum.model';
//import { InputFormFieldBaseComponent } from '../input-form-field-base/input-form-field-base.component';
import { FormFieldProcessingPhaseEnum } from '../../../enums/form-field-processing-phase.enum';
import { IGridRow } from '../../../interfaces/grid-row.interface';

@Component({
    selector: 'app-decimal-input-form-field-base',
    templateUrl: './num-input-form-field-base.component.html',
    styleUrls: ['./num-input-form-field-base.component.scss'],
    standalone: false
})
export abstract class DecimalInputFormFieldBaseComponent extends NumericInputFormFieldBaseComponent {
    // Properties.
    @Output() onInit = new EventEmitter();

    protected readonly formFieldProperties: string[] =
        [
            FormFieldPropertyEnum.NAME,
            FormFieldPropertyEnum.FIELD_GROUP,
            FormFieldPropertyEnum.REQUIRED,
            FormFieldPropertyEnum.DISPLAY_NAME,
            FormFieldPropertyEnum.BLANK_VALUE,
            FormFieldPropertyEnum.HELP_TEXT,
            FormFieldPropertyEnum.PLACEHOLDER_TEXT,
            FormFieldPropertyEnum.TOOL_TIP,
            FormFieldPropertyEnum.DEFAULT_VALUE,
            FormFieldPropertyEnum.MIN_VALUE,
            FormFieldPropertyEnum.MAX_VALUE,
            FormFieldPropertyEnum.SHOW_PREVIEW,
            FormFieldPropertyEnum.INSTRUCTIONS_TEXT,
            FormFieldPropertyEnum.GRID_COLUMN_WIDTH
        ];

    // Constructor.
    public constructor(protected renderer: Renderer2) {
        super(renderer);
    }

    // Life cycle methods.
    public ngOnInit(): void {
        let hshProperties = this.getProperties();
        this.onInit.emit(hshProperties);
    }

    // Implement abstract methods.
    public getProperties(): any {
        let hshProperties = {
            component: this,
            formField: this.FormField,
            properties: this.formFieldProperties
        };

        return (hshProperties);
    }

    // Implement methods common to form field component classes that use FormInstanceElement property 'textValue'.
    public pasteValue(value: string, elementWrapper: GridFormInstanceElementWrapper, formField: FormField): void {
        elementWrapper.formInstanceElement.decimalValue = parseFloat(value);
        elementWrapper.formInstanceElement.valueType = FormInstanceElementValueTypeEnum.TypeDecimal;
        elementWrapper.standinDisplayValue = elementWrapper.formInstanceElement.decimalValue.toString();
    }

    // Handle updates made outside the control.
    public formInstanceElementUpdated(formInstanceElement: FormInstanceElement): void {
        if ((this.controlData != null) && (this.controlData.formControl != null))
            this.controlData.formControl.setValue(formInstanceElement.decimalValue);
    }

    // Override base class methods.
    protected formInstanceElementReceived(): void {
        if ((this.Mode === 'preview') || (this.Mode === 'instance')) {
            if (this.ControlType === ControlType.REACTIVE_FORMS) {
                if (this.FormInstanceElement.UserUpdatedData != true) {
                    this.setDefaultDecimalValue();
                }

                // Use a base class method to set up a decimal form group.
                this.setupDecimalFormControl();
            } // if (this.controlType === ControlType.REACTIVE_FORMS)
        } // if ((this.Mode === 'preview') || (this.Mode === 'instance'))

        return;
    }

    //TEAMS-561: KLW - Implement the first instance of writeValueTrigger which will eventually replace formInstanceElementReceived
    protected writeValueTriggered(): void {
        if ((this.Mode === 'preview') || (this.Mode === 'instance')) {
            if (this.ControlType === ControlType.REACTIVE_FORMS) {
                this.SetupFormControlFromWriteValue();
            } // if (this.controlType === ControlType.REACTIVE_FORMS)
        } // if ((this.Mode === 'preview') || (this.Mode === 'instance'))

        return;
    }

    // Override the getDisplayValue() base class method.
    // Define a method that allows a component to return its display value.
    public pseudoStatic_getDisplayValue(formFieldParam: FormField,
        formInstanceElementParam: FormInstanceElement,
        gridRow: IGridRow,
        processingPhase: FormFieldProcessingPhaseEnum): string {
        if (!formInstanceElementParam.UserUpdatedData) {
            FormFieldBaseComponent.setDefaultDecimalValueFor(formFieldParam, formInstanceElementParam);
        }

        let strValue: string = (formInstanceElementParam.decimalValue ?
            new Intl.NumberFormat('en-us', { minimumFractionDigits: 0 }).format(formInstanceElementParam.decimalValue) :
            '');

        return strValue;
    }

    public pseudoStatic_pasteValue(value: string, elementWrapper: GridFormInstanceElementWrapper, formField: FormField): void {
        elementWrapper.formInstanceElement.decimalValue = parseFloat(value);
        elementWrapper.formInstanceElement.valueType = FormInstanceElementValueTypeEnum.TypeDecimal;
        elementWrapper.standinDisplayValue = elementWrapper.formInstanceElement.decimalValue.toString();
    }

    // Override other base class methods.
    public hasNumericData(): boolean {
        return (true);
    }
    public getNumericValue(formFieldParam: FormField,
        formInstanceElementParam: FormInstanceElement,
        gridRow: IGridRow,
        processingPhase: FormFieldProcessingPhaseEnum): number {
        //if (!formInstanceElementParam.transientValueSetFlag) {
        if (!formInstanceElementParam.UserUpdatedData) {
            FormFieldBaseComponent.setDefaultDecimalValueFor(formFieldParam, formInstanceElementParam);
        }

        return (formInstanceElementParam.decimalValue ? formInstanceElementParam.decimalValue : 0);
    }

    // Protected methods.
    protected removeAnyExtraDigitsAfterDecimalPoint(value: string): string {
        let result: string = value;

        let numberParts: string[] = value.split('.');
        if (numberParts.length == 2) {
            let digitsAfterDecimalPoint: string = numberParts[1].trim();

            if (digitsAfterDecimalPoint.length > this.FormField.numDigitsAfterDecimalPoint) {
                digitsAfterDecimalPoint = digitsAfterDecimalPoint.substring(0, this.FormField.numDigitsAfterDecimalPoint);

                result = `${numberParts[0]}.${digitsAfterDecimalPoint}`;
            }
        }

        return result;
    }

}
