import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { SiteListColumnDef, SiteGridStateInfo } from './extras';
import { SortDescriptor } from "@progress/kendo-data-query";
import { Router } from '@angular/router';
import { MultipleSortSettings } from '@progress/kendo-angular-grid';
import { DataCollectionService } from '../../../shared/services/data-collection.service';
import { CurrentUserService } from '../../../security/current-user.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { CollectionDetailDialog } from '../../dialogs/collection-detail/collection-detail.dialog';
import { DataCollection } from '../../models/site-content/data-collection.model';
import { ProgressConfig } from '../../services/progress-indicator.service';
import { ImportSiteDialog } from '../../dialogs/import-site/import-site.dialog';
import { SiteGridRemoteBindingDirective } from '../../directives/site-grid-remote-binding.directive';
import { KendoGridHelper } from '../../kendo-grid-helper';
import { ConfirmationDialogComponent, ConfirmationDialogModel } from '../../dialogs/confirmation/confirmation-dialog.component';

@Component({
    selector: 'app-site-grid',
    templateUrl: './site-grid.component.html',
    styleUrls: ['./site-grid.component.scss'],
    encapsulation: ViewEncapsulation.None //enable CSS overrides
    ,
    standalone: false
})
export class SiteGridComponent implements OnInit {
    // Get a reference to the remote binding directive that handles all the changes to Grid state (Relates to <kendo-grid #grid="remoteBindingDirectiveRef" in the HTML)
    @ViewChild(SiteGridRemoteBindingDirective) public remoteBindingDirectiveRef: SiteGridRemoteBindingDirective;
    private helper = new KendoGridHelper();
    private kendoGridData: SiteGridStateInfo = new SiteGridStateInfo();
    private showAllForAdmin: boolean = false;

    public columnDefs: SiteListColumnDef[] = [
        { fieldName: 'name', title: 'Name', width: '200' },
        { fieldName: 'lastAccessedDate', title: 'Last Accessed by You', width: '75' },
        { fieldName: 'description', title: 'Description', width: '75' },
        { fieldName: 'purpose', title: 'BDR/Memo Number and Title', width: '75' },
        { fieldName: 'currentWorkflowState', title: 'Status', width: '75' },
        { fieldName: 'id', title: 'ID', width: '100' },
        { fieldName: 'isFavorite', title: 'Fav', width: '0' }
    ];

    public get SortSettings(): any {
        let settings: MultipleSortSettings = {
            //mode: "multiple",
            initialDirection: "asc",
            allowUnsort: true,
            showIndexes: true,
        };
        return settings;
    }

    constructor(
        private router: Router,
        private dialog: MatDialog,
        private dataCollectionService: DataCollectionService,
        private currentUserService: CurrentUserService) { }

    ngOnInit(): void {
    }

    public openSite(event, dataItem): void {
        //this.router.navigateByUrl(`/site/${dataItem.id}`);

        window.open(`/site/${dataItem.id}`, '_blank').focus();
    }

    public deleteSite(event, dataItem): void {
        let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: "400px",
            data: new ConfirmationDialogModel(
                `Delete Site`,
                `Are you sure you want to delete the Site "${dataItem.name}"?`
            )
        });
        dialogRef.afterClosed().subscribe(yes => {
            if (yes) {
                this.dataCollectionService.delete(dataItem.id).then(x => {
                    this.remoteBindingDirectiveRef.rebind();
                });
            }
        });
    }

    public toggleFavorite(dataItem: any): void {
        dataItem.isFavorite = !dataItem.isFavorite;
        this.dataCollectionService.toggleIsFavorite(dataItem.id).then(fi => { });
    }

    public toggleSiteAdmin(dataItem: any): void {
        dataItem.siteIsAdministerable = !dataItem.siteIsAdministerable;
        if (dataItem.siteIsAdministerable) {
            this.dataCollectionService.addAsSiteAdmin(dataItem.id).then(fi => { });
        } else {
            this.dataCollectionService.removeAsSiteAdmin(dataItem.id).then(fi => { });
        }
    }

    public favIcon(dataItem: any): string {
        if (dataItem.isFavorite) {
            return "star";
        } else {
            return "star_outline";
        }
    }

    public showAllForAdminIcon(dataItem: any): string {
        if (dataItem.siteIsAdministerable) {
            return "person";
        } else {
            return "person_outline";
        }
    }

    public toggleShowAllForAdmin() {
        this.showAllForAdmin = !this.showAllForAdmin;

        this.remoteBindingDirectiveRef.ShowAllForAdmin = this.showAllForAdmin;
        this.remoteBindingDirectiveRef.rebind();
    }

    public handleNewSite(): void {
        const dialogRef = this.dialog.open(CollectionDetailDialog, {
            width: '60%',
            data: {}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                let collection = new DataCollection();

                collection.name = result.name;

                this.dataCollectionService.create(
                    collection,
                    new ProgressConfig(`Creating new Site "${collection.name}"`, `Done creating Site "${collection.name}"`))
                    .then(response => {
                        this.router.navigate(['/site/' + response.id]);
                    });
            }
        });
    }

    public handleSiteImport(): void {
        // Process an export site dialogue.

        let dialogConfig: MatDialogConfig = new MatDialogConfig();
        dialogConfig.hasBackdrop = true;
        dialogConfig.width = '500px';

        const dialogRef = this.dialog.open(ImportSiteDialog, dialogConfig);

        dialogRef.afterClosed().subscribe(importedSiteId => {
            // Note:  the site import is performed by the dialogue.
            // If the site Id is returned, load the copied site.
            if (importedSiteId != null) {
                this.dataCollectionService.get(importedSiteId)
                    .then(response => {
                        this.router.navigate(['/site/' + response.id]);
                    });
            }
        });

        return;
    }

    public get ShowAllForAdminButton(): boolean {
        return this.currentUserService.user.isSystemAdmin;
    }
    public get ShowDeleteButtons(): boolean {
        return this.currentUserService.user.isSystemAdmin;
    }

    public get CommandColumnWidth(): number {
        return this.ShowDeleteButtons ? 82 : 55;
    }


    // Getters / Setters
    public get Helper(): KendoGridHelper {
        return this.helper;
    }

    public get ShowAllForAdmin(): boolean {
        return this.showAllForAdmin;
    }

    public get ShowAllForAdminButtonText(): string {
        return this.showAllForAdmin ? "Show only Sites you have access to" : "Show All for Admin";
    }

    public get ColumnDefs() {
        return this.columnDefs.filter(x => { return x.fieldName != 'isFavorite' });
    }

    public get FavoriteColumnDef() {
        var faveCol = this.columnDefs.find(x => { return x.fieldName == 'isFavorite' });
        return faveCol;
    }

    // Getters for virtual scrolling
    public get PageSize(): number {
        return this.kendoGridData.gridState.take;
    }

    public get Skip(): number {
        return this.kendoGridData.gridState.skip;
    }
    public get Sort(): SortDescriptor[] {
        return this.kendoGridData.gridState.sort;
    }

}
