<!--
    To add a new kind of field to serve as source to a flex field, see https://community-dc.max.gov/x/E4u6mQ
-->
<ng-template #instructionsTemplate><ng-content select="[projectedInstructions]"></ng-content></ng-template>
<ng-template #tooltipTemplate><ng-content select="[projectedTooltip]"></ng-content></ng-template>

<!--
    A lot of the code in this template was copied directly from drop-down-form-field.component.html and
    multi-drop-down-form-field.component and then modified.
-->
<!-- Useful for debugging:
    <div style="color:red">{{IsAutoComplete ? 'Auto Complete' : 'Manual'}}, {{IsSingleSelect ? 'Single' : 'Multi'}} Select</div>
-->

<ng-template #templateOutput>
    <!--
        When autocomplete is configured the autocomplete componnent is used to render the flex field itself
        NOTE: Multiple selection AND autocomplete are not yet supported
    -->
    <div *ngIf="this.AutocompleteEnabled && !this.IsDesign; else noAutoComplete">
        <app-autocomplete #autocompletecomponenent
                          [title]="this.DropDownOptionsTitle"
                          [formInstanceElement]="FormInstanceElement"
                          [formInstance]="FormInstance"
                          [selectedValuesByFormField]="SelectedValuesByFieldId"
                          [formFieldId]="this.FormField.id"
                          [selectableValues]="FormField.selectOptions"
                          (selectedItems)="handleAutoCompleteSelectionChange($event)"
                          (onBlur)="this.blurValidation()">
        </app-autocomplete>
    </div>
    <ng-container *ngTemplateOutlet="instructionsTemplate"></ng-container>
</ng-template>

<!-- When autocomplete has NOT been selected ... -->
<ng-template #noAutoComplete>
    <div>
        <!-- --------------------------- DESIGN MODE --------------------------------- -->
        <div *ngIf="this.Mode === 'design'" class="flex-row-top-right">
            <div class="fill-remaining-space">
                <ng-container *ngIf="this.HasInstructions">
                    <div class="design-mode-field-label" *ngIf="this.DisplayName">{{this.DisplayName}} <span *ngIf="this.FormField.required" style="color:red">*</span></div>
                    <ng-container *ngTemplateOutlet="instructionsTemplate"></ng-container>
                </ng-container>

                <div>
                    <mat-form-field appearance="outline" class="dropdown-mat-form-field">
                        <mat-label class="design-mode-field-label" *ngIf="this.ShowLabel && !HasInstructions">{{this.DisplayName}} <span *ngIf="this.FormField.required" style="color:red">*</span></mat-label>

                        <mat-select disabled [(ngModel)]="this.DefaultDropdownValue">
                            <mat-option [value]="-1">{{this.DefaultDropdownValueText}}</mat-option>

                            <mat-option *ngFor="let hshOption of this.ConfiguredOptions" [value]="hshOption.OptionId">
                                {{hshOption.OptionText}}
                            </mat-option>
                        </mat-select>

                    </mat-form-field>
                </div>
                <ng-container *ngTemplateOutlet="instructionsTemplate"></ng-container>
            </div>
            <ng-container *ngTemplateOutlet="tooltipTemplate"></ng-container>
        </div>

        <!-- --------------------------- PREVIEW/INSTANCE MODE --------------------------------- -->
        <div *ngIf="(this.Mode === 'preview') || (this.Mode === 'instance')" class="flex-row-top-right">

            <div class="fill-remaining-space">
                <ng-container *ngIf="this.FormGroup !== null">
                    <!-- If there are instructions, display label first, then instructions then field -->
                    <ng-container *ngIf="this.HasInstructions">
                        <div class="field-label" *ngIf="this.DisplayName">{{this.DisplayName}} <span *ngIf="this.FormField.required" style="color:red">*</span></div>
                        <ng-container *ngTemplateOutlet="instructionsTemplate"></ng-container>
                        <div class="field-label" *ngIf="IsMultiSelect">The maximum number of selections that can be made: {{this.MaxSelections}}</div>
                    </ng-container>

                    <!-- Much of this code was pulled from the old drop down component -->
                    <form [formGroup]="this.FormGroup">

                        <!---------------------------------- SINGLE SELECT --------------------------------->
                        <mat-form-field *ngIf="IsSingleSelect" appearance="outline" [floatLabel]="'always'" class="dropdown-mat-form-field">
                            <!-- If there are no instructions, display label integrated into the field -->
                            <mat-label *ngIf="this.ShowLabel && !this.HasInstructions && !FieldIsInGrid" class="field-label">
                                {{this.DisplayName}} <span *ngIf="this.FormField.required" style="color:red">*</span>
                            </mat-label>

                            <mat-select formControlName="flexible_single_selection_form" placeholder="{{this.SelectOptions.length > 1 ? DropdownPlaceHolderText : ''}}"
                                        [disabled]="this.ReadOnly"
                                        (blur)="this.handleValidationOnBlur()"
                                        (selectionChange)="handleSingleSelectChange($event)">

                                <mat-option *ngFor="let strSelectOption of this.SelectOptions"
                                            title="{{strSelectOption}}"
                                            [value]="strSelectOption">
                                    {{strSelectOption}}
                                </mat-option>
                            </mat-select>

                        </mat-form-field>
                    </form>

                    <!---------------------------------- MULTI SELECT --------------------------------->
                    <!-- Much of this code was pulled from the old multi-select drop down component -->
                    <mat-form-field *ngIf="IsMultiSelect && !IsAutoComplete" appearance="outline" [floatLabel]="'always'" class="drop-down-mat-form-field full-width">
                        <!-- class="{{this.Mode =='design' ? 'design-mode-field-label' : 'field-label'}}" -->
                        <mat-label *ngIf="this.ShowLabel && !this.HasInstructions && !FieldIsInGrid" class="field-label">
                            {{this.DisplayName}} <span *ngIf="this.FormField.required" style="color:red">*</span>
                        </mat-label>

                        <span>
                            <button mat-raised-button
                                    *ngIf="!this.SelectionsOpen && (this.Mode == 'instance' || this.Mode == 'preview')"
                                    class="select-close-button"
                                    color="primary"
                                    (click)="openSelections()">
                                Select
                            </button>
                            <button mat-raised-button
                                    *ngIf="this.SelectionsOpen && (this.Mode == 'instance' || this.Mode == 'preview')"
                                    class="select-close-button"
                                    color="primary"
                                    (click)="closeSelections()">
                                Close
                            </button>
                        </span>

                        <span class="selection-count" *ngIf="this.Mode == 'instance' || this.Mode == 'preview'">
                            {{this.SelectedChips.length}} of {{this.SelectOptions.length}} selected
                        </span>

                        <mat-select #multiSelect
                                    [formControl]="this.DropDownOptionsFormControl"
                                    multiple
                                    (blur)="this.handleValidationOnBlur()"
                                    (opened)="multiSelectOpened()"
                                    (closed)="multiSelectClosed()"
                                    [disableOptionCentering]="true"
                                    tabindex="0"
                                    class="dropdown-select">
                            <mat-select-trigger><!-- keep this even though blank --></mat-select-trigger>

                            <mat-option *ngFor="let strSelectOption of this.SelectOptions" [disabled]="this.IsDesign || this.canDisableOption(strSelectOption)" [value]="strSelectOption">
                                {{strSelectOption}}
                            </mat-option>
                        </mat-select>

                        <mat-chip-listbox [formControl]="this.DropDownOptionsFormControl">
                            <mat-chip-option *ngFor="let selected of SelectedChips"
                                             [removable]="true"
                                             (removed)="onRemove(selected)"
                                             class="mat-elevation-z2">
                                {{ selected }}
                                <mat-icon matChipRemove>cancel</mat-icon>
                            </mat-chip-option>

                            <mat-chip-option class="mat-elevation-z3 remove-all-chip" *ngIf="this.ShowRemoveAll" (click)="this.removeAllChips()" (keyup.enter)="this.removeAllChips()">
                                Remove All
                            </mat-chip-option>
                        </mat-chip-listbox>
                    </mat-form-field>
                    <ng-container *ngTemplateOutlet="instructionsTemplate"></ng-container>
                </ng-container>
            </div>
            <ng-container *ngTemplateOutlet="tooltipTemplate"></ng-container>
        </div>
    </div>

</ng-template>

<!-- output the #templateOutput defined above -->
<div class="outer-div">
    <div class="flex-row-top-right">

        <div class="fill-remaining-space">
            <ng-template [ngTemplateOutlet]="templateOutput"></ng-template>
        </div>

        <ng-container *ngTemplateOutlet="tooltipTemplate"></ng-container>
    </div>
</div>
