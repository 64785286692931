import { Component, OnInit, Input } from '@angular/core';
import { Menu } from '../../shared/models/navigation/menu.model';
import { ActivatedRoute } from '@angular/router';
import { UserClaims } from '../../shared/models/user-claims.model';

@Component({
    selector: 'app-admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.scss'],
    standalone: false
})
export class AdminComponent implements OnInit {

    @Input()
    navMenu: Menu;

    userClaims: UserClaims;

    constructor(private route: ActivatedRoute) { }

    ngOnInit() {
        this.route.data
            .subscribe((data: { navMenu: Menu, claims: UserClaims }) => {
                this.navMenu = data.navMenu;
                this.userClaims = data.claims;
            });
    }

}
