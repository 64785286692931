import {
    Component, EventEmitter, OnInit, Output, Renderer2, Type as AngularCoreType, ViewChild
} from '@angular/core';
import { AbstractControl, UntypedFormControl, NG_VALUE_ACCESSOR, ValidationErrors, ValidatorFn } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { CustomDateFormatDirective } from '../../../directives/custom-date-format.directive';
import { FormFieldProcessingPhaseEnum } from '../../../enums/form-field-processing-phase.enum';
import { IGridRow } from '../../../interfaces/grid-row.interface';
import { FormFieldOnInitPropertyEnum } from '../../../models/form-builder/form-field-on-init-output-property.enum';
import { FormFieldPropertyEnum } from '../../../models/form-builder/form-field-property-enum.model';
import { FormField } from '../../../models/form-builder/form-field.model';
import { FormInstanceElement, FormInstanceElementValueTypeEnum } from '../../../models/form-builder/form-instance-element.model';
import { GridFormInstanceElementWrapper } from '../../../models/grid/grid-form-instance-element-wrapper.model';
import { ControlType } from '../form-field-base/form-field-base.component';
// 05/18/2021 note:  leaving in the following, commented out references to the 'Moment'
//                   date formatting library which is apparently rather popular.  I am
//                   thinking that we might elect to start using it at some point.
//import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
//import * as _moment from 'moment';
//import { default as _rollupMoment } from 'moment';
//const moment = _rollupMoment || _moment;
import { FormFieldMode } from '../form-field-mode.enum';
import { InputFormFieldBaseComponent } from '../input-form-field-base/input-form-field-base.component';

const JANUARY_1_1900_STRING = 'Mon Jan 01 1900 00:00:00 GMT-0500 (Eastern Standard Time)';
const DECEMBER_31_2300_STRING = 'Mon Dec 31 2300 00:00:00 GMT-0500 (Eastern Standard Time)';

// Note:  please note the 'providers' definition below, as it is needed.
//        Without it, you will get the following exception:
//
//             No value accessor for form control with unspecified name
//
// The above exception gets thrown when a component, in this case our
// base class, implements interface 'ControlValueAccessor' and does not
// provide the 'providers' definition below.  Implementing the
// 'ControlValueAccessor' interface allows a form field component to
// support [(ngMode)], so users of the component can use [(ngModel)].
@Component({
    selector: 'app-date-form-field',
    templateUrl: './date-form-field.component.html',
    styleUrls: ['./date-form-field.component.scss', '../form-fields.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: DateFormFieldComponent,
            multi: true
        }
    ],
    standalone: false
})
export class DateFormFieldComponent extends InputFormFieldBaseComponent implements OnInit {
    // Properties.
    // Note:  several properties are implemented in my base class.
    @Output() onInit = new EventEmitter();

    private minDate: Date = null;
    private maxDate: Date = null;

    private strDatePipeFormat: string = null;

    private readonly formFieldProperties: string[] =
        [FormFieldPropertyEnum.NAME,
        FormFieldPropertyEnum.FIELD_GROUP,
        FormFieldPropertyEnum.REQUIRED,
        FormFieldPropertyEnum.DISPLAY_NAME,
        FormFieldPropertyEnum.BLANK_VALUE,
        FormFieldPropertyEnum.HELP_TEXT,
        FormFieldPropertyEnum.PLACEHOLDER_TEXT,
        FormFieldPropertyEnum.TOOL_TIP,
        FormFieldPropertyEnum.DEFAULT_VALUE,
        FormFieldPropertyEnum.MIN_DATE,
        FormFieldPropertyEnum.MAX_DATE,
        FormFieldPropertyEnum.DISPLAY_FORMAT,
        FormFieldPropertyEnum.INSTRUCTIONS_TEXT,
        FormFieldPropertyEnum.GRID_COLUMN_WIDTH
        ];

    private readonly displayFormats: string[] =
        [
            'Short Date',
            'Medium Date',
            'Long Date',
            'Full Date'
        ];

    private formattedDateValue: string = '';
    @ViewChild(CustomDateFormatDirective, { read: CustomDateFormatDirective }) customDateFormatDirective: CustomDateFormatDirective;
    editorSubject: any;

    // Constructor.
    // Note:  Renderer2 is not yet being used. 
    constructor(rendererParam: Renderer2,
        private dateAdapter: DateAdapter<Date>) {
        super(rendererParam);

        this.matInputId = this.generateUniqueId('date');

        return;
    }

    // Implement abstract methods.
    public getProperties(): any {
        let hshProperties = {
            component: this,
            formField: this.FormField,
            properties: this.formFieldProperties,
            displayFormatValues: this.displayFormats
        };
        hshProperties[FormFieldOnInitPropertyEnum.REQUIRED_PREVIEW_INSTANCE_MODE_HEIGHT] = 80;
        hshProperties[FormFieldOnInitPropertyEnum.REQUIRED_PREVIEW_INSTANCE_MODE_HEIGHT_UNIT] = 'px';

        return (hshProperties);
    }

    // Life cycle methods.
    public ngOnInit(): void {
        let hshEventProperties = this.getProperties();

        this.onInit.emit(hshEventProperties);

        // If we are in 'preview' or'instance' mode,
        // create a date pipe and select a date format.
        // Note:  a date pipe is now accessed via private method
        //        getDatePipe(), which creates it if necessary.

        if ((this.Mode === 'preview') || (this.Mode === 'instance')) {
            this.strDatePipeFormat = DateFormFieldComponent.displayFormatToDatePipeFormat(this.FormField);
        }

        return;
    }

    public ngAfterViewInit(): void {
        this.setFormattedValueIfValueExists();

        return;
    }

    public ngAfterViewChecked(): void {
        return;
    }

    // HTML accessor methods.
    public get DatePipeFormat(): string {
        return (this.strDatePipeFormat);
    }

    public get MinDate(): Date {
        return (this.minDate);
    }

    public get MaxDate(): Date {
        return (this.maxDate);
    }

    public get FormattedDateValue(): string {
        return (this.formattedDateValue);
    }

    public set FormattedDateValue(val: string) {
        this.formattedDateValue = val;
    }

    // Override writeValue().
    public writeValue(value: any) {
        super.writeValue(value);
    }

    public handleOnModelChange(): void {
        if (this.customDateFormatDirective) {
            this.customDateFormatDirective.writeValue(this.FormInstanceElement['unformattedDateValue']);
            this.FormInstanceElement['unformattedDateValue'] = null;
        }

        super.handleOnModelChange();

        return;
    }

    // Implement formInstanceElementReceived(), when a FormInstanceElement value gets set.
    protected formInstanceElementReceived(): void {
        if ((this.Mode === FormFieldMode.PREVIEW) || (this.Mode === FormFieldMode.INSTANCE)) {
            if (this.ControlType === ControlType.REACTIVE_FORMS) {
                let bGroupAlreadyExists: boolean = (this.FormGroup !== null);

                this.setupTextFormGroup('date_control', false);

                let localFormControl: UntypedFormControl = <UntypedFormControl>this.FormGroup.get('date_control');

                // Note:  as we are putting an instance of a JavaScript Date object
                // in this.FormInstanceElement.textValue, we must convert it to a
                // string in order to invoke string methods on it.
                if (!this.FormInstanceElement?.textValue) {
                    // Determine a value for the date.
                    if (this.FormField.defaultValue) {
                        let hshDateInfo: any = FormField.DateToUtcDate(this.FormField.defaultValue);
                        let strUTCDate: string = hshDateInfo['utcDate'];
                        let dateValue: Date = hshDateInfo['dateValue'];
                        localFormControl.setValue(strUTCDate);
                    }

                    let strMinDate: string = this.FormField.minDate;
                    let strMaxDate: string = this.FormField.maxDate;

                    if (!strMinDate) {
                        strMinDate = JANUARY_1_1900_STRING;
                    }

                    if (!strMaxDate) {
                        strMaxDate = DECEMBER_31_2300_STRING;
                    }

                    this.minDate = new Date(strMinDate);
                    this.maxDate = new Date(strMaxDate);
                }

                this.setFormattedValueIfValueExists();

                // If we just created the form group, subscribe to value changes.
                if (!bGroupAlreadyExists) {
                    // Subscribe to value changes.
                    localFormControl.valueChanges
                        .subscribe(val => {
                            // Save the value.
                            this.FormInstanceElement.TextValue = val;

                            //VNEXT-539: KLW - Used for allowing required for multi select and auto complete
                            this.handleStatusChangesFor(this.FormControl);

                            this.FormInstanceElement['unformattedDateValue'] = val;

                            // Notify any listeners of the value change.
                            this.notifyValueChanged();
                        });

                    // pharv - 11/3/2021 - set the FormControl as part of getting validaion to work on date field
                    this.FormControl = localFormControl;
                }
            } // if (this.controlType === ControlType.REACTIVE_FORMS)
        } // if ((this.Mode === 'preview') || (this.Mode === 'instance'))

        return;
    }


    //TEAMS-561: KLW - Implement the first instance of writeValueTrigger which will eventually replace formInstanceElementReceived
    //This needs to be async for the code to wait until the FormGroup is created in FormFieldBase
    protected async writeValueTriggered(): Promise<void> {
        if ((this.Mode === FormFieldMode.PREVIEW) || (this.Mode === FormFieldMode.INSTANCE)) {
            if (this.ControlType === ControlType.REACTIVE_FORMS) {
                let bGroupAlreadyExists: boolean = (this.FormGroup !== null);

                this.setupTextFormGroup('date_control', false);

                // await this.SetupFormGroupFromWriteValue('date_control', [], false);

                let localFormControl: UntypedFormControl = <UntypedFormControl>this.FormGroup.get('date_control');

                // Note:  as we are putting an instance of a JavaScript Date object
                // in this.FormInstanceElement.textValue, we must convert it to a
                // string in order to invoke string methods on it.
                if (!this.FormInstanceElement?.textValue) {
                    // Determine a value for the date.
                    if (this.FormField.defaultValue) {
                        let hshDateInfo: any = FormField.DateToUtcDate(this.FormField.defaultValue);
                        let strUTCDate: string = hshDateInfo['utcDate'];
                        let dateValue: Date = hshDateInfo['dateValue'];
                        localFormControl.setValue(strUTCDate);
                    }

                    let strMinDate: string = this.FormField.minDate;
                    let strMaxDate: string = this.FormField.maxDate;

                    if (!strMinDate) {
                        strMinDate = JANUARY_1_1900_STRING;
                    }

                    if (!strMaxDate) {
                        strMaxDate = DECEMBER_31_2300_STRING;
                    }

                    this.minDate = new Date(strMinDate);
                    this.maxDate = new Date(strMaxDate);
                }

                this.setFormattedValueIfValueExists();

                // If we just created the form group, subscribe to value changes.
                if (!bGroupAlreadyExists) {
                    // Subscribe to value changes.
                    localFormControl.valueChanges
                        .subscribe(val => {
                            // Save the value.
                            this.FormInstanceElement.TextValue = val;

                            //VNEXT-539: KLW - Used for allowing required for multi select and auto complete
                            this.handleStatusChangesFor(this.FormControl);

                            this.FormInstanceElement['unformattedDateValue'] = val;

                            // Notify any listeners of the value change.
                            this.notifyValueChanged();
                        });

                    // pharv - 11/3/2021 - set the FormControl as part of getting validaion to work on date field
                    this.FormControl = localFormControl;
                }
            } // if (this.controlType === ControlType.REACTIVE_FORMS)
        } // if ((this.Mode === 'preview') || (this.Mode === 'instance'))

        return;
    }

    // Override the getDisplayValue() base class method.
    // Define a method that allows a component to return its display value.
    public pseudoStatic_getDisplayValue(formFieldParam: FormField,
        formInstanceElementParam: FormInstanceElement,
        gridRow: IGridRow,
        processingPhase: FormFieldProcessingPhaseEnum): string {
        //if ((!formInstanceElementParam.transientValueSetFlag) ||
        if ((!formInstanceElementParam.UserUpdatedData) ||
            (!formInstanceElementParam.textValue)) {
            // Set a default value.
            formInstanceElementParam.TextValue = '';
        }

        if (formInstanceElementParam.textValue == 'Invalid Date') return 'Invalid Date';

        let d = new Date(formInstanceElementParam.textValue);
        if (!(d instanceof Date && !isNaN(d.valueOf()))) {
            if (formInstanceElementParam.textValue.trim().length == 0) {
                return '';
            }
            else {
                return 'Unrecognized Date ' + formInstanceElementParam.textValue;
            }
        }

        // Note:  we have to use the display format for the provided form field definition.
        let strDatePipeFormat: string = DateFormFieldComponent.displayFormatToDatePipeFormat(formFieldParam);

        let strValue: string =
            (formInstanceElementParam.textValue ?
                CustomDateFormatDirective.applyDateFormattingUsing(strDatePipeFormat, formInstanceElementParam.textValue) :
                '');

        return (strValue);
    }

    public pseudoStatic_pasteValue(value: string, elementWrapper: GridFormInstanceElementWrapper, formField: FormField): void {
        elementWrapper.formInstanceElement.TextValue = new Date(value).toUTCString();
        elementWrapper.formInstanceElement.valueType = FormInstanceElementValueTypeEnum.TypeText;
        elementWrapper.standinDisplayValue = elementWrapper.formInstanceElement.textValue;
    }

    // Handle updates made outside the control.
    public formInstanceElementUpdated(formInstanceElement: FormInstanceElement): void {
        if ((this.controlData != null) && (this.controlData.formControl != null))
            this.controlData.formControl.setValue(formInstanceElement.textValue);
    }

    // Override a method used to get my class.
    public getFormFieldClass(): AngularCoreType<any> {
        return (DateFormFieldComponent);
    }

    // Implement doSetFocus().
    protected doSetFocus(): void {

        // 01-04-2021 note:  the following code is not yet working.
        let inputSelector: string = `#${this.matInputId}`;
        let matInput: any = this.renderer.selectRootElement(inputSelector, true);
        matInput.focus();

        return;
    }

    private doDoSetFocus(): void {
        let inputSelector: string = `#${this.matInputId}`;
        let matInput: any = this.renderer.selectRootElement(inputSelector, true);
        matInput.focus();
        return;
    }

    // Handle control events.
    public formattedDateSpanClicked(picker: any): void {
        // Open the date picker when the user
        // clicks on the formatted date span.
        picker.open();

        return;
    }

    // Define private helper methods.
    private dateNumToDateString(iDate: number): string {
        // Expect a date number that is in YYYYMMDD format.
        let strDate: string = null;

        try {
            // Get date part values.
            let iYear = Math.round(iDate / 10000);
            let iMonth = Math.round(iDate % 10000 / 100);
            let iDay = iDate % 100;

            // Build a date string from the parts.
            strDate = iYear.toString() + '-';
            strDate += (iMonth >= 10 ? iMonth.toString() : '0' + iMonth.toString()) + '-';
            strDate += (iMonth >= 10 ? iDay.toString() : '0' + iDay.toString());
        } catch (strError) {
            // Since something has gone wrong, return
            // a string representing the current date.
            let date = new Date();

            strDate = date.getFullYear().toString() + '-';
            strDate += (date.getMonth() >= 10 ? date.getMonth().toString() : '0' + date.getMonth().toString()) + '-';
            strDate += (date.getDate() >= 10 ? date.getDate().toString() : '0' + date.getDate().toString());
        }

        // Convert the date to local time.
        let dateToConvert: Date = new Date(strDate);
        this.convertDateToLocalTime(dateToConvert);

        strDate = dateToConvert.toString();

        return (strDate);
    }

    private convertDateToLocalTime(dateToConvert: Date): void {
        // Convert the date to local time.
        let local_date: Date = new Date();
        let iTimeOffsetInMinutes: number = local_date.getTimezoneOffset();

        dateToConvert.setHours(dateToConvert.getHours() + (iTimeOffsetInMinutes / 60));

        return;
    }

    public static displayFormatToDatePipeFormat(formField: FormField): string {
        let strDatePipeFormat: string = 'shortDate';

        switch (formField.displayFormat) {
            case 'Short Date':
                strDatePipeFormat = 'shortDate';
                break;

            case 'Medium Date':
                strDatePipeFormat = 'mediumDate';
                break;

            case 'Long Date':
                strDatePipeFormat = 'longDate';
                break;

            case 'Full Date':
                strDatePipeFormat = 'fullDate';
                break;

            default:
                strDatePipeFormat = 'shortDate';
                break;
        }

        return (strDatePipeFormat);
    }

    private setFormattedValueIfValueExists(): void {
        if (this.FormGroup) {
            let localFormControl: UntypedFormControl = <UntypedFormControl>this.FormGroup.get('date_control');

            if ((localFormControl.value != null) &&
                (localFormControl.value.toString() != null) &&
                (localFormControl.value.toString().trim() != '') &&
                (this.customDateFormatDirective != null)) {
                this.customDateFormatDirective.writeValue(localFormControl.value);
            }
        }

        return;
    }

    // Override
    protected validatorFn(validationPropertyName: string, formFieldParam: FormField): ValidatorFn {
        if (validationPropertyName == 'maxDate') {
            return (control: AbstractControl): ValidationErrors | null => {
                let enteredDate = control.value;
                let maxDate = new Date(formFieldParam.maxDate)
                if (enteredDate && enteredDate > maxDate) {
                    return { maxDate: true };
                } else {
                    return null;
                }
            }
        } else if (validationPropertyName == 'minDate') {
            return (control: AbstractControl): ValidationErrors | null => {
                let enteredDateWithoutTime = new Date(new Date(control.value).toLocaleDateString());
                let minDateWithoutTime = new Date(new Date(formFieldParam.minDate).toLocaleDateString());
                if (enteredDateWithoutTime && enteredDateWithoutTime < minDateWithoutTime) {
                    return { minDate: true };
                } else {
                    return null;
                }
            }
        }
        else {
            return null;
        }
    }


}
