import { ComponentFactoryResolver, ViewContainerRef, ComponentRef, Type as AngularCoreType } from '@angular/core';
import { FieldDefinition, FieldDefinitionAllowDropFlags } from '../field-definition.model';
import { IFieldDefinitionLogic } from '../../../interfaces/ifield-definition-logic.interface';
import { IFormPreRenderFilter } from '../../../interfaces/iform-pre-render-filter.interface';
import { LayoutCell } from '../layout-cell.model';
import { LayoutRow } from '../layout-row.model';
import { Form } from '../form.model';
import { FormObjectDroppedFlags } from '../form-object-dropped-flags.model';
import { FormField } from '../form-field.model';
import { SelectOptionValue } from '../../selection-fields/select-option-value.model';
//import { IFormFieldComponent } from '../../../interfaces/iform-field-component';
import { ConditionalLogicRuleOperator } from '../../field-conditional-logic/rule-operator.model';
//import { FormFieldComparisonOperatorNameEnum } from '../../field-conditional-logic/enums';
import { FormInstanceElement } from '../form-instance-element.model';
//import { InstructionsFieldType } from '../form-field-types';
import { FormFieldBaseComponent } from '../../../components/form-fields/form-field-base/form-field-base.component';
import { IGridRow } from '../../../interfaces/grid-row.interface';
import { FormFieldProcessingPhaseEnum } from '../../../enums/form-field-processing-phase.enum';
import { FieldDefinitionService } from '../../../services/field-definition.service';
//import { IOperandSymbolToCompareMethodName, allFieldOperators } from '../../field-conditional-logic/conditional-logic-operators.model';
import { KendoGridOperatorName } from '../../../enums/kendo-grid/kendo-grid-operator-name.enum';
import { FormInstanceGridRow } from '../../site-content/form-instance-grid-row.model';

// Define/implement field definition custom logic classes.
// Define the custom logic handler that does nothing (works for most field definition classes).
export class DefaultFieldDefinitionClientLogic implements IFieldDefinitionLogic {
    public constructor() { };

    public performSpecialFormAction(form: Form, row: LayoutRow, dropCell: LayoutCell, definition: FieldDefinition, objectDroppedFlags: FormObjectDroppedFlags): void {
        // Note:  this method perform no function by design.
    }

    public pushFormPreRenderFilterFor(formField: FormField): IFormPreRenderFilter {
        return null; // This class has no pre-render filter.
    }
    public popFormPreRenderFilter(filter: IFormPreRenderFilter, formField: FormField): boolean {
        return false;
    }

    public checkAllowsNestedDrop(fieldDefinition: FieldDefinition, alowsDropFlags: FieldDefinitionAllowDropFlags): void {
        // Note:  this method is a NOOP by design.
    }

    public fieldDraggedToTrash(formField: FormField, form: Form): void {
        // Note:  this method is a NOOP by design.
    }

    public get canHaveInstructions(): boolean {
        return true;
    }

    public get canParticipateInFlexFieldBehavior(): boolean{
        return false;
    }

    public hasCustomFormLayoutStylesFor(formField: FormField): boolean {
        return false;
    }
    public customFormLayoutStylesFor(formField: FormField): string {
        return null;
    }

    public hasCustomFormFieldLayoutStylesFor(formField: FormField): boolean {
        return false;
    }
    public customFormFieldLayoutStylesFor(formField: FormField): string {
        return null;
    }

    public hasVirtualSiblingFormFieldsFor(formField: FormField): boolean {
        return false;
    }
    public getVirtualSiblingFormFieldsFor(formField: FormField): FormField[] {
        return null;
    }

    public displayDeleteIconInGridFor(formField: FormField): boolean {
        return true;
    }

    public getAvailableOperatorsFor(formField: FormField): ConditionalLogicRuleOperator[] {
        return [];
    }
    public getSelectableValuesFor(formField: FormField): SelectOptionValue[] {
        return [];
    }
    public compare(leftOperand: any, operator: string, rightOperand: any): boolean {
        return false;
    }

    // pasteDataForInto():  only allow the pasting of values that are valid for a given form field.
    public pasteDataForInto(formField: FormField, formInstanceElement: FormInstanceElement, pasteValue: string): void {
        // Note:  the default version of this method does nothing by design.
        //
        //        Derived logic handler classes must handle this method.
    }
    public validateData(formField: FormField, formInstanceElement: FormInstanceElement, dataValue: string): boolean {
        // Note:  this default implementation returns false by design.
        return false;
    }

    // Sorting.
    public compareAscendingFor(formField: FormField, value1: FormInstanceElement, value2: FormInstanceElement): number {
        // Note:  this default implementation simply returns zero.
        return 0;
    }
    public compareDescendingFor(formField: FormField, value1: FormInstanceElement, value2: FormInstanceElement): number {
        let compareResult: number = - this.compareAscendingFor(formField, value1, value2);
        return compareResult;
    }

    public compareValuesAscending(value1: any, value2: any): number {
        // Note:  this default implementation simply returns zero.
        return 0;
    }
    public compareValuesDescending(value1: any, value2: any): number {
        // Note:  this default implementation simply returns zero.
        return 0;
    }

    public allowsHtmlInDisplayValue(): boolean {
        return false; // A default.
    }

    public createFormFieldDynamically(componentFactoryResolver: ComponentFactoryResolver,
        fieldDefinitionService: FieldDefinitionService,
        viewContainerRef: ViewContainerRef,
        formFieldClass: AngularCoreType<any>,
        formField: FormField,
        mode: string,
        formInstanceElement: FormInstanceElement,
        bReadOnly: boolean,
        parentComponentPrefix: string,
        useGridSpecificStyling: boolean): ComponentRef<FormFieldBaseComponent>
    {
        let componentRef = FormFieldBaseComponent.staticCreateFormFieldDynamically(componentFactoryResolver, fieldDefinitionService, viewContainerRef, formField, formFieldClass, mode, formInstanceElement, bReadOnly, parentComponentPrefix, useGridSpecificStyling);
        return componentRef;
    }

    public setFieldSpecificValuesForDynamicallyCreatedField(formFieldComponent: FormFieldBaseComponent, formField: FormField, parentComponentPrefix: string): void {
        // NOTE:  THIS DEFAULT IMPLEMENTATION IS A NOOP BY DESIGN.
    }

    public getDisplayValue(formFieldParam: FormField, formInstanceElementParam: FormInstanceElement, gridRow: IGridRow, processingPhase: FormFieldProcessingPhaseEnum, gridRowColumnDefs: FormField[] = null): string {
        return "";
    }
    public getPropertiesRequiredByGrid(formField: FormField): any {
        // NOTE:  THIS METHOD RETURNS NO PROPERTIES BY DEFAULT.
        return {};
    }
    public hasNumericData(): boolean {
        return false; // A default implementation.
    }
    public getNumericValue(formFieldParam: FormField, otherFormFields: FormField[], formInstanceElementParam: FormInstanceElement, gridRow: IGridRow, processingPhase: FormFieldProcessingPhaseEnum): number {
        return 0;
    }
    public hasDollarSignPrefix(): boolean {
        return false;
    }
    /*
    public saveData(formInstance: any): void {
        // NOTE:  THIS DEFAULT IMPLEMENTATION IS A NOOP BY DESIGN.
    }
    */

    // Methods presently only relevant to the formula field def logic class/the behavior of the formula form field.
    public requiresFieldValueUpdate(): boolean {
        return false;
    }
    public formFieldValuesUpdated(fieldDefinitionService: FieldDefinitionService, otherFormFields: FormField[], otherFormInstanceElements: FormInstanceElement[], gridRow: IGridRow, formField: FormField, formInstanceElementToUpdate: FormInstanceElement): void {
        // Note:  this method is a NOOP by design.
    }

    // Protected methods.
    // Note:  the following method needs to be tested before its first use.
    public static needsTestingBeforeFirstUse_fieldDefinitionClassNameToFieldTypeName(fieldDefinitionClassName: string): string {
        let result:string = null;

        let fieldDefNameParts = fieldDefinitionClassName.split('.');
        if ((fieldDefNameParts != null) && (fieldDefNameParts.length > 0)) {
            let lastNamePart = fieldDefNameParts[fieldDefNameParts.length - 1];
            let suffixLocation: number = lastNamePart.indexOf("FieldDefinition");
            //result = lastNamePart.replace(suffixLocation, '');
            result = lastNamePart.substr(1, suffixLocation);
        } else {
            let errorMsg = `DefaultFieldDefinitionClientLogic.fieldDefinitionClassNameToFieldTypeName():  encountered unexpected field definition class name '${fieldDefinitionClassName}'.`;
            throw errorMsg;
        }

        return (result);
    }

    public isFilterable(): boolean {
        return false;
    }
    public filterOperator(): string {
        // Default to 'contains'.
        return 'contains';
    }

    public filterType(): string {
        return null;
    }

    public getDefaultMaxLengthWhenInGrid(): number {
        return null;
    }

    public includeInFormTemplateInstancesGrid(): boolean {
        return false;
    }
    public isURLLink(): boolean {
        return false;
    }

    public whenInGridShowAs(): string {
        return 'SPAN';
    }

    public displayValueWithQuotationMarks(): boolean {
        return false;
    }

    public getDefaultKendoGridOperatorName(): KendoGridOperatorName {
        return KendoGridOperatorName.Equals;
    }

    public unpackTemplateInstancesRow(formField: FormField, colValue: FormInstanceElement, row: FormInstanceGridRow): any {
        return null;
    }

    public getNumericValueFrom(value: string): number {
        return (value != null) && (value.trim() != '') ? parseFloat(value.replace(/,/g, '')) : 0;
    }

    public fieldCanBeUsedInSiteHomePageConditionalLogic(formField: FormField): boolean {
        return false;
    }
    public createSiteHomePageFormInstanceElementFor(formField: FormField): FormInstanceElement {
        let formInstanceElement = new FormInstanceElement();
        formInstanceElement.formFieldId = formField.id;
        formInstanceElement.formFieldName = formField.name;
        formInstanceElement.formField = formField;
        return formInstanceElement;
    }

    public isATypeOfGridField(formField: FormField): boolean {
        return false;
    }

    // By default, clicking on a field in a grid row will trigger editing of that row
    public doNotTriggerGridRowEditFor(): boolean {
        return false;
    }
}

