import { Component, OnInit, Output, EventEmitter, Input, Inject, ElementRef } from '@angular/core';
import { DataCollection } from '../../models/site-content/data-collection.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ScriptableBaseComponent } from '../../components/scriptable-base/scriptable-base.component';
import { INameToPrettyNameMap } from '../../models/component-scripting/additional-element-info.model';
import { environment } from '../../../../environments/environment';

export class NewSiteOptions {
    public type: string = 'empty';
    public name: string;
}

@Component({
    selector: 'app-collection-detail',
    templateUrl: './collection-detail.dialog.html',
    styleUrls: ['./collection-detail.dialog.scss'],
    standalone: false
})
export class CollectionDetailDialog extends ScriptableBaseComponent implements OnInit {
    // Property.
    public options: NewSiteOptions;

    public constructor(public dialogRef: MatDialogRef<CollectionDetailDialog>,
        @Inject(MAT_DIALOG_DATA) private data: any,
        elForBaseClass: ElementRef) {
        super(elForBaseClass);
        let namesMap: INameToPrettyNameMap =
        {
            'siteName': 'Site Name'
        };
        this.element('#siteNameDiv').hasInput().withNameToPrettyNameMap(namesMap); //.withAddedDateTimeSuffix();
        this.element('#dialogActions').hasButtons();

        this.options = new NewSiteOptions();
    }

    // Life cycle method(s).
    public ngOnInit(): void {
        super.ngOnInit();
    }

    // Handle dialogue actions.
    public handleModelChange(): void {
    }

    public onOkClicked(): void {
        this.dialogRef.close(this.options);
    }

    //VNEXT-1066: KLW - Property to get site name
    public get SiteName() {
        return environment.sitename;
    } 
}
