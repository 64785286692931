import { FormField } from '../form-field.model';
import { FormInstanceElement } from '../form-instance-element.model';
import { SelectOptionValue } from '../../selection-fields/select-option-value.model';
import { IGridRow } from '../../../interfaces/grid-row.interface';
import { FormFieldProcessingPhaseEnum } from '../../../enums/form-field-processing-phase.enum';
import { FormFieldOnInitPropertyEnum } from '../../../models/form-builder/form-field-on-init-output-property.enum';
import { CascadingDropDownFormFieldConfig } from '../../../models/cascading-dropdown/cascading-dropdown-config.model';
import { DropdownFieldDefLogic } from './dropdown-field-def-logic';
import { FormInstanceGridRow } from '../../site-content/form-instance-grid-row.model';

export class FlexibleSelectionFieldDefLogic extends DropdownFieldDefLogic {
    public constructor() {
        super();
    };

    // Implement IFieldDefinitionLogic methods.
    public getSelectableValuesFor(formField: FormField): SelectOptionValue[] {
        let values: SelectOptionValue[] = [];

        let arrSelectOptions: string[] = FormField.ParseSelectOptions(formField);

        for (let iOption: number = 0; iOption < arrSelectOptions.length; iOption++) {
            let selectOption: string = arrSelectOptions[iOption];

            let operandValue: SelectOptionValue =
            {
                id: iOption + 1,
                displayName: selectOption
            }

            values.push(operandValue);
        }

        return values;
    }

    // pasteDataForInto():  only allow the pasting of values that are valid for a given form field.
    public pasteDataForInto(formField: FormField, formInstanceElement: FormInstanceElement, pasteValue: string): void {
        let selectableValues: string[] = FormField.ParseSelectOptions(formField);
        let foundValue: string = (selectableValues != null ? selectableValues.find(v => v == pasteValue) : null);
        if (foundValue != null)
            formInstanceElement.textValue = pasteValue;
    }

    public getDisplayValue(formFieldParam: FormField, formInstanceElementParam: FormInstanceElement, gridRow: IGridRow, processingPhase: FormFieldProcessingPhaseEnum, gridRowColumnDefs: FormField[] = null): string {
        let value: string = '';

        if (formInstanceElementParam.childFormInstanceElements &&
            (formInstanceElementParam.childFormInstanceElements.length > 0)) {
            let iNumValuesSelected: number = 0;

            for (let iChild: number = 0; iChild < formInstanceElementParam.childFormInstanceElements.length; iChild++) {
                let child: FormInstanceElement = formInstanceElementParam.childFormInstanceElements[iChild];

                if (!child.isDeleted) {
                    if (iNumValuesSelected > 0) {
                        value += ',';
                    }

                    value += child.textValue;
                    iNumValuesSelected++;
                }
            }
        }

        return (value);
    }

    public getPropertiesRequiredByGrid(formField: FormField): any {
        let hshProperties = {
        };
        let minHeight: number = 100; // Note:  need to calculate this!!!
        if ((formField.jsonConfig != null) && (formField.jsonConfig.trim() != '')) {
            let config: CascadingDropDownFormFieldConfig = CascadingDropDownFormFieldConfig.parseJsonConfig(formField.jsonConfig);
            if ((config.dropDownHeaders != null) && (config.dropDownHeaders.length > 0)) {
                minHeight = 50 // Header
                    + (config.dropDownHeaders.length * 60) // Space for all drop-down fields.
                    + ((config.dropDownHeaders.length - 1) * 25); // Space for filler between drop-down fields.
            }
        }
        hshProperties[FormFieldOnInitPropertyEnum.REQUIRED_PREVIEW_INSTANCE_MODE_HEIGHT] = minHeight;
        hshProperties[FormFieldOnInitPropertyEnum.REQUIRED_PREVIEW_INSTANCE_MODE_HEIGHT_UNIT] = 'px';

        return hshProperties;
    }

    public unpackTemplateInstancesRow(formField: FormField, colValue: FormInstanceElement, row: FormInstanceGridRow): any {
        let fieldValue: string = null;

        if (colValue.childFormInstanceElements != null) {
            for (let index: number = 0; index < colValue.childFormInstanceElements.length; index++) {
                let childValue: FormInstanceElement = colValue.childFormInstanceElements[index];
                if (childValue.textValue != null) {
                    if (fieldValue == null)
                        fieldValue = childValue.textValue;
                    else {
                        fieldValue += ',';
                        fieldValue += childValue.textValue;
                    }
                }
            }
        }

        return fieldValue;
    }

    // Protected methods.
    protected formatValueForCompare(value: any): any {
        return value;
    }

    public filterType(): string {
        return 'text';
    }
    public isFilterable(): boolean {
        return true;
    }

    public get canParticipateInFlexFieldBehavior(): boolean {
        return true;
    }
}
