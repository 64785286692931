import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IListItem } from '../../../interfaces/ilist-item.interface';
import { BrowserStorageService } from '../../../services/browserstorage.service';
import { DragDropService } from '../../../services/drag-drop.service';
import { ListViewEnum, ListViewHelper } from '../list-view.helper';
import { BaseViewComponent } from '../base-view/base-view.component';
import { MatDialog } from '@angular/material/dialog';
import { UtilityHelper } from '../../../utility.helper';
import { CustomColumnProperty } from './extras';
import { StructureFieldConfig, ConfigureStructureFields } from '../../../models/structure/configure-structure-fields.model';
import { RoleService } from '../../../services/role.service';
import { CurrentSiteService } from '../../../services/current-site.service';

// 07-26-2024 note:  export the column names used in this component's grid view.
export const STRUCTURE_PROPERTY_SELECT = "select";
export const STRUCTURE_PROPERTY_NAME = "name";
export const STRUCTURE_PROPERTY_MODIFIED_DATE = "modifiedDate";
export const STRUCTURE_PROPERTY_MODIFIED_BY = "modifiedBy";
export const STRUCTURE_PROPERTY_TEMPLATE = "property(Template)";
export const STRUCTURE_PROPERTY_STATUS = "property(Status)";
// 07-26-2024 note:  end export of column names.

/*
 *  ----------------------------- NOTE TO DEVELOPERS ----------------------------- 
 *  Customization of Column Headers and Dynamic Property Values
 *
 *  1) Customization of Column Headers
 *     When configuring ColumnsConfig on use of a <app-list-view> you can specific custom columns headers to be used in place of standard column headers.
 *
 *     For example if you supply this as a ColumnsConfig...
 *
 *     [ColumnsConfig]="['select', 'name', 'modifiedDate', 'modifiedBy']"
 *     ...you'll get default column headers of "Name", "Modified On" and "Modified By"
 *     You can configure custom column headers by using a notation of <columnId>:<custom name>, for example...
 *
 *     [ColumnsConfig]="['select', 'name:Notification Message', 'modifiedDate:Modified', 'modifiedBy:By']"
 *     ...would result in column headers of "Notification Message", "On" and "By"
 *
 *  2) Supplying Dynamic Property Values
 *     Suppose you are using ListView to display a class called Widget which implements IListItem, and you supply this ColumnsConfig to <app-list-view>
 *
 *     [ColumnsConfig]="['select', name, 'property(WidgetPurpose)', 'property(WidgetHeight)', 'property(WidgetWidth)', modifiedDate]"
 *
 *     GridView will iterate over the "property(x)" columns producing, in this case, three columns from them.
 *     It'll use "x" as the columm header, and will call getValue(x) on each IListItem to supply values for each column
 *  
 */

class UserSiteRoleNamesInfo {
    public structureColumnsComputed: boolean = false;
    public columnsToDisplay: string[] = [];

    public constructor(public userSiteRoleNamesRetrieved: boolean = false, public userSiteRoleNames: string[] = []) {
    }
}

@Component({
    selector: 'app-grid-view',
    templateUrl: './grid-view.component.html',
    styleUrls: ['./grid-view.component.scss'],
    standalone: false
})
export class GridViewComponent extends BaseViewComponent implements OnInit, OnChanges {
    // Inputs.
    @Input() columnsConfig: string[];

    @Input() hasCheckboxes: boolean = false;
    @Input() hideActionButtons: boolean = false;

    @Input() enableInfiniteScrolling: boolean = false; // default is not enabled

    @Input() maxSelection: number = 0; // if zero, there is no limit

    @Input() renderNameAsLink: boolean = true;

    @Input() configuredStructureFields: ConfigureStructureFields;

    @Output() sortChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() exceededMaxSelection: EventEmitter<any> = new EventEmitter();

    // Properties.
    //TEAMS-424: KLW - Variables for the selection model and last selected row
    selection = new SelectionModel<IListItem>(true, []);
    lastSelectedRow = null;
    hideActionButtonsClass = '';

    //private userSiteRoleNames: string[] = [];
    private userSiteRoleNamesInfo: UserSiteRoleNamesInfo = new UserSiteRoleNamesInfo();

    private displayedColumns: string[] = ['select', 'name', 'modifiedDate', 'modifiedBy', 'status']; // set the defaults. Can be overridden by passing columnsConfig @Input param
    private columnDisplayNames: any = {
        'select': '',
        'name': 'Name',
        'childCount': 'Forms',
        'modifiedDate': 'Modified On',
        'modifiedBy': 'Modified By',
        'status': 'Status',
        'description': 'Description',
        'createdByName': 'Created By',
        'createdDate': 'Created On',
        'position': 'Published Order'
    };

    private dataSource: MatTableDataSource<IListItem> = new MatTableDataSource<IListItem>();

    // Used for the display of the drop zone above and below a row
    private idOfRepositionAbove: number;
    private idOfRepositionBelow: number;

    // This is the MatSort object associated with the <table matSort> in the template.
    // It gets passed to the data source of the grid to inform how it should sort itself
    @ViewChild(MatSort, { static: true }) matSort: MatSort;

    // This is our own custom structure. It gets serialized to JSON and stored in
    // local storage so we can persist a user's settings across sessions
    private savedSort;

    // Constructor.
    public constructor(
        private localStorageService: BrowserStorageService,
        private currentSiteService: CurrentSiteService,
        private roleService: RoleService,
        public dialog: MatDialog,
        public injectedDragDropService: DragDropService) {
        super(dialog, injectedDragDropService);
    }

    // Begin methods that handle reordering of dragged rows (pharvey for VNEXT-556)
    // The idea is that as the user drags over rows in the grid a blue reordering drop zone is displayed making it clear where a row will be go when it's dropped
    //
    // How this works:
    // <div class="reorder-drop-zone">'s inside the <td>s of the mat table handle the (dragover) event.
    // The first of thse divs in the td calls onDragOverRepositionAbove() and the last one calls onDragOverRepositionAbove()
    // These methods set idOfRepositionAbove aand idOfRepositionBelow to the id of the row containing the <div class="reorder-drop-zone"> currently being dragged over
    public onDragOverRepositionAbove(ev: DragEvent, row: IListItem) {
        this.idOfRepositionAbove = row.getId();
        this.idOfRepositionBelow = -1;
    }

    public onDragOverRepositionBelow(ev: DragEvent, row: IListItem) {
        this.idOfRepositionBelow = row.getId();
        this.idOfRepositionAbove = -1;
    }

    // These methods determine if classes should be added to the reordering div which will cause it to display
    // Note: Only the <div class="reorder-drop-zone"> in the very first <td> invokes this method.
    // The classes added will cause the div to display as a blue bar spreading across the table.
    public getRepositionAboveClass(row: IListItem): string {
        let draggedRow = this.ItemDragDataStart?.items[0];
        if (this.rowIsTargetOfReording(row, 'move-above')) {
            return "show-reposition-above";
        } else {
            return "";
        }
    }
    public getRepositionBelowClass(row: IListItem): string {
        let draggedRow = this.ItemDragDataStart?.items[0];
        if (draggedRow) {
            if (this.rowIsTargetOfReording(row, 'move-below') &&
                row.itemIndex == (this.totalListCount - 1) // only display this on the last row
            ) {
                return "show-reposition-below";
            } else {
                return "";
            }
        }
    }
    // We also want to expand the <tr> containing the <div class="reorder-drop-zone"> which is currently being dragged over...
    public getExpandRowForReorderingClass(row: IListItem): string {
        let draggedRow = this.ItemDragDataStart?.items[0];
        if (draggedRow) {
            if (this.rowIsTargetOfReording(row, 'move-above')) {
                return "expand-row-for-reordering";
            } else {
                return "";
            }
        }
    }

    // The next two methods Handle the dropping of a row on a <div class="reorder-drop-zone">
    public onDropOnRepositionAbove(ev: DragEvent, rowBeingDroppedOn: IListItem) {
        let offset = (this.ItemDragDataStart.items[0].itemIndex < rowBeingDroppedOn.itemIndex) ? -1 : null;
        this.doReordering(ev, rowBeingDroppedOn, offset);
    }
    public onDropOnRepositionBelow(ev: DragEvent, rowBeingDroppedOn: IListItem) {
        let offset = (this.ItemDragDataStart.items[0].itemIndex > rowBeingDroppedOn.itemIndex) ? 1 : null;
        this.doReordering(ev, rowBeingDroppedOn, offset);
    }

    // Prep the data for a call to the base class's onDragDrop method
    private doReordering(ev: DragEvent, rowBeingDroppedOn: IListItem, offset: number) {
        if (offset) {
            let indexOfOffsetItem = rowBeingDroppedOn.itemIndex + offset;
            let offsetRow = this.list.filter(x => { return x.itemIndex == indexOfOffsetItem; })[0];
            if (offsetRow)
                rowBeingDroppedOn = offsetRow
        }
        this.onDragDrop({ nativeEvent: ev }, rowBeingDroppedOn, null, true);
        this.clearReposition();
    }

    // Resets the variables which control whether a reordering dropzone should be displayed or not
    public clearReposition() {
        this.idOfRepositionAbove = -1;
        this.idOfRepositionBelow = -1;
    }

    // Handles the somewhat complicated conditions which determine if a reordering dropzone should be displayed or not
    private rowIsTargetOfReording(row: IListItem, direction: string): boolean {
        if (!this.listItemsAreDraggable) return false;

        let draggedRow = this.ItemDragDataStart?.items[0];
        let draggedOverRowIsTargetOfReordering = row.getId() == (direction == 'move-above' ? this.idOfRepositionAbove : this.idOfRepositionBelow);
        let draggedAndDraggedOverAreNotTheSame = draggedRow?.getId() != row.getId();
        let draggedAndDraggedOverAreOfTheSameType = draggedRow?.getType() == row.getType();

        return draggedOverRowIsTargetOfReordering && draggedAndDraggedOverAreNotTheSame && draggedAndDraggedOverAreOfTheSameType;
    }

    // End methods that handle reordering of dragged rows 

    // Life cycle methods.
    public ngOnInit() {
        if (this.columnsConfig != null) {
            let columnConfig = [];

            for (let col of this.columnsConfig) {
                // col can look like just 'description' or 'description:My Display Name'
                let internalAndDisplayName = col.split(':');
                let internalName = internalAndDisplayName[0];
                columnConfig.push(internalName);

                if (internalAndDisplayName.length == 2) {
                    let displayName = internalAndDisplayName[1];
                    this.columnDisplayNames[internalName] = displayName;
                }
            }
            this.displayedColumns = columnConfig;
        }

        if (this.typeSortOrderConfig != null) {
            this.typeSortOrder = this.typeSortOrderConfig;
        }

        this.dataSource.filterPredicate = (data: IListItem, filterValue: string) => {
            return !filterValue || data.name.indexOf(filterValue) > -1;
        }


        this.initializeSavedSort();
        this.setDataSource();

        if (this.hasCheckboxes)
            this.selection = new SelectionModel<IListItem>(true, []);

        if (this.hideActionButtons)
            this.hideActionButtonsClass = "hide-action-buttons";

        // If we have a configuration that hides at least one field, query the user's roles for the current site.
        // If we have a configuration that hides at least one field, query the user's roles for the current site.
        if ((this.configuredStructureFields != null) && this.configuredStructureFields.HasAtLeastOneHiddenField) {
            this.roleService.getUserSiteRoleNames(this.currentSiteService.Site.id).then(userRoleNames => {
                if (userRoleNames != null)
                    this.userSiteRoleNamesInfo.userSiteRoleNames = userRoleNames;
                this.userSiteRoleNamesInfo.userSiteRoleNamesRetrieved = true;
            });
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.filterTerm?.currentValue != null) {
            this.filterGridData(changes);
        } else if (changes.list) {
            this.setDataSource();
            this.clearSelection();
            super.ngOnChanges(changes);
        }
    }

    public ngDoCheck(): void {
        this.selectionsSet.emit(this.selection.selected);
    }

    public selectionChange(event: any, row: IListItem) {
        if (event) {
            this.selection.toggle(row);

            if (this.maxSelection > 0 && this.selection.selected.length > this.maxSelection) {
                this.selection.toggle(row); // unselect it
                this.exceededMaxSelection.emit();
            }
        }
    }

    public GetColumnDisplayName(internalName: string) {
        return this.columnDisplayNames[internalName];
    }

    public get DataSource(): MatTableDataSource<IListItem> {
        return (this.dataSource);
    }

    public get SavedSort(): any {
        return (this.savedSort);
    }

    // Returns the currently saved sort column
    public get MatSortActive(): string {
        return (this.savedSort['active']);
    }
    // Returns the currently saved sort direction
    public get MatSortDirection(): string {
        return (this.savedSort['direction']);
    }

    public get DisplayedColumns(): string[] {
        //return (this.displayedColumns);
        let columnsToDisplay = this.displayedColumns;

        if ((this.configuredStructureFields != null) && this.configuredStructureFields.HasAtLeastOneHiddenField) {
            if (this.userSiteRoleNamesInfo.userSiteRoleNamesRetrieved) {
                if (!this.userSiteRoleNamesInfo.structureColumnsComputed) {
                    // Compute the structure fields that the user can see.
                    let isSiteAdmin: boolean = this.currentSiteService.Site.siteIsAdministerable;

                    if (isSiteAdmin) {
                        this.userSiteRoleNamesInfo.columnsToDisplay = columnsToDisplay;
                    } else if (this.configuredStructureFields != null) {
                        columnsToDisplay = this.configuredStructureFields.filterStructureColumnNames(columnsToDisplay, this.userSiteRoleNamesInfo.userSiteRoleNames);
                        this.userSiteRoleNamesInfo.columnsToDisplay = columnsToDisplay;
                    }

                    this.userSiteRoleNamesInfo.structureColumnsComputed = true;
                } else {
                    // Use the column names computed earlier.
                    columnsToDisplay = this.userSiteRoleNamesInfo.columnsToDisplay;
                }
            } else {
                columnsToDisplay = [];
            }
        }

        return columnsToDisplay;
    }

    public get PropertyCols(): CustomColumnProperty[] {
        let cols = this.columnsConfig?.filter(x => { return x.indexOf('property(') > -1 });
        let props = [];
        if (cols) {
            for (let col of cols) {
                props.push(new CustomColumnProperty(col));
            }
        }
        return props;
    }

    public get selectAllTitle(): string {
        if (this.isAllSelected()) return 'Unselect All';
        return 'Select All';
    }

    public GridItemDragged(row) {
        if (this.selection.selected.indexOf(row) < 0) {
            this.selection.clear();
            this.selection.select(row);
            this.lastSelectedRow = row;
        }
    }

    // filters the grid's data source based on the list items name
    public doFilter(listItemName: string) {
        this.dataSource.filter = listItemName;
    }

    public clearSelection() {
        this.selection.clear();
    }

    // Define methods called by my HTML code.
    // Called when mat-table's sort is changed by cicking on a column header...
    // <table mat-table matSort(matSortChange)="saveSortState($event)"
    public saveSortState(sortState): void {
        let stateToSave = sortState;
        if (sortState.direction == '') {
            stateToSave.active = this.defaultSortColumn;
            this.matSort.direction = 'asc';
            this.matSort.active = this.defaultSortColumn;
        }

        this.updateTableSource(this.list);
        this.dataSource.sort = this.matSort;
        this.localStorageService.set(this.sortCacheKey(), JSON.stringify(stateToSave));
        this.sortChange.emit(sortState);
    }

    public updateTableSource(passedList: any) {
        this.list = passedList;

        this.setDataSource();

        this.selection.clear();
    }

    // sets the value of savedSort by trying to get it from local storage. If it's not
    // found there, then a default is set and written to local storage
    // NOTE: this method does NOT actually peform any sorting
    // this.savedSort is how we tell the mat-table in the template how it should initialize its sort column and direction
    // See MatSortActive() and MatSortDirection() which each return a property of this.savedSort
    private initializeSavedSort(): void {
        let key = this.sortCacheKey();
        let serializedSort = this.localStorageService.get(key);
        let savedSort = {};
        if (serializedSort) {
            savedSort = JSON.parse(serializedSort);
        }

        if (savedSort['direction'] == '') {
            this.savedSort = { active: this.defaultSortColumn, direction: 'asc' }; // set a default
        }
        else {
            this.savedSort = savedSort;
        }
        this.localStorageService.set(key, JSON.stringify(this.savedSort));
    }

    private sortCacheKey(): string {
        return ListViewHelper.sortKey(this.listType, ListViewEnum.GRID_VIEW);
    }

    /** Whether the number of selected elements matches the total number of rows. */
    public isAllSelected() {
        const numSelected = this.selection.selected?.length;
        const numRows = this.dataSource.data?.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    public masterToggle() {
        if (this.isAllSelected()) {
            this.selection.clear();
            return;
        }

        this.selection.select(...this.dataSource.data);
    }

    public itemSelected(item) {
        this.selection.clear();
        this.selection.select(item);
    }

    public rowClicked(event, row) {
        //Get the current displayed rows with any sorting and filtering applied
        let displayedRows = this.dataSource.connect().value;

        if (event.shiftKey) {
            this.selection.clear();

            //Must get the indexes of the last row clicked and current row clicked
            let indexOfLastClicked = displayedRows.indexOf(this.lastSelectedRow);
            let indexOfCurrentSelection = displayedRows.indexOf(row);

            if (indexOfLastClicked < indexOfCurrentSelection) {
                this.setSelectionGroup(displayedRows, indexOfLastClicked, indexOfCurrentSelection);
            }

            if (indexOfLastClicked > indexOfCurrentSelection) {
                this.setSelectionGroup(displayedRows, indexOfCurrentSelection, indexOfLastClicked);
            }
        }

        if (event.ctrlKey) {
            this.ctrlSelect(row);
        }

        if (!event.shiftKey && !event.ctrlKey) {
            this.selection.clear();
            this.selection.select(row);
        }

        this.lastSelectedRow = row;
    }

    public ctrlSelect(row) {
        if (this.selection.isSelected(row))
            this.selection.deselect(row);
        else
            this.selection.select(row);

        this.lastSelectedRow = row;
    }

    public onRightClick(row) {
        if (!this.selection.selected.some(x => x == row)) {
            this.selection.clear();
            this.selection.select(row);

            this.lastSelectedRow = row;
        }
    }

    public setSelectedRow(row) {
        this.lastSelectedRow = row;
    }

    public onKeyDown($event): void {
        this.handleCtrlA($event);
    }

    //Prevent Ctrl+A from selecting all HTML elements in the table
    public handleCtrlA($event) {
        let charCode = String.fromCharCode($event.which).toLowerCase();
        if ($event.ctrlKey && charCode === 'a') {
            $event.preventDefault();
            this.selection.select(...this.dataSource.data);
        }
    }

    private setDataSource() {
        this.dataSource = new MatTableDataSource(this.list);
        this.dataSource.sortData = (data, sort) => {
            return this.doSortListData(data, this.matSort.direction, this.matSort.active);
        }

        // pharvey - 2/14/2022 - we used to rely on the parent of the ListView to pre-sort the items, but not all parents do this
        // In any case it's better to have the sorting happen automatically, and without this that doesn't happen on initialization
        UtilityHelper.runWhenStackClear(() => {
            this.dataSource.sort = this.matSort;
        });
    }

    private filterGridData(changes: SimpleChanges) {
        this.dataSource.filter = changes.filterTerm.currentValue;
    }

    private setSelectionGroup(rows, start: number, end: number) {
        let sliced = rows.slice(start, end + 1);

        sliced.forEach(x => {
            this.selection.select(x);
        });
    }
}
