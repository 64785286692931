import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ItemTypeEnum } from '../../../enums/item-type.enum';
import { IListItem } from '../../../interfaces/ilist-item.interface';
import { FabricIconSizes } from '../../../mappings/fabric-file-types-icon.mapping';
import { DataCollection } from '../../../models/site-content/data-collection.model';
import { DragDropService } from '../../../services/drag-drop.service';
import { BrowserStorageService } from '../../../services/browserstorage.service';
import { BaseViewComponent } from '../base-view/base-view.component';
import { ListViewEnum, ListViewHelper } from '../list-view.helper';

@Component({
    selector: 'app-tile-view',
    templateUrl: './tile-view.component.html',
    styleUrls: ['./tile-view.component.scss'],
    standalone: false
})
export class TileViewComponent extends BaseViewComponent implements OnInit, OnChanges {
    // Inputs.
    @Input() icon: string;
    //TEAMS-424: KLW - Input to accept if view should have checkboxes or not
    @Input() hasCheckboxes: boolean = false;
    @Input() hideActionButtons: boolean = false;

    private selection: SelectionModel<IListItem> = new SelectionModel<IListItem>(true, []);
    private lastSelectedRow = null;
    private hideActionButtonsClass: string = '';

    // Properties.
    private defaultIcon: string = 'note';

    // holds a copy of the original list which
    // is used to render the view while honoring
    // search terms entered by the user
    private filteredList: IListItem[];

    private propetyToSortBy = 'name';
    @Output() sortChange: EventEmitter<any> = new EventEmitter<any>();

    // Constructor.
    constructor(private localStorageService: BrowserStorageService,
                public dialog: MatDialog,
                public injectedDragDropService: DragDropService) {
        super(dialog, injectedDragDropService);
    }

    // Life cycle methods.
    public ngOnInit() {
        //TEAMS-424: KLW - Create a selection model if checkboxes are required
        if (this.hasCheckboxes)
            this.selection = new SelectionModel<IListItem>(true, []);

        if (this.typeSortOrderConfig != null) {
            this.typeSortOrder = this.typeSortOrderConfig;
        }

        if (this.hideActionButtons)
            this.hideActionButtonsClass = "hide-action-buttons";

        //this.sortChange.emit(this.localStorageService.get(this.sortCacheKey()));
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.filterTerm) {
            if (changes.filterTerm.currentValue) {
                this.filteredList = this.copyOfOriginalList().filter(x => {
                    return x.name.toLowerCase().indexOf(
                        changes.filterTerm.currentValue.toLowerCase()
                    ) > -1;
                });
            } else {
                this.initFilteredList();
            }
        }else if (changes.list) {
            this.initFilteredList();
        } else if (changes.sortDirection) {
            this.saveSortState({ active: this.propetyToSortBy, direction: changes.sortDirection.currentValue })
        }

        // so this gets called on every @Input change of any kind, shouldn't it be in ngOnInit?
        this.filteredList = this.doSortListData(this.filteredList, this.sortDirection, this.propetyToSortBy);

        if (changes.list) {
            if (this.selection)
                this.selection.clear();
        }
    }

    // move to base class to DRY it
    public saveSortState(sortState): void {
        this.localStorageService.set(this.sortCacheKey(), JSON.stringify(sortState));
        this.sortChange.emit(sortState); // new 
    }
    // move to base class to DRY it
    private sortCacheKey(): string {
        return ListViewHelper.sortKey(this.listType, ListViewEnum.TILE_VIEW);
    }

    public ngDoCheck(): void {
        this.selectionsSet.emit(this.selection.selected);
    }

    //TEAMS-424: KLW - Set the attachment icon size to large
    public get fabricIconSize(): number {
        return FabricIconSizes.LARGE;
    }

    // Accessor methods called by my HTML code.
    public get Selection(): SelectionModel<IListItem> {
        return (this.selection);
    }

    public get HideActionButtonsClass(): string {    
        return (this.hideActionButtonsClass);
    }

    public get FilteredList(): IListItem[] {
        return (this.filteredList);
    }

    public get DefaultIcon(): string {
        return (this.defaultIcon);
    }

    //VNEXT-473: KLW - Changes to have Tile View update after an attachment is added
    public updateTableSource(passedList: any) {
        this.filteredList = this.doSortListData(passedList, this.sortDirection, this.propetyToSortBy);
    }

    public TileItemDragged(item) {
        if (this.selection.selected.indexOf(item) < 0) {
            this.selection.clear();
            this.selection.select(item);
            this.lastSelectedRow = item;
        }
    }

    public isAttachmentIcon(item: DataCollection): boolean {
        let retVal: boolean = false;

        if (item.getType() == ItemTypeEnum.ATTACH_W_URLS)
            retVal = true;

        return retVal;
    }

    /** Whether the number of selected elements matches the total number of rows. */
    public isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.filteredList.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    public masterToggle() {
        if (this.isAllSelected()) {
            this.selection.clear();
            return;
        }

        this.selection.select(...this.filteredList);
    }

    public rowClicked(item) {
        this.selection.clear();
        this.selection.select(item);
    }

    public ctrlSelect(row) {
        if (this.selection.isSelected(row))
            this.selection.deselect(row);
        else
            this.selection.select(row);

        this.lastSelectedRow = row;
    }

    public onRightClick(row) {
        if (!this.selection.selected.some(x => x == row)) {
            this.selection.clear();
            this.selection.select(row);

            this.lastSelectedRow = row;
        }
    }

    public setSelectedItem(event, item) {
        if (event.shiftKey) {
            this.selection.clear();

            //Must get the indexes of the last row clicked and current row clicked
            let indexOfLastClicked = this.filteredList.indexOf(this.lastSelectedRow);
            let indexOfCurrentSelection = this.filteredList.indexOf(item);

            if (indexOfLastClicked < indexOfCurrentSelection) {
                this.setSelectionGroup(this.filteredList, indexOfLastClicked, indexOfCurrentSelection - 1);
            }

            if (indexOfLastClicked > indexOfCurrentSelection) {
                this.setSelectionGroup(this.filteredList, indexOfCurrentSelection + 1, indexOfLastClicked);
            }
        }

        this.lastSelectedRow = item;
    }

    public onKeyDown($event): void {
        this.handleCtrlA($event);
    }

    //Prevent Ctrl+A from selecting all HTML elements in the table
    public handleCtrlA($event) {
        let charCode = String.fromCharCode($event.which).toLowerCase();
        if ($event.ctrlKey && charCode === 'a') {
            $event.preventDefault();
            this.selection.select(...this.filteredList);
        }
    }

    private setSelectionGroup(rows, start: number, end: number) {
        let sliced = rows.slice(start, end + 1);

        sliced.forEach(x => {
            this.selection.select(x);
        });
    }

    private initFilteredList() {
        this.filteredList = this.copyOfOriginalList();
    }

    private copyOfOriginalList() {
        return Object.assign([], this.list);
    }
}
