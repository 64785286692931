import {
    Component, ElementRef, EventEmitter, OnInit, Output, Renderer2, Type as AngularCoreType, ViewChild
} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { FormFieldProcessingPhaseEnum } from '../../../enums/form-field-processing-phase.enum';
import { IGridRow } from '../../../interfaces/grid-row.interface';
import { FormFieldPropertyEnum } from '../../../models/form-builder/form-field-property-enum.model';
import { FormField } from '../../../models/form-builder/form-field.model';
import { FormInstanceElement, FormInstanceElementValueTypeEnum } from '../../../models/form-builder/form-instance-element.model';
import { GridFormInstanceElementWrapper } from '../../../models/grid/grid-form-instance-element-wrapper.model';
import { ControlType } from '../form-field-base/form-field-base.component';
import { InputFormFieldBaseComponent } from '../input-form-field-base/input-form-field-base.component';


// Note:  please note the 'providers' definition below, as it is needed.
//        Without it, you will get the following exception:
//
//             No value accessor for form control with unspecified name
//
// The above exception gets thrown when a component, in this case our
// base class, implements interface 'ControlValueAccessor' and does not
// provide the 'providers' definition below.  Implementing the
// 'ControlValueAccessor' interface allows a form field component to
// support [(ngMode)], so users of the component can use [(ngModel)].
@Component({
    selector: 'app-checkbox-form-field',
    templateUrl: './checkbox-form-field.component.html',
    styleUrls: ['./checkbox-form-field.component.scss', '../form-fields.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: CheckboxFormFieldComponent,
            multi: true
        }
    ],
    standalone: false
})
export class CheckboxFormFieldComponent extends InputFormFieldBaseComponent implements OnInit {
    // Properties.
    // Note:  several properties are implemented in my base class.
    @Output() onInit = new EventEmitter();

    private readonly formFieldProperties: string[] =
        [
            FormFieldPropertyEnum.NAME,
            FormFieldPropertyEnum.FIELD_GROUP,
            FormFieldPropertyEnum.DISPLAY_NAME,
            FormFieldPropertyEnum.HELP_TEXT,
            FormFieldPropertyEnum.TOOL_TIP,
            FormFieldPropertyEnum.DEFAULT_VALUE,
            FormFieldPropertyEnum.INSTRUCTIONS_TEXT,
            FormFieldPropertyEnum.GRID_COLUMN_WIDTH
        ];

    @ViewChild('matCheckbox') matCheckboxElement: ElementRef;

    private bSaveInProgress: boolean = false;

    // Constructor.
    constructor(rendererParam: Renderer2) {
        super(rendererParam);

        this.matInputId = this.generateUniqueId('checkbox');

        return;
    }

    // Implement abstract methods.
    public getProperties(): any {
        let hshProperties = {
            component: this,
            formField: this.formField,
            properties: this.formFieldProperties,

            saveDataRequired: true // 02-23-2021:  added this property, needed to make sure a
            //              checkbox default value does not get applied
            //              after a form instance save.
        };

        return (hshProperties);
    }

    // Life cycle methods.
    public ngOnInit(): void {
        let hshProperties = this.getProperties();

        this.onInit.emit(hshProperties);

        return;
    }

    // Accessor methods.
    public get DefaultCheckboxValue(): boolean {
        let bChecked: boolean = false;

        if (this.FormField) {
            if ((this.FormField.defaultValue !== undefined) && (this.FormField.defaultValue !== null)) {
                if (this.FormField.defaultValue.toLowerCase() === 'true') {
                    bChecked = true;
                }
            }
        }

        return (bChecked);
    }

    public set DefaultCheckboxValue(bValue: boolean) {
        // Note:  this method is a NOOP on purpose.

        return;
    }

    // Implement saveData()
    public saveData(formInstance: any): void {
        this.bSaveInProgress = true;

        // Force the caller to 'remember' my data by setting
        // a value for my form field Id in the provided hash.
        //
        // The following line causes the caller also to call
        // saveCompleted(), the following method.
        //hshComponentDatatoSave[this.formField.id] = this.formField;

        return;
    }

    //public saveCompleted(hshSavedComponentData: any): void {
    public saveCompleted(formInstance: any): void {
        // The fact that we are receiving this call
        // means that a save was in progress.
        this.bSaveInProgress = true;
        // Setting a zero milliseconds timeout will
        // allow the following method,
        // formInstanceElementReceived(), to be
        // executed before the code to reset the
        // 'bSaveInProgress' property is reset to
        // false.
        setTimeout(() => {
            this.bSaveInProgress = false;
        }, 0);

        return;
    }

    // Handle getting this field's form instance element.
    protected formInstanceElementReceived(): void {
        if ((this.Mode === 'preview') || (this.Mode === 'instance')) {
            if (this.ControlType === ControlType.REACTIVE_FORMS) {
                // If I have no value assigned but
                // have a default value, apply it now.
                if ((this.FormInstanceElement.UserUpdatedData != true) &&
                    (!this.bSaveInProgress)) {
                    // 02-22-2021 note:  this if statement is evaluating to true for a
                    //                   checkbox form field that has a default value of
                    //                   true even after the user enters and saves a value
                    //                   of false.  After the save completes, this method
                    //                   executes, and reset the user-entered false value to true.
                    //
                    // Note:  this comment will be removed when the above bug is fixed.                   
                    this.setDefaultBooleanValue();
                }

                // Setup my Reactive Forms data structure.
                this.setupBooleanFormControl();
            }
        }

        return;
    }


    // protected writeValueTriggered(): void {
    //     if ((this.Mode === 'preview') || (this.Mode === 'instance')) {
    //         if (this.ControlType === ControlType.REACTIVE_FORMS) {
    //             // If I have no value assigned but
    //             // have a default value, apply it now.
    //             if ((this.FormInstanceElement.UserUpdatedData != true) &&
    //                 (!this.bSaveInProgress)) {
    //                 // 02-22-2021 note:  this if statement is evaluating to true for a
    //                 //                   checkbox form field that has a default value of
    //                 //                   true even after the user enters and saves a value
    //                 //                   of false.  After the save completes, this method
    //                 //                   executes, and reset the user-entered false value to true.
    //                 //
    //                 // Note:  this comment will be removed when the above bug is fixed.                   
    //                 this.setDefaultBooleanValue();
    //             }

    //             // Setup my Reactive Forms data structure.
    //             this.setupBooleanFormControl();
    //         }
    //     }

    //     return;
    // }


    //TEAMS-561: KLW - Implement the first instance of writeValueTrigger which will eventually replace formInstanceElementReceived
    protected writeValueTriggered(): void {
        if ((this.Mode === 'preview') || (this.Mode === 'instance')) {
            this.SetupFormControlFromWriteValue();

            /*
            // For this field type, treat a value change as a blue event.
            let localFormControl: FormControl = this.FormControl;
            if (localFormControl != null) {
                localFormControl.valueChanges.subscribe(updatedValue => {
                    super.handleOnBlur();
                });
            }
            */
        }

        return;
    }

    // Override notifyValueChanged.
    protected notifyValueChanged(): void {
        super.notifyValueChanged();

        super.handleOnBlur();
    }

    // Override the getDisplayValue() base class method.
    // Define a method that allows a component to return its display value.
    public pseudoStatic_getDisplayValue(formFieldParam: FormField,
        formInstanceElementParam: FormInstanceElement,
        gridRow: IGridRow,
        processingPhase: FormFieldProcessingPhaseEnum): string {
        if ((!formInstanceElementParam.UserUpdatedData) ||
            (!formInstanceElementParam.booleanValue)) {
            // Set a default value.
            this.setDefaultBooleanValue();

            formInstanceElementParam.BooleanValue = this.FormInstanceElement.booleanValue;
        }

        return (formInstanceElementParam.booleanValue ? '<span class="material-icons-outlined">check_box</span>' : '<span class="material-icons-outlined">check_box_outline_blank</span>');
    }

    public pseudoStatic_pasteValue(value: string, elementWrapper: GridFormInstanceElementWrapper, formField: FormField): void {
        elementWrapper.formInstanceElement.booleanValue = false;
        elementWrapper.standinDisplayValue = '<span class="material-icons-outlined">check_box_outline_blank</span>';
        elementWrapper.formInstanceElement.valueType = FormInstanceElementValueTypeEnum.TypeBoolean;
        if (value.toLowerCase() == "true") {
            elementWrapper.formInstanceElement.booleanValue = true;
            elementWrapper.standinDisplayValue = '<span class="material-icons-outlined">check_box</span>';
        }
    }

    // Override a method used to get my class.
    public getFormFieldClass(): AngularCoreType<any> {
        return (CheckboxFormFieldComponent);
    }

    // Override some base class methods.
    public handleOnBlur(): void {
        super.handleOnBlur();
    }

    public handleOnFocus(eventData: FocusEvent): void {
        super.handleOnFocus(eventData);
    }

    // Implement doSetFocus().
    protected doSetFocus(): void {
        setTimeout(() => {
            this.doDoSetFocus();
        }, 1000);

        // 01-04-2021 note:  the following code is not yet working.
        /*
        let inputSelector: string = `#${this.matInputId}`;
        let matInput: any = this.renderer.selectRootElement(inputSelector);
        matInput.focus();
        */

        return;
    }

    private doDoSetFocus(): void {
        let matCheckbox: MatCheckbox = <MatCheckbox><unknown>this.matCheckboxElement;
        matCheckbox.focus();

        return;
    }
}
