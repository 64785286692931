import { ComponentFactoryResolver, Directive, ElementRef, HostBinding, Input, OnInit, Renderer2, ViewContainerRef } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { IconFormatEnum } from '../enums/icon-format.enum';
import { ItemTypeEnum } from '../enums/item-type.enum';
import { FabricFileTypeIconMapping, FabricIconSizes, IFileTypeIconMap } from '../mappings/fabric-file-types-icon.mapping';
import { DataCollection } from '../models/site-content/data-collection.model'; 

@Directive({
    selector: '[appIcon]',
    standalone: false
})
export class IconDirective implements OnInit {

    private fabricIconBaseURL: string = 'https://static2.sharepointonline.com/files/fabric/assets/item-types';

    protected _elementClass: string[] = [];

    @HostBinding('class')
    get elementClass(): string {
        return this._elementClass.join(' ');
    }
    //set(val: string) {
    //    this._elementClass = val.split(' ');
    //}

    @Input() appIcon: DataCollection;

    @Input() appIconItemType: string;
    @Input() fileName: string;
    @Input() iconFormat: string;
    @Input() fabricIconSize: number = FabricIconSizes.SMALL;
    @Input() matIconText: string = '';

    @Input() fabricIconName: string = '';
    @Input() svgIcon: string = '';

    private elementRef: ElementRef;

    private _fileTypeIconMap: IFileTypeIconMap[] = null;

    constructor(private el: ElementRef, private domSanitizer: DomSanitizer) {
        this.elementRef = el;
    }

    get FileTypeIconMap(): IFileTypeIconMap[] {
        if (this._fileTypeIconMap == null) {
            this._fileTypeIconMap = new FabricFileTypeIconMapping().FileTypeIconMap;
        }

        return this._fileTypeIconMap;
    }

    /*Examples of how to implement
    *
    * -----Material Icons not from item base property
    *
    * You would want to overwrite the following properties;
    *
    * appIconItemType - Set to whatever case statement covers material icons ie ItemTypeEnum.ATTACHMENT_ACTIVITY
    * matIconText - Set to the key word used for icon ie "edit"
    *
    * Example:
    * <i [appIcon] matIconText="edit" appIconItemType="{{this.ActivityListIcon}}"></i>
    *
    *
    * -----Material Icons from item base property
    *
    * Be sure your object class has the inherited method "getIcon()" returning the key word used for the icon and there is a case statement accounting for your ItemTypeEnum item type
    *
    * -----Fabric Icons (File Type)
    *
    * This is based on the extension of a file or attachment name. Pass in the entire object itself to this directive and be sure your ItemTypeEnum item type is accounted for in the
    * case statements. The directive will handle the rest
    *
    * Example:
    * <i class="grid-icon" [appIcon]="row"></i>
    *
    *-----Fabric Icons (Explicit Reference)
    *
    * To see what key to use for your file type, such as excel, word, etc. get it from here:
    *
    * https://developer.microsoft.com/en-us/fluentui#/styles/web/file-type-icons
    *
    * If you want to use a fabric icon directly do the following, first setup your values in the code behind
    *
    * fabricName: string = FabricIconNamesEnum.ARCHIVE;
    * itemType: string = ItemTypeEnum.FABRIC_ICON;
    *
    * Then assign the values in the HTML after calling the appIcon directive, remember to set a CSS class to have a height and width to the icon:
    * 
    * <i [appIcon] fabricIconName="{{this.fabricName}}" appIconItemType="{{this.itemType}}" class="upload-icon"></i>
    * 
    *-----SVG Icons
    *
    * In the code behind:
    *
    * itemType: string = ItemTypeEnum.SVG_ICON;
    * 
    * In the HTML, set svgIcon as the svg icon name:
    * <i [appIcon] svgIcon="trash" appIconItemType="itemType"></i>
    *
    * 
    */
    ngOnInit(): void {

        if (this.appIcon) {
            if (!this.appIconItemType)
                this.appIconItemType = this.appIcon.getType();
        }

        switch (this.appIconItemType) {
            //KLW - Adding logic to use a fabric icon explicitly
            case ItemTypeEnum.FABRIC_ICON:
                { 
                    let inner = '<img src="' + this.fabricIconBaseURL + '/' + this.fabricIconSize + '/' + this.fabricIconName + '.svg" alt="' + this.fabricIconName + '" class="center-icon">';
                    this.elementRef.nativeElement.innerHTML = inner;
                    break;
                }

            case ItemTypeEnum.SVG_ICON:
                {
                    let inner = '<img src="../assets/svg/' + this.svgIcon + '.svg" class="center-icon">';
                    this.elementRef.nativeElement.innerHTML = inner;
                    break;
                }

            case ItemTypeEnum.ATTACH_W_URLS:
            case ItemTypeEnum.FILE:
                {
                    var fileIconProperties: IFileTypeIconMap = null;

                    if (this.appIcon.name) {
                        this.fileName = this.appIcon.name;
                        fileIconProperties = this.getAssociatedFabricIconProperties(this.getFileExtension(this.fileName));

                        if (fileIconProperties) {
                            this.iconFormat = fileIconProperties.value.iconFormat;

                            switch (this.iconFormat) {
                                case IconFormatEnum.FABRIC_ICON: {
                                    let inner = '<img src="' + this.fabricIconBaseURL + '/' + this.fabricIconSize + '/' + fileIconProperties.key + '.svg" alt="' + fileIconProperties.key + '" class="center-icon">';
                                    this.elementRef.nativeElement.innerHTML = inner;

                                    break;
                                }
                            }
                        }
                        else { //Default Fabric Icon
                            this.elementRef.nativeElement.innerHTML = '<img src="https://static2.sharepointonline.com/files/fabric/assets/item-types/48/genericfile.svg" class="center-icon">';
                        }
                    }
                    break;
            }
            // this catches me every time I add a new use of ListView and wonder why icons don't display -- I forget to add the type here
            // TODO: It would be good to find a way for IconDirective to not need to know about the types of thing using it
            case ItemTypeEnum.DATACOLLECTION:
            case ItemTypeEnum.FORM:
            case ItemTypeEnum.FORM_INSTANCE:
            case ItemTypeEnum.FOLDER:
            case ItemTypeEnum.ATTACHMENT_ACTIVITY:
            case ItemTypeEnum.WORKFLOW:
            case ItemTypeEnum.NOTIFICATION:
            case ItemTypeEnum.SUBSCRIPTION:
            case ItemTypeEnum.ICON:
                {
                    this._elementClass.push('material-icons');
                    if (this.matIconText == '')
                        this.matIconText = this.appIcon.getIcon();

                    this.elementRef.nativeElement.innerHTML = this.matIconText;

                    break;
                }
            case ItemTypeEnum.DOCUMENT_PUBLISH_SETTINGS:
                {
                    var fileIconProperties: IFileTypeIconMap = this.getAssociatedFabricIconProperties(this.appIcon.getIcon());
                    this.iconFormat = fileIconProperties.value.iconFormat;
                    let inner = '<img src="' + this.fabricIconBaseURL + '/' + this.fabricIconSize + '/' + fileIconProperties.key + '.svg" alt="' + fileIconProperties.key + '" class="center-icon">';

                    this.elementRef.nativeElement.innerHTML = inner;

                    break;
                }

            default:
        }

    }

    private getFileExtension(fileName: string): string {
        var retVal = '';

        if (fileName)
            retVal = fileName.split('.').pop();

        return retVal;
    }

    private getAssociatedFabricIconProperties(extension: string): IFileTypeIconMap {
        var retVal: IFileTypeIconMap = null;

        if (extension) {
            var findKey = this.FileTypeIconMap.find(x => x.value.extensions.find(y => y == extension));

            if (findKey) {
                retVal = findKey;
            }
        }

        return retVal;
    }
}
