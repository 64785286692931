import { FormField } from '../form-field.model';
import { FormInstanceElement } from '../form-instance-element.model';
import { DefaultFieldDefinitionClientLogic } from './default-field-def-client-logic';
import { NumericComparisonTypesFieldDefinitionLogicBase } from './numeric-compare-base-field-def-logic';
import { DateUtil } from '../../../utility-classes/date.util';
import { IGridRow } from '../../../interfaces/grid-row.interface';
import { FormFieldProcessingPhaseEnum } from '../../../enums/form-field-processing-phase.enum';
import { CustomDateFormatDirective } from '../../../directives/custom-date-format.directive';
// Note:  we should move the implementation of the DateFormFieldComponent static method called in this file into this file.
import { DateFormFieldComponent } from '../../../components/form-fields/date-form-field/date-form-field.component';
import { FormFieldOnInitPropertyEnum } from '../../../models/form-builder/form-field-on-init-output-property.enum';
import { FormInstanceGridRow } from '../../site-content/form-instance-grid-row.model';

export class DateFieldDefLogic extends NumericComparisonTypesFieldDefinitionLogicBase { //DefaultFieldDefinitionClientLogic {
    // Constructor.
    public constructor() {
        super();
    };

    // pasteDataForInto():  only allow the pasting of values that are valid for a given form field.
    public pasteDataForInto(formField: FormField, formInstanceElement: FormInstanceElement, pasteValue: string): void {
        // Note:  this method is not yet implemented.
    }

    // Sorting.
    public compareAscendingFor(formField: FormField, value1: FormInstanceElement, value2: FormInstanceElement): number {
        let compareResult: number = 0;

        if ((value1.textValue != null) && (value2.textValue != null)) {
            let intValue1: number = DateUtil.DateToSecondsSinceJan1_1970(value1.textValue);
            let intValue2: number = DateUtil.DateToSecondsSinceJan1_1970(value2.textValue);

            if (intValue1 > intValue2)
                compareResult = 1;
            else if (intValue1 < intValue2)
                compareResult = -1;
        } else if (value1.textValue != null) {
            compareResult = 1;
        } else {
            compareResult = -1;
        }

        return compareResult;
    }

    public compareValuesAscending(value1: any, value2: any): number {
        let compareResult: number = 0;

        if ((value1 != null) && (value2 != null)) {
            let formattedValue1: any = this.formatValueForCompare(value1);
            let formattedValue2: any = this.formatValueForCompare(value2);

            if (formattedValue1 > formattedValue2)
                compareResult = 1;
            else if (formattedValue1 < formattedValue2)
                compareResult = -1;
        } else if (value1 != null) {
            compareResult = 1;
        } else if (value2 != null) {
            compareResult = -1;
        }

        return compareResult;
    }
    public compareValuesDescending(value1: any, value2: any): number {
        let compareResult: number = - this.compareValuesAscending(value1, value2);
        return compareResult;
    }

    public getDisplayValue(formFieldParam: FormField, formInstanceElementParam: FormInstanceElement, gridRow: IGridRow, processingPhase: FormFieldProcessingPhaseEnum, gridRowColumnDefs: FormField[] = null): string {
        //if ((!formInstanceElementParam.transientValueSetFlag) ||
        if ((!formInstanceElementParam.UserUpdatedData) ||
            (!formInstanceElementParam.textValue)) {
            // Set a default value.
            formInstanceElementParam.TextValue = '';
        }

        if (formInstanceElementParam.textValue == 'Invalid Date') return 'Invalid Date';

        let d = new Date(formInstanceElementParam.textValue);
        if (!(d instanceof Date && !isNaN(d.valueOf()))) {
            if (formInstanceElementParam.textValue.trim().length == 0) {
                return '';
            }
            else {
                return 'Unrecognized Date ' + formInstanceElementParam.textValue;
            }
        }

        // Note:  we have to use the display format for the provided form field definition.
        // Note:  we should move the implementation of the DateFormFieldComponent static method called in this file into this file.
        let strDatePipeFormat: string = DateFormFieldComponent.displayFormatToDatePipeFormat(formFieldParam);

        let strValue: string =
            (formInstanceElementParam.textValue ?
                CustomDateFormatDirective.applyDateFormattingUsing(strDatePipeFormat, formInstanceElementParam.textValue) :
                '');

        return (strValue);
    }

    public getPropertiesRequiredByGrid(formField: FormField): any {
        let hshProperties = {
        };
        hshProperties[FormFieldOnInitPropertyEnum.REQUIRED_PREVIEW_INSTANCE_MODE_HEIGHT] = 80;
        hshProperties[FormFieldOnInitPropertyEnum.REQUIRED_PREVIEW_INSTANCE_MODE_HEIGHT_UNIT] = 'px';

        return hshProperties;
    }

    // Implement protected methods.
    protected formatValueForCompare(value: any): any {
        let result: number = 0;

        if (value != null)
            result = DateUtil.DateToSecondsSinceJan1_1970(value.toString());

        return result;
    }

    public filterType(): string {
        return 'date';
    }

    public isFilterable(): boolean {
        return true;
    }

    public includeInFormTemplateInstancesGrid(): boolean {
        return true;
    }

    public unpackTemplateInstancesRow(formField: FormField, colValue: FormInstanceElement, row: FormInstanceGridRow): any {
        return colValue.textValue;
    }

    public get canParticipateInFlexFieldBehavior(): boolean {
        return true;
    }
}
