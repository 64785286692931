import { Component, OnInit, Input } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormControl } from '@angular/forms';

import { VnextReleaseFeatures } from '../../models/vnext-release-features.model';
import { VnextReleaseFeaturesService } from '../../services/vnext-release-features.service';

// Export a mode enum for this dialog.
export enum VnextReleaseFeaturesDialogMode {
    EditReleaseFeatures = 'EditReleaseFeatures',
    ViewReleaseFeatures = 'ViewReleaseFeatures'
}

@Component({
    selector: 'app-vnext-release-features',
    templateUrl: './vnext-release-features.dialog.html',
    styleUrls: ['./vnext-release-features.dialog.scss'],
    standalone: false
})
export class VnextReleaseFeaturesDialog implements OnInit {
    // Properties.
    private readonly mode: VnextReleaseFeaturesDialogMode = VnextReleaseFeaturesDialogMode.EditReleaseFeatures;

    private releaseFeatures: VnextReleaseFeatures = null;
    private editFormControl: UntypedFormControl = new UntypedFormControl('');
    private savingReleaseFeaturesFlag: boolean = false;

    // Constructor.
    public constructor(private dialogRef: MatDialogRef<VnextReleaseFeaturesDialog>,
        //private dialog: MatDialog,
        private releaseFeaturesService: VnextReleaseFeaturesService) {
        return;
    }

    // Life cycle methods.
    public ngOnInit(): void {
        this.editFormControl.disable();
        this.releaseFeaturesService.getCurrentReleaseFeatures().then(response => {
            this.releaseFeatures = response;

            this.editFormControl = new UntypedFormControl(this.releaseFeatures.newFeatures != null ? this.releaseFeatures.newFeatures : '');
            this.editFormControl.enable();
            this.editFormControl.valueChanges.subscribe(value => {
                this.releaseFeatures.newFeatures = value;
            });

        });

        return;
    }

    // Methods called by my .html code.
    public get ReleaseFeaturesRetrieved(): boolean {
        return this.releaseFeatures != null;
    }

    public get Title(): string {
        return (this.mode == VnextReleaseFeaturesDialogMode.ViewReleaseFeatures ? 'View Release Features' : 'Edit Release Features');
    }

    public get ReleaseFeatures(): VnextReleaseFeatures {
        return this.releaseFeatures;
    }
    public get NewReleaseFeaturesHtml(): string {
        let enteredHtml: string = (this.releaseFeatures != null ? this.releaseFeatures.newFeatures : null);
        let html: string =
            '<html>' +
            '  <body>' +
            (enteredHtml != null ? enteredHtml : '    <p>Enter HTML in the left text area.</p>') +
            '  </body>' +
            '</html>';
        return html;
    }

    public get EditFormControl(): UntypedFormControl {
        return this.editFormControl;
    }
    public get NumNewFeaturesTextAreaRows(): number {
        return 20;
    }
    public get NumNewFeaturesTextAreaCols(): number {
        return 100;
    }

    public get SaveButtonText(): string {
        return (this.savingReleaseFeaturesFlag ? 'Saving' : 'Save');
    }
    public get SavingReleaseFeaturesFlag(): boolean {
        return this.savingReleaseFeaturesFlag;
    }

    // Handle control action events.
    public saveReleaseFeatures(): void {
        this.savingReleaseFeaturesFlag = true;
        this.releaseFeaturesService.saveCurrentReleaseFeatures(this.releaseFeatures).then(updatedReleaseFeatures => {
            this.savingReleaseFeaturesFlag = false;
        });
    }

    public cancelOrCloseClicked(): void {
        this.dialogRef.close(null);
    }

}
