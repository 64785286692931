import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ButtonConfig } from '../../../shared/components/list-view/list-view-button-config.model';
import { TrashConfig } from '../../../shared/components/list-view/list-view-trash-config.model';
import { DocumentPublishingSettingsEditorDialog } from '../../../shared/dialogs/document-publishing/document-publishing-settings-editor.dialog';
import { IListItem } from '../../../shared/interfaces/ilist-item.interface';
import { DocumentPublishingSettings } from '../../../shared/models/document-publishing-settings.model';
import { DataCollection } from '../../../shared/models/site-content/data-collection.model';
import { DeleteOnConfirmDialogService } from '../../../shared/services/delete-on-confirm-dialog.service';
import { DocumentPublishingService } from '../../../shared/services/document-publishing.service';

@Component({
    selector: 'app-document-publishing',
    templateUrl: './document-publishing.component.html',
    styleUrls: ['./document-publishing.component.scss'],
    standalone: false
})
export class DocumentPublishingComponent implements OnInit {

    private settings: DocumentPublishingSettings[] = [];
    private site: DataCollection;

    private buttonConfig: any;
    private contextMenuPosition: any = { x: '0px', y: '0px' };
    @ViewChild(MatMenuTrigger) contextMenu: MatMenuTrigger;

    constructor(
        private route: ActivatedRoute,
        public dialog: MatDialog,
        private exportService: DocumentPublishingService,
        private deleteOnConfirmDialogService: DeleteOnConfirmDialogService,
        private readonly titleService: Title

    ) { }

    ngOnInit(): void {
        this.settings = this.route.snapshot.data['documentPublishing'];
        this.site = this.route.parent.snapshot.data['currentSite'];

        this.buttonConfig = {
            right: [new ButtonConfig('New', 'new_settings')]
            //,item_actions: [new ButtonConfig('Edit', 'info', 'info')]
        };

        if (this.site.siteIsAdministerable) {
            this.buttonConfig['trash'] = new TrashConfig(true)
        }

        this.titleService.setTitle(this.route.snapshot.data['title']);
    }

    public get ListItems(): IListItem[] {
        return (this.settings);
    }

    public get ButtonConfig(): any {
        return (this.buttonConfig);
    }

    public doOnEdit(event: any, origin = 'drag') {
        let item;
        if (origin == 'context_menu') {
            item = this.contextMenu.menuData.item;
        } else {
            item = event.item;
        }
        this.createOrUpdateSettings(item);
    }

    public doOnButtonClick(event: any) {
        if (event.button == 'new_settings') {
            this.createOrUpdateSettings();
        }
    }

    private createOrUpdateSettings(data: DocumentPublishingSettings = null) {
        const dialogRef = this.dialog.open(DocumentPublishingSettingsEditorDialog, {
            width: '60%',
            data: data
        });

        dialogRef.afterClosed().subscribe(submittedSettings => {
            if (submittedSettings) {
                let exportSettings = new DocumentPublishingSettings();
                exportSettings.id = submittedSettings.id;
                exportSettings.name = submittedSettings.name;
                exportSettings.outputType = submittedSettings.outputType;
                exportSettings.xslt = submittedSettings.xslt;
                exportSettings.viewableBy = submittedSettings.viewableBy;
                exportSettings.viewableByMaxGroup = submittedSettings.viewableBy == 'GROUP' ? submittedSettings.viewableByMaxGroup : null;
                exportSettings.siteId = this.site.id;

                this.exportService.saveDocumentPublishingSettings(exportSettings).then(x => {
                    let clonedSettings = Object.assign([], this.settings);
                    if (submittedSettings.id > 0) {
                        clonedSettings = clonedSettings.filter(x => { return x.id != submittedSettings.id });
                    } 
                    clonedSettings.push(x);
                    this.settings = clonedSettings;
                });
            }
        });
    }

    public doOnItemAction(event: any) { }


    public doOnItemDelete(event: any, origin: string = 'drag') {
        let items = [];
        if (origin == 'context_menu') {
            items.push(this.contextMenu.menuData.item);
        } else {
            items = event.items;
        }
        this.deleteOnConfirmDialogService.deleteOnConfirm(this.dialog, "Document Publishing Settings", () => {
            for (let i of items) {
                this.exportService.deleteDocumentPublishingSettings(i.id).then(x => {
                    this.settings = this.settings.filter(x => x.getId() != i.id);
                });
            }
        });
    }

    public doOnItemContextMenu(event: any) {
        // Set the context menu position.
        this.contextMenuPosition.x = event.originalEvent.clientX + 'px';
        this.contextMenuPosition.y = event.originalEvent.clientY + 'px';

        // Set menu data.
        this.contextMenu.menuData = { 'item': event.item };
        this.contextMenu.openMenu();
    }

    public get ContextMenuPositionX(): string {
        return (this.contextMenuPosition.x);
    }
    public get ContextMenuPositionY(): string {
        return (this.contextMenuPosition.y);
    }

}
