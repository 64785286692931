import {
    Component,
    OnInit,
    Renderer2,
    Output,
    EventEmitter,
    Type as AngularCoreType
} from '@angular/core';
import {
    NG_VALUE_ACCESSOR,
    UntypedFormControl
} from '@angular/forms';

import { FormFieldMode } from '../form-field-mode.enum';
import {
    ControlType,
    FormFieldBaseComponent
} from '../form-field-base/form-field-base.component';
import { FormField, DisplayFormatEnum } from '../../../models/form-builder/form-field.model';
import {
    FormInstanceElement,
    FormInstanceElementValueTypeEnum
} from '../../../models/form-builder/form-instance-element.model';
import { FormFieldPropertyEnum } from '../../../models/form-builder/form-field-property-enum.model';
import { IGridRow } from '../../../interfaces/grid-row.interface';
import { FormFieldProcessingPhaseEnum } from '../../../enums/form-field-processing-phase.enum';
import { FormFieldOnInitPropertyEnum } from '../../../models/form-builder/form-field-on-init-output-property.enum';
import { ICheckboxValueChanged, CheckboxData } from '../../../models/checkboxes/checkbox-data.model';

// Define an internally used interface.
/*
interface ICheckboxValueChanged {
    checkboxValueChanged(index: number, checkboxData: CheckboxData): void;
}

// Define an internally used class.
class CheckboxData {
    // Properties.
    public id: number = 0;
    public title: string;
    public value: boolean = false;

    private index: number = -1;
    private checkboxValueChanged: ICheckboxValueChanged;
    private formControl: FormControl;

    // Constructor.
    public constructor(indexParam: number,
        checkboxValueChangedParam: ICheckboxValueChanged) {
        this.index = indexParam;
        this.checkboxValueChanged = checkboxValueChangedParam;

        return;
    }

    // Getter methods.
    public get FormControl(): FormControl {
        return (this.formControl);
    }

    // Methods.
    public setupFormControl(): void {
        // Setup my form control.
        let hshControlProperties = {
            value: this.value,
            disabled: false
        }

        this.formControl = new FormControl(hshControlProperties);

        // Listen for/subscribe to value changes.
        this.formControl.valueChanges
            .subscribe(val => {
                //this.FormInstanceElement.booleanValue = val;
                this.value = val;

                //this.notifyValueChanged();
                this.checkboxValueChanged.checkboxValueChanged(this.index, this);
            });

        return;
    }
}
*/

// Note:  please note the 'providers' definition below, as it is needed.
//        Without it, you will get the following exception:
//
//             No value accessor for form control with unspecified name
//
// The above exception gets thrown when a component, in this case our
// base class, implements interface 'ControlValueAccessor' and does not
// provide the 'providers' definition below.  Implementing the
// 'ControlValueAccessor' interface allows a form field component to
// support [(ngMode)], so users of the component can use [(ngModel)].
@Component({
    selector: 'app-multi-checkbox-form-field',
    templateUrl: './multi-checkbox-form-field.component.html',
    styleUrls: ['./multi-checkbox-form-field.component.scss', '../form-fields.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: MultiCheckboxFormFieldComponent,
            multi: true
        }
    ],
    standalone: false
})
export class MultiCheckboxFormFieldComponent extends FormFieldBaseComponent
    implements OnInit, ICheckboxValueChanged {
    // Properties.
    @Output() onInit = new EventEmitter();

    private readonly formFieldProperties: string[] =
        [
            FormFieldPropertyEnum.NAME,
            FormFieldPropertyEnum.FIELD_GROUP,
            FormFieldPropertyEnum.DISPLAY_NAME,
            FormFieldPropertyEnum.HELP_TEXT,
            FormFieldPropertyEnum.TOOL_TIP,
            FormFieldPropertyEnum.SELECT_OPTIONS,
            FormFieldPropertyEnum.DISPLAY_FORMAT,
            FormFieldPropertyEnum.INSTRUCTIONS_TEXT,
            FormFieldPropertyEnum.GRID_COLUMN_WIDTH
        ];

    private readonly displayFormats: string[] =
        [
            DisplayFormatEnum.HORIZONTAL,
            DisplayFormatEnum.VERTICAL
        ];

    private derivedCheckboxData: CheckboxData[] = [];
    private iDerivedCheckboxValueCount: number = 0;

    // Constructor.
    public constructor(private renderer: Renderer2) {
        super();

        return;
    }

    // Lifecycle methods.
    public ngOnInit(): void {
        // Output my properties.
        let hshProperties = this.getProperties();

        this.onInit.emit(hshProperties);

        // In case we have selected options, try to make sense of our configuration.
        if (this.Mode === 'design') {
            this.rebuildCheckboxData();
        }

        // If my display form is not set, set it to a default.
        if ((this.FormField.displayFormat === undefined) ||
            (this.FormField.displayFormat === null)) {
            this.FormField.displayFormat = DisplayFormatEnum.VERTICAL;
        }

        return;
    }

    // Implement base class abstract methods.
    public getProperties(): any {
        let hshProperties = {
            component: this,
            formField: this.FormField,
            properties: this.formFieldProperties,
            displayFormatValues: this.displayFormats,

            propertyUpdateRequired: true
        };

        // Need to ask for more display height.
        hshProperties[FormFieldOnInitPropertyEnum.REQUIRED_PREVIEW_INSTANCE_MODE_HEIGHT] = 100;
        hshProperties[FormFieldOnInitPropertyEnum.REQUIRED_PREVIEW_INSTANCE_MODE_HEIGHT_UNIT] = 'px';

        return (hshProperties);
    }

    public getFormFieldClass(): AngularCoreType<any> {
        return (MultiCheckboxFormFieldComponent);
    }

    public get canHaveFieldConditionalLogic(): boolean {
        return false;
    }

    // Implement interface ICheckboxValueChanged.
    public checkboxValueChanged(index: number, checkboxData: CheckboxData): void {
        // Update the corresponding
        // child form instance element.
        if (!this.FormInstanceElement.childFormInstanceElements ||
            (this.FormInstanceElement.childFormInstanceElements.length <= index)) {
            let errorMsg: string =
                `MultiCheckboxFormFieldComponent.checkboxValueChanged():  ` +
                `encountered an unexpected index value of {index} ` +
                `for select options '${this.FormField.selectOptions}'.`;
            throw (errorMsg);
        }

        let childFormInstanceElement: FormInstanceElement =
            this.FormInstanceElement.childFormInstanceElements[index];
        childFormInstanceElement.BooleanValue = checkboxData.value;

        // Did a user enter this value?
        if (!this.FormInstanceElement.transientInSetupFlag) {
            // A user entered this value.
            //this.FormInstanceElement.transientValueSetFlag = true;
            this.FormInstanceElement.UserUpdatedData = true;

            this.userEnteredValue();
        }

        this.notifyValueChanged();

        return;
    }

    // Handle some form field event methods.
    public propertyUpdated(formField: FormField, propertyName: string): void {
        if ((propertyName == FormFieldPropertyEnum.SELECT_OPTIONS) ||
            (propertyName == FormFieldPropertyEnum.ALL)) {
            // Set a 'reminder' to rebuild my checkbox titles.
            //
            // Note:  do not want to do this now as it triggers
            //        an endless loop of Angular callbacks.
            setTimeout(() => {
                this.rebuildCheckboxData();
            }, 0);
        }
        
        return;
    }

    // Methods called from my .html file.
    public get CheckboxData(): CheckboxData[] {
        return (this.derivedCheckboxData);
    }

    public get CheckboxValueCount(): number {
        return (this.iDerivedCheckboxValueCount);
    }

    public getCheckboxFormControl(indexParam: number): UntypedFormControl {
        let checkboxFormControl: UntypedFormControl = null;

        if (indexParam < this.iDerivedCheckboxValueCount) {
            checkboxFormControl = this.derivedCheckboxData[indexParam].FormControl;
        }

        return (checkboxFormControl);
    }

    public formFieldUpdated(): void {
        // 03-14-2024 note:  added this method so it can be called by the field conditional
        //                   logic to indicate that a component's form field has been updated.

        // Make sure the form control's disabled state agrees with the form field's 'isReadOnly' attribute.
        //this.toggleFormControlDisabledBasedOnReadOnlyAttribute();
        if (this.derivedCheckboxData != null) {
            for (let index: number = 0; index < this.derivedCheckboxData.length; index++) {
                let formControl: UntypedFormControl = this.getCheckboxFormControl(index);
                FormFieldBaseComponent.staticToggleFormControlDisabledBasedOnReadOnlyAttribute(formControl, this.formField);
            }
        }
        this.writeValueTriggered();
    }

    // Handle getting this field's form instance element.
    protected formInstanceElementReceived(): void {
        if ((this.Mode === 'preview') || (this.Mode === 'instance')) {
            if (this.ControlType === ControlType.REACTIVE_FORMS) {
                // If I have no value assigned but
                // have a default value, apply it now.

                // Note:  this form field does not
                //        yet handle default values.

                // Set my value type.
                this.FormInstanceElement.ValueType = FormInstanceElementValueTypeEnum.TypeMultiBoolean;

                // Setup my Reactive Forms data structure.
                this.rebuildCheckboxData();

                if (this.derivedCheckboxData &&
                    (this.derivedCheckboxData.length > 0)) {
                    for (let iCheckbox: number = 0; iCheckbox < this.derivedCheckboxData.length; iCheckbox++) {
                        let checkboxData: CheckboxData = this.derivedCheckboxData[iCheckbox];

                        checkboxData.setupFormControl();
                    }
                }                
            }
        }

        return;
    }

    //TEAMS-561: KLW - Implement the first instance of writeValueTrigger which will eventually replace formInstanceElementReceived
    protected writeValueTriggered(): void {
        if ((this.Mode === 'preview') || (this.Mode === 'instance')) {
            if (this.ControlType === ControlType.REACTIVE_FORMS) {
                // If I have no value assigned but
                // have a default value, apply it now.

                // Note:  this form field does not
                //        yet handle default values.

                // Set my value type.
                this.FormInstanceElement.ValueType = FormInstanceElementValueTypeEnum.TypeMultiBoolean;

                // Setup my Reactive Forms data structure.
                this.rebuildCheckboxData();

                if (this.derivedCheckboxData &&
                    (this.derivedCheckboxData.length > 0)) {
                    for (let iCheckbox: number = 0; iCheckbox < this.derivedCheckboxData.length; iCheckbox++) {
                        let checkboxData: CheckboxData = this.derivedCheckboxData[iCheckbox];

                        checkboxData.setupFormControl(this.FormField.readOnly);
                    }
                }                
            }
        }

        return;
    }

    public pseudoStatic_getDisplayValue(formFieldParam: FormField,
        formInstanceElementParam: FormInstanceElement,
        gridRow: IGridRow,
        processingPhase: FormFieldProcessingPhaseEnum): string {
        let value: string = '';

        let selectOptions: string[] = this.SelectOptions;

        if (formInstanceElementParam.childFormInstanceElements &&
            (formInstanceElementParam.childFormInstanceElements.length > 0)) {
            let iNumValuesSelected: number = 0;

            for (let iChild: number = 0; iChild < formInstanceElementParam.childFormInstanceElements.length; iChild++) {
                let child: FormInstanceElement = formInstanceElementParam.childFormInstanceElements[iChild];

                if (!child.isDeleted) {
                    if ((iChild < selectOptions.length) && (child.booleanValue == true)) {
                        if (iNumValuesSelected > 0) {
                            value += ',';
                        }

                        value += selectOptions[iChild];
                        iNumValuesSelected++;
                    }
                }
            }
        }

        return (value);
    }

    // Handle control events.
    public handleCheckboxModelChange(checkboxIndex: number): void {
        return;
    }

    // Implement private helper methods.
    private rebuildCheckboxData(): void {
        this.derivedCheckboxData = null;
        this.iDerivedCheckboxValueCount = 0;

        let arrCheckboxTitles: string[] = this.SelectOptions;

        if (arrCheckboxTitles && (arrCheckboxTitles.length > 0)) {
            this.derivedCheckboxData = [];
            this.iDerivedCheckboxValueCount = 0;

            // We have some additional work to
            // do in preview and instance modes.
            let bPreviewOrInstanceMode = ((this.Mode === 'preview') || (this.Mode === 'instance'));

            if (bPreviewOrInstanceMode) {
                if (!this.FormInstanceElement.childFormInstanceElements)
                    this.FormInstanceElement.childFormInstanceElements = [];
            }

            for (let iCheckbox: number = 0; iCheckbox < arrCheckboxTitles.length; iCheckbox++) {
                let checkboxTitle = arrCheckboxTitles[iCheckbox];

                let checkboxData: CheckboxData = new CheckboxData(iCheckbox, this);
                checkboxData.title = checkboxTitle;
                checkboxData.value = false;

                // If the last value is a blank, do not add it.
                let bIsLastValue: boolean = (iCheckbox === arrCheckboxTitles.length - 1);

                if (bIsLastValue && ((!checkboxTitle) || (checkboxTitle.trim() === ''))) 
                    continue;

                // Save the checkbox configuration.
                this.derivedCheckboxData.push(checkboxData);
                this.iDerivedCheckboxValueCount++;

                // We have some additional work to do in preview and instance modes.
                if (bPreviewOrInstanceMode) {
                    let childFormInstanceElement: FormInstanceElement = null;

                    if (this.FormInstanceElement.childFormInstanceElements.length > iCheckbox) {
                        childFormInstanceElement = this.FormInstanceElement.childFormInstanceElements[iCheckbox];

                        checkboxData.id = childFormInstanceElement.id;
                        if (childFormInstanceElement.booleanValue === true) {
                            checkboxData.value = true;
                        } else {
                            checkboxData.value = false;
                        }
                    } else {
                        childFormInstanceElement = new FormInstanceElement();
                        childFormInstanceElement.ValueType = FormInstanceElementValueTypeEnum.TypeBoolean;
                        childFormInstanceElement.BooleanValue = false;

                        this.FormInstanceElement.childFormInstanceElements.push(childFormInstanceElement);
                    }
                } // if-else
            } // for
        } // if

        return;
    }
}
