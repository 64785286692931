import { CommonModule } from '@angular/common'; // contains ngIf and the like
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// TODO -- Tried putting this in its own module but it didn't work...don't know why
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
//import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
//import { MatPaginatorModule } from '@angular/material/paginator';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { DndModule } from 'ngx-drag-drop';

import { MatBottomSheetModule } from '@angular/material/bottom-sheet'; //TEAMS-192: KLW - Reference to Material Bottom Sheet
import { BaseViewComponent } from '../shared/components/list-view/base-view/base-view.component';
import { GridViewComponent } from '../shared/components/list-view/grid-view/grid-view.component';
import { ListViewComponent } from '../shared/components/list-view/list-view.component';
import { TileViewComponent } from '../shared/components/list-view/tile-view/tile-view.component';
import { SiteDetailsComponent } from '../shared/components/site-details/site-details.component';
import { SiteEditorComponent } from '../shared/components/site-editor/site-editor.component';
import { ToggleFavoriteComponent } from '../shared/components/toggle-favorite/toggle-favorite.component';
import { IconDirective } from '../shared/directives/icon.directive'; //TEAMS-424: KLW - Add reference to IconDirective 
import { NgDragDropService } from '../shared/external-libraries/ng-drag-drop/services/ng-drag-drop.service';
import { Draggable } from '../shared/external-libraries/ng-drag-drop/directives/draggable.directive';
import { Droppable } from '../shared/external-libraries/ng-drag-drop/directives/droppable.directive';
import { NgDragDropModule } from '../shared/external-libraries/ng-drag-drop/ng-drag-drop.module';
import { FieldDefinitionService } from '../shared/services/field-definition.service';
 //import { DragDropFilesDirective } from '../shared/directives/drag-and-drop-files.directive';
//import { SafePipe } from '../shared/pipes/safe.pipe'; //TEAMS-424: KLW - Add reference to SafePipe

@NgModule({
    imports: [CommonModule,
        FormsModule,
        ReactiveFormsModule,

        MatMenuModule,
        MatCardModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule,
        MatToolbarModule,
        MatTooltipModule,
        MatExpansionModule,
        //BrowserAnimationsModule,
        //MatTooltipModule,
        //MatProgressBarModule,
        //MatPaginatorModule,
        MatInputModule,
        MatFormFieldModule,
        MatButtonModule,
        MatSelectModule,
        MatDialogModule,
        MatRadioModule,
        MatCheckboxModule,
        MatSlideToggleModule,
        MatButtonToggleModule,
        MatProgressSpinnerModule,
        MatProgressBarModule, //VNEXT-101: KLW - Adding module for the material progress bar
        MatTableModule,
        MatSortModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatTabsModule,
        MatAutocompleteModule,

        DragDropModule, 
        DndModule,
        //DragDropFilesDirective
        //SafePipe

        // Draggable,
        // Droppable,
        NgDragDropModule.forRoot()
    ],
    exports: [
        // Exported modules.
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatMenuModule,
        MatCardModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule,
        MatToolbarModule,
        MatExpansionModule,
        //BrowserAnimationsModule,
        MatInputModule,
        MatFormFieldModule,
        MatButtonModule,
        MatSelectModule,
        MatDialogModule,
        MatRadioModule,
        MatCheckboxModule,
        MatSlideToggleModule,
        MatButtonToggleModule,
        MatProgressSpinnerModule,
        MatProgressBarModule, //VNEXT-101: KLW - Adding module for material progress bar 
        MatTableModule,
        MatSortModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatTabsModule,
        MatAutocompleteModule,
        
        DragDropModule, 
        DndModule,

        // Draggable,
        // Droppable,

        NgDragDropModule,

        // Exported component classes.
        ListViewComponent,
        BaseViewComponent,
        TileViewComponent,
        GridViewComponent,
        SiteDetailsComponent,
        SiteEditorComponent,
        ToggleFavoriteComponent,
        IconDirective, //TEAMS-424: KLW - Add reference to IconDirective
        //DragDropFilesDirective
        MatBottomSheetModule //TEAMS-192: KLW - Reference to Material Bottom Sheet
    ],
    declarations: [
         
        ListViewComponent,
        BaseViewComponent,
        TileViewComponent,
        GridViewComponent,
        SiteDetailsComponent,
        SiteEditorComponent, 
        ToggleFavoriteComponent,
        IconDirective, //TEAMS-424: KLW - Add reference to IconDirective
        //DragDropFilesDirective
    ],
    providers: [ 
        //FieldDefinitionService, //Kevin - causing ?undefined? error
        FieldDefinitionService,
    ]
})
export class SharedModule { }
