import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { UntypedFormControl } from '@angular/forms'; // Used for Reactive Forms

import { ComponentMethodMetadata, ComponentMethodsMetadata, IComponentNameToMethodsMetadata } from '../../models/component-scripting/component-methods-metadata.model';
import { ComponentMethodsService, EnumMetadata, IEnumMetadataByName } from '../../models/component-scripting/component-methods.service';
import { Scriptable } from '../../models/component-scripting/scriptable-function.annotation';
import { TextSelectOptionValue } from '../../models/selection-fields/select-option-value.model';

class ParameterTypeToSetupMethodName {
    public constructor(public parameterType: string, public setupMethodName: string) {
    }
}

enum ParameterFormControlTypeEnum {
    TextInput = 'TextInput',
    NumericInput = 'NumericInput',
    Select = 'Select'
}

export class ParameterFormControlInfo {
    // Properties.
    // Parameter metadata.
    public selectOptionValues: TextSelectOptionValue[] = null;

    // Control data.
    public formControl: UntypedFormControl = null;
    public enteredValue: any = null;

    public hasDateTimeSuffixFormControl: UntypedFormControl = null;

    // Constructor.
    public constructor(public parameterName: string, public controlType: ParameterFormControlTypeEnum) {
    }
}

export class EditInputParametersDialogManager {
    public constructor(private dialog: MatDialog, private methodMetadata: ComponentMethodMetadata) {
    }

    public openDialog(): MatDialogRef<EditInputParametersDialog, any> {
        let dialogHeight: string = `${150 * this.methodMetadata.parameterNames.length + 100}px`;

        // Open an EditInputParametersComponent dialogue.
        let dialogConfig: MatDialogConfig = new MatDialogConfig();
        dialogConfig.hasBackdrop = true;
        dialogConfig.width = '400px';
        dialogConfig.height = dialogHeight; // '500px';
        dialogConfig.data = this.methodMetadata;

        const dialogRef: MatDialogRef<EditInputParametersDialog, any> = this.dialog.open(EditInputParametersDialog, dialogConfig);

        return dialogRef;
    }
}

@Component({
    selector: 'app-edit-input-parameters',
    templateUrl: './edit-input-parameters.dialog.html',
    styleUrls: ['./edit-input-parameters.dialog.scss'],
    standalone: false
})
export class EditInputParametersDialog implements OnInit {
    // Static data.
    private static parameterTypesToMethodNames: ParameterTypeToSetupMethodName[] =
        [
            new ParameterTypeToSetupMethodName('string', 'setupStringControl'),
            new ParameterTypeToSetupMethodName('number', 'setupNumericControl')
        ];

    // Properties.
    public title: string = null;
    public readonly parametersControlInfo: ParameterFormControlInfo[] = [];

    // Constructor.
    public constructor(@Inject(MAT_DIALOG_DATA) public methodMetadata: ComponentMethodMetadata,
        private dialogRef: MatDialogRef<EditInputParametersDialog>) {
        this.setupParameterControls();
    }

    // Life cycle methods.
    public ngOnInit(): void {
        //this.setupParameterControls();
    }

    // Method called by my HTML code.
    /*
    public get Title(): string {
        let title: string = 'Edit Input Parameter';

        if ((this.methodMetadata != null) && (this.methodMetadata.argumentTypeNames != null) && (this.methodMetadata.argumentTypeNames.length > 1))
            title += 's';

        return title;
    }

    public get ParametersControlInfo(): ParameterFormControlInfo[] {
        return this.parametersControlInfo;
    }

    public get TextInputControlType(): string {
        return ParameterFormControlTypeEnum.TextInput;
    }
    public get NumericInputControlType(): string {
        return ParameterFormControlTypeEnum.NumericInput;
    }
    public get SelectControlType(): string {
        return ParameterFormControlTypeEnum.Select;
    }

    public getParameterFormControlFor(parameterControlInfo: ParameterFormControlInfo): FormControl {
        return parameterControlInfo.formControl;
    }
    public getParameterNameFor(parameterControlInfo: ParameterFormControlInfo): string {
        return parameterControlInfo.parameterName;
    }
    public getFieldLabelFor(parameterControlInfo: ParameterFormControlInfo): string {
        return this.getParameterNameFor(parameterControlInfo);
    }
    public getSelectOptionsFor(parameterControlInfo: ParameterFormControlInfo): TextSelectOptionValue[] {
        return parameterControlInfo.selectOptionValues;
    }

    public getHasAddedDateTimeSuffixFormControlFor(parameterControlInfo: ParameterFormControlInfo): FormControl {
        return parameterControlInfo.hasDateTimeSuffixFormControl;
    }
    */

    // Control events.
    public returnParameterValues(): void {
        this.prepareResultValues();
        this.dialogRef.close(this.parametersControlInfo);
    }

    // Helper methods.
    private setupParameterControls(): void {
        // Set my title.
        this.title = 'Edit Input Parameter';
        if ((this.methodMetadata != null) && (this.methodMetadata.argumentTypeNames != null) && (this.methodMetadata.argumentTypeNames.length > 1))
            this.title += 's';

        // Setup my form controls.
        if ((this.methodMetadata != null) && (this.methodMetadata.argumentTypeNames != null)) {
            for (let index: number = 0; index < this.methodMetadata.argumentTypeNames.length; index++) {
                let parameterType: string = this.methodMetadata.argumentTypeNames[index].toLowerCase();
                let parameterTypeToSetup: ParameterTypeToSetupMethodName = EditInputParametersDialog.parameterTypesToMethodNames.find(p => p.parameterType == parameterType);
                if (parameterTypeToSetup == null)
                    throw `EditInputParametersComponent.setupParameterControls():  cannot find a setup method for parameter type '${parameterType}'`;

                let parameterName: string = (this.methodMetadata.parameterNames != null) && (this.methodMetadata.parameterNames.length > index) ? this.methodMetadata.parameterNames[index] : parameterType;
                let additionalTypeInfo = (this.methodMetadata.parametersTypeInfo != null) && (this.methodMetadata.parametersTypeInfo.length > index) ? this.methodMetadata.parametersTypeInfo[index] : null;
                let controlInfo: ParameterFormControlInfo = this[parameterTypeToSetup.setupMethodName](parameterName, additionalTypeInfo);
                this.parametersControlInfo.push(controlInfo);
            }
        }
    }

    // Note:  the following two methods are called indirectly, by method name passed as a variable, so they look to be unused.
    //
    //        Please do not comment out or remove the following two methods.
    private setupStringControl(parameterName: string, additionalTypeInfo: string = null): ParameterFormControlInfo {
        let parameterInfo: ParameterFormControlInfo = null;

        let controlProperties = { 
            value: '',
            disabled: false
        };

        if (additionalTypeInfo != null) {
            // Lookup the enumeration.
            let enumMetadata: EnumMetadata = ComponentMethodsService.getEnumMetadata(additionalTypeInfo);

            if ((enumMetadata != null) && (enumMetadata.values != null)) {
                parameterInfo = new ParameterFormControlInfo(parameterName, ParameterFormControlTypeEnum.Select);
                parameterInfo.selectOptionValues = [];

                for (let index: number = 0; index < enumMetadata.values.length; index++) {
                    let value: string = enumMetadata.values[index];
                    let displayName: string = ((enumMetadata.prettyNames != null) && (enumMetadata.prettyNames.length > index) ? enumMetadata.prettyNames[index] : enumMetadata.values[index]);
                    let selectOption: TextSelectOptionValue = new TextSelectOptionValue(value, displayName);

                    parameterInfo.selectOptionValues.push(selectOption);
                }

                controlProperties.value = enumMetadata.values[0];
                parameterInfo.formControl = new UntypedFormControl(controlProperties);
            } else {
                parameterInfo = this.setupTextControlWithDateTimeSuffixCheckbox(parameterName, controlProperties);
            }
        } else {
            parameterInfo = this.setupTextControlWithDateTimeSuffixCheckbox(parameterName, controlProperties);
        }            

        return parameterInfo;
    }
    private setupNumericControl(parameterName: string, additionalTypeInfo: string = null): ParameterFormControlInfo {
        let parameterInfo = new ParameterFormControlInfo(parameterName, ParameterFormControlTypeEnum.NumericInput);
        return parameterInfo;
    }

    private setupTextControlWithDateTimeSuffixCheckbox(parameterName: string, controlProperties: any): ParameterFormControlInfo {
        let parameterInfo: ParameterFormControlInfo = new ParameterFormControlInfo(parameterName, ParameterFormControlTypeEnum.TextInput);
        parameterInfo.formControl = new UntypedFormControl(controlProperties);

        parameterInfo.hasDateTimeSuffixFormControl = new UntypedFormControl({ value: false, disabled: false});

        return parameterInfo;
    }
    private prepareResultValues(): void {
        for (let index: number = 0; index < this.parametersControlInfo.length; index++) {
            let parameterControlInfo: ParameterFormControlInfo = this.parametersControlInfo[index];
            parameterControlInfo.enteredValue = parameterControlInfo.formControl.value;
        }
    }
}
