import { HttpClient } from '@angular/common/http';
import { Directive, OnInit, OnDestroy } from '@angular/core';
import { DataBindingDirective, GridComponent } from '@progress/kendo-angular-grid';
import { Subscription } from 'rxjs';
import { CrossSiteUserFavoriteFormsService } from '../services/cross-site-user-favorite-forms.service';

@Directive({
    selector: '[appCrossSiteUserFavoriteFormsRemoteBinding]',
    exportAs: 'remoteBindingDirectiveRef',
    standalone: false
})
export class CrossSiteUserFavoriteFormsRemoteBindingDirective extends DataBindingDirective implements OnInit, OnDestroy {

    private serviceSubscription: Subscription;
    private showAllForAdmin: boolean;

    constructor(private http: HttpClient, grid: GridComponent, private service: CrossSiteUserFavoriteFormsService) {
        super(grid);
    }

    public ngOnInit() {
        this.serviceSubscription = this.service.subscribe((data) => {
            // this code called whenever the service has new data to present, triggered by calls to the rebind() callback function
            // (which DataBindingDirective takes care of calling when grid state changes)...
            this.grid.loading = false;
            this.grid.data = data.resultsAsPlainObjects;
            this.notifyDataChange();
        });

        super.ngOnInit();
        this.rebind();
    }



    // A callback which the DataBindingDirective super class takes care of calling automatically when the grid's state changes
    public rebind(): void {
        this.grid.loading = true;
        this.service.read(this.state);
    }

    public ngOnDestroy(): void {
        if (this.serviceSubscription) {
            this.serviceSubscription.unsubscribe();
        }

        super.ngOnDestroy();
    }
}
