import {
    HttpClient
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IAutoCompleteItemData } from '../interfaces/iautocomplete-item-data.interface';
import { IAutoCompleteServiceCall } from '../interfaces/iautocomplete-service-call.interface';
import { Logging } from '../logging';
import { FieldIdToSelectedValues, FlexibleSelectionFieldInstructionsRequest } from '../models/flexible-selection-fields/flexible-selection-field-instructions-request.model';
import { FormInstance } from '../models/site-content/form-instance.model';

@Injectable()
export class AutocompleteService // extends CollectApiServiceBase<Autocomplete> {
{
    constructor(private http: HttpClient) {
    }

    public isSearching = new BehaviorSubject(false);
    public totalCount: number = 0;


    private lastCallToSearch: number;
    private lastResultOfSearch: any;
    public search(
        query: string,
        max: number = 10,
        selectedItems: string[],
        fie_id: number,
        flexSelectRequest: FlexibleSelectionFieldInstructionsRequest
    ): Promise<IAutoCompleteItemData[]> {
        // prevent double hits to the server (two calls were coming into this method milliseconds apart - I don't know why)
        //let millisecondsSinceLastCall = this.lastCallToSearch ? (Date.now() - this.lastCallToSearch) : 101;
        //this.lastCallToSearch = Date.now();
        //if (millisecondsSinceLastCall < 101) {
        //    console.log(`return this.lastResultOfSearch (${this.lastResultOfSearch})`);
        //    return this.lastResultOfSearch;
        //} else if ((!query) || (query.length == 0)) {
        //    return EMPTY.toPromise();
        //}

        this.isSearching.next(true);

        var url = `${environment.apiUrl}/api/autocomplete/search/${fie_id}/${query}/${max}`;

        let request = null;
        if (flexSelectRequest) {
            request = this.http.post<IAutoCompleteServiceCall>(url, flexSelectRequest);
        } else {
            request = this.http.get<IAutoCompleteServiceCall>(url);
        }

        return request
            .toPromise()
            .then(response => {
                var data: IAutoCompleteItemData[] = [];

                response.startsWithResults.forEach(x => {

                    var isSelected: boolean = selectedItems.filter(y => y == x).length > 0;

                    data.push({ item: x, selected: isSelected } as IAutoCompleteItemData);
                });

                response.containsResults.forEach(x => {

                    var isSelected: boolean = selectedItems.filter(y => y == x).length > 0;

                    data.push({ item: x, selected: isSelected } as IAutoCompleteItemData);
                });

                //VNEXT-558: KLW - Implement sorting options by ascending order
                data.sort();

                this.isSearching.next(false);
                this.totalCount = response.totalCount;
                this.lastResultOfSearch = data;

                return data;
            })
            .catch(this.handleError);
    }

    public startsWith(query: string, max: number = 10, selectedItems: string[], fie_id: number): Promise<IAutoCompleteItemData[]> {
        if ((!query) || (query.length == 0)) { return EMPTY.toPromise(); }

        this.isSearching.next(true);

        var url = `${environment.apiUrl}/api/autocomplete/startswith/${fie_id}/${query}/${max}`;

        return this.http
            .get<IAutoCompleteServiceCall>(url)
            .toPromise()
            .then(response => {
                var data: IAutoCompleteItemData[] = [];

                response.startsWithResults.forEach(x => {

                    var isSelected: boolean = selectedItems.filter(y => y == x).length > 0;

                    data.push({ item: x, selected: isSelected } as IAutoCompleteItemData);
                });

                //VNEXT-558: KLW - Implement sorting options by ascending order
                data.sort();

                this.isSearching.next(false);
                this.totalCount = response.totalCount;

                return data;
            })
            .catch(this.handleError);
    }

    contains(query: string, max: number = 10, selectedItems: string[], fie_id: number): Promise<IAutoCompleteItemData[]> {
        if ((!query) || (query.length == 0)) { return EMPTY.toPromise(); }

        this.isSearching.next(true);

        var url = `${environment.apiUrl}/api/autocomplete/contains/${fie_id}/${query}/${max}`;

        return this.http
            .get<IAutoCompleteServiceCall>(url)
            .toPromise()
            .then(response => {
                var data: IAutoCompleteItemData[] = [];

                response.containsResults.forEach(x => {

                    var isSelected: boolean = selectedItems.filter(y => y == x).length > 0;

                    data.push({ item: x, selected: isSelected } as IAutoCompleteItemData);
                });

                //VNEXT-558: KLW - Implement sorting options by ascending order
                data.sort();

                this.isSearching.next(false);
                this.totalCount = response.totalCount;

                return data;
            })
            .catch(this.handleError);
    }

    handleError(error: Response | any): Promise<any> {
        Logging.log(error);
        return Promise.reject(error.message || error);
    }
}
