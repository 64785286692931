import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { TooltipPosition } from '@angular/material/tooltip';

import { MaxPickerService } from '../../services/max-picker.service';
import { PickerItem } from '../../models/picker-item.model';

@Component({
    selector: 'app-max-picker',
    templateUrl: './max-picker.component.html',
    styleUrls: ['./max-picker.component.scss'],
    standalone: false
})
export class MaxPickerComponent implements OnInit {
    // Properties.
    private addMemberFormGroup: UntypedFormGroup = null;

    private showLoadingIndicator: boolean = false;
    private pickerItems: Observable<PickerItem[]> = null;
    private pickerLoadingTmeoutId: any;

    // Inputs.
    @Input() userGroupPickerLabel: string = 'Add Member';
    @Input() pickerDisabled: boolean = false;
    @Input() maxMemberStyle: string = 'width: 75%;';
    @Input() addButtonName: string = 'Add';
    @Input() addButtonFlyoverText: string = 'Click to add a user or group';
    @Input() addButtonAdditionalStyle: string = '';
    @Input() addButtonCanEnable: boolean = true;
    @Input() scope: string = 'usersAndGroups';
    @Input() secondButtonName: string = null;
    @Input() secondButtonCanEnable: boolean = true;
    @Input() secondButtonIgnoreMemberInput: boolean = false;
    @Input() secondButtonFlyoverText: string = null;

    // Outputs.
    @Output() addButtonClicked: EventEmitter<any> = new EventEmitter();
    @Output() secondButtonClicked: EventEmitter<any> = new EventEmitter();

    // Constructor.
    public constructor(private _formBuilder: UntypedFormBuilder,
        private maxPickerService: MaxPickerService) {
        return;
    }

    // Life cycle methods.
    public ngOnInit(): void {
        // Create initial/blank form definitions.
        this.createFormGroupAndInitFilteredUsers();

        // Initialize picker elements.
        this.initializeLoadingIndicator();

        return;
    }

    // Accessor methods called by my HTML code.
    public get AddMemberFormGroup(): UntypedFormGroup {
        return (this.addMemberFormGroup);
    }

    public get MaxMemberStyle(): string {
        return (this.maxMemberStyle);
    }

    public get AddButtonName(): string {
        return (this.addButtonName);
    }
    public get AddButtonAdditionalStyle(): string {
        return (this.addButtonAdditionalStyle);
    }
    public get AddButtonFlyoverText(): string {
        return this.addButtonFlyoverText;
    }

    public get UserGroupPickerLabel(): string {
        let label: string = this.userGroupPickerLabel;

        if (this.scope == 'usersOnly') {
            label = 'Add User';
        } else if (this.scope == 'groupsOnly') {
            label = 'Add Group'
        }
        return label;
    }

    public get ShowLoadingIndicator(): boolean {
        return (this.showLoadingIndicator);
    }

    public DisplayUserName(pi: PickerItem): string {
        return (pi != null ? pi.displayName : '');
    }

    public get PickerItems(): Observable<PickerItem[]> {
        return (this.pickerItems);
    }

    public get DisableAddButton(): boolean {
        let memberInput: UntypedFormControl = <UntypedFormControl>this.addMemberFormGroup.get('member');

        let bDisable: boolean = (
            (!this.addButtonCanEnable) ||
            (memberInput == null) ||
            (memberInput.value == null) ||
            (memberInput.value.toString().trim() == '') ||
            (!memberInput.value.key)); //VNEXT-806 make sure a member is selected before enabling Add button

        return bDisable;
    }
    public get DisableSecondButton(): boolean {
        let memberInput: UntypedFormControl = <UntypedFormControl>this.addMemberFormGroup.get('member');
        let bDisable: boolean = false;

        if (this.secondButtonIgnoreMemberInput)
            bDisable = (!this.secondButtonCanEnable);
        else
            bDisable = ((!this.secondButtonCanEnable) || (memberInput == null) || (memberInput.value == null) || (memberInput.value.toString().trim() == ''));

        return bDisable;
    }

    public get SecondButtonName(): string {
        return this.secondButtonName;
    }
    public get SecondButtonFlyoverText(): string {
        return this.secondButtonFlyoverText;
    }

    public get TooltipPosition(): TooltipPosition {
        let position: TooltipPosition = 'below';

        return (position);
    }

    public get PickerDisabled(): boolean {
        return this.pickerDisabled;
    }

    // Handle control events.
    public addMembership(): void {
        this.membershipOrMemberAdded(true);
    }

    public addMember(): void {
        if (this.secondButtonIgnoreMemberInput)
            this.secondButtonClicked.emit(null);
        else
            this.membershipOrMemberAdded(false);
    }

    // Private helper methods.
    private membershipOrMemberAdded(membershipAdded: boolean): void {
        // Get and output the added member.
        let member: any = this.addMemberFormGroup.value.member;

        // Add a flag that the caller can change.
        if ((member != null) && (typeof member == 'object') && (member.constructor.name == 'PickerItem'))
            member['clearMemberInput'] = false;

        // Output the event.
        if (membershipAdded)
            this.addButtonClicked.emit(member);
        else
            this.secondButtonClicked.emit(member);

        // Clear the member input field?
        if (member['clearMemberInput'] == true)
            this.clearMemberInput();
    }

    private clearMemberInput(): void {
        //return;

        let memberInput: UntypedFormControl = <UntypedFormControl>this.addMemberFormGroup.get('member');
        if (memberInput != null) {
            // 04-07-2022 note:  clearing the existing input fields was causing a
            //                   "You provided 'undefined' where a stream was expected" exception.
            //
            //                   The information on the following page gave me the idea to recreate
            //                   the form group instead:
            //
            //                   https://stackoverflow.com/questions/34742023/how-to-clear-form-after-submit-in-angular-2
            //memberInput.setValue('');
            this.createFormGroupAndInitFilteredUsers();
            /*
            try {
                //memberInput.setValue('');
                //memberInput.reset();
                this.createFormGroupAndInitFilteredUsers();
            } catch (err) {
                console.log(err);
            }
            */
        }
    }

    private initializeFilteredUsers(): void {
        this.pickerItems = this.addMemberFormGroup.controls['member'].valueChanges.pipe(
            debounceTime(300),                                          // swallow multiple valueChanges made within 300ms period and just keep the most recent one
            switchMap(value => this.pickerServiceSearch(value)//this.maxPickerService.search(value)
            ) // switch to a new observable, canceling the previous
        );
    }

    private pickerServiceSearch(value: any): Promise<PickerItem[]> {
        if ((value != null) && (value != '')) {
            let result: Promise<PickerItem[]> = this.maxPickerService.search(value, 100, this.scope);

            return (result);
        } else {
            let retValue = new Promise<PickerItem[]>((resolve, reject) => {
                resolve([]);
            });

            return retValue;
        }
    }

    private initializeLoadingIndicator(): void {
        this.maxPickerService.isSearching.subscribe((value) => {
            if (value) {
                if (!this.showLoadingIndicator) {
                    this.pickerLoadingTmeoutId = setTimeout(() => { this.showLoadingIndicator = true }, 450);
                }
            }
            else {
                clearTimeout(this.pickerLoadingTmeoutId);
                this.showLoadingIndicator = false;
            }
        });
    }

    private createFormGroupAndInitFilteredUsers(): void {
        this.addMemberFormGroup = this._formBuilder.group({
            member: { value: '', disabled: this.pickerDisabled }
        });

        this.initializeFilteredUsers();
    }
}
