import { IFieldDefinitionLogic } from '../../../interfaces/ifield-definition-logic.interface';
import { TextMetadataFieldDefLogic } from './text-metadata-field-def-logic';

export class FolderNameFieldDefinitionLogic extends TextMetadataFieldDefLogic implements IFieldDefinitionLogic {
    // Constructor.
    public constructor() {
        super('folderName');
    }

    public get canParticipateInFlexFieldBehavior(): boolean {
        return true;
    }
}
