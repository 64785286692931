import {
    Component,
    OnInit,
    Renderer2,
    Output,
    Input,
    EventEmitter,
    Type as AngularCoreType,
    ViewEncapsulation
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { ControlType, FormFieldBaseComponent } from '../form-field-base/form-field-base.component';
//import { FormFieldPropertyEnum } from '../../form-builder/properties/form-field-properties/form-field-properties.component';
import { FormFieldPropertyEnum } from '../../../models/form-builder/form-field-property-enum.model';
import { FormField } from '../../../models/form-builder/form-field.model';
import { FormInstanceElement } from '../../../models/form-builder/form-instance-element.model';
import { InlineContentService } from '../../../services/inline-content.service';

/*
    !!!!! IMPORTANT NOTE !!!!!:
    This component relies upon use of a property of FormField named UrlBuilderConfigJson BUT THERE IS CURRENTLY NO UI FOR ENTER THIS
    The expected format of this property is...
    {
        "urlTemplate":"https://services.max.gov/a11cs/adt/PA2024/textdata/FINAL/<Treasury Account Code>.html",
        "buttonName":"Render Report"
    }
    There is an existing property of formfield which could have been used but was not - "iframeSource". 1) The component no longer uses
    an iframe, and 2) other types of field may need to be configured with a url - such as the address lookup functionality Steve
    worked on.
*/

@Component({
    selector: 'app-inline-content-form-field',
    templateUrl: './inline-content-form-field.component.html',
    styleUrls: ['./inline-content-form-field.component.scss'],
    encapsulation: ViewEncapsulation.None, //enable CSS overrides
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: InlineContentFormFieldComponent,
            multi: true
        }
    ],
    standalone: false
})
export class InlineContentFormFieldComponent extends FormFieldBaseComponent implements OnInit {
    private url: string = null;
    private resource: string = null;
    private showLoading: boolean = false;

    @Input() buttonName: string = "Get Content";
    @Output() onInit = new EventEmitter();

    private readonly formFieldProperties: string[] =
        [
            FormFieldPropertyEnum.DISPLAY_NAME,
            FormFieldPropertyEnum.TOOL_TIP,
            FormFieldPropertyEnum.IFRAME_SRC
        ];

    private readonly formFieldNames: string[] =
        [
            FormFieldPropertyEnum.DISPLAY_NAME
        ]

    // Constructor.
    constructor(private renderer: Renderer2, private inlineContentService: InlineContentService)
    {
        super();

        return;
    }

    // Implement abstract methods.
    public getProperties(): any {
        let hshProperties = {
            component: this,
            formField: this.FormField,
            properties: this.formFieldProperties
        };

        return (hshProperties);
    }

    // Life cycle methods.
    ngOnInit(): void {
        //this.onInit.emit({ component: this, formField: this.formField, properties: this.formFieldNames });
        let hshProperties = this.getProperties();
        this.onInit.emit(hshProperties);

        return;
    }

    // Override the getDisplayValue() base class method.
    // Define a method that allows a component to return its display value.
    public pseudoStatic_getDisplayValue(formFieldParam: FormField, formInstanceElementParam: FormInstanceElement): string {
        if ((!formInstanceElementParam.UserUpdatedData) ||
            (!formInstanceElementParam.textValue)) {
            // Set a default value.
            formInstanceElementParam.TextValue = '';
        }

        // NOTE:  NEED TO REVISIT THIS.
        return (formInstanceElementParam.textValue);
    }

    // Override a method used to get my class.
    public getFormFieldClass(): AngularCoreType<any> {
        return (InlineContentFormFieldComponent);
    }

    public get ButtonName(): string {
        return this.buttonName;
    }

    public get Resource(): string {
        return this.resource;
    }

    public get Ready(): boolean {
        return 
    }

    public get ShowLoading() {
        return this.showLoading;
    }

    public handleOpenContentButtonClick(event: any) {
        this.loading();
        this.inlineContentService.getUrl(this.FormInstance, this.formField.id).then(url => {
            this.showLoading = false;
            window.open(url, '_blank').focus();
        });
    }

    public handleOpenContentButtonClickForGridRow_FOR_DEV_ONLY(event: any) {
        this.loading();
        this.inlineContentService.getUrlFromGridRow(this.FormInstance.id, this.formField.id).then(url => {
            this.showLoading = false;
            window.open(url, '_blank').focus();
        });
    }

    public handleGetContentButtonClick(event: any) {
        this.loading();
        this.inlineContentService.getContent(this.formInstance.id, this.formField.id).then(resource => {
            this.showLoading = false;
            this.resource = resource;
        });
    }


    private loading() {
        this.resource = null;
        this.showLoading = true;
    }
}
