import {Directive, Input, HostListener, ElementRef} from '@angular/core';

@Directive({
    selector: '[IntegerOnly]',
    standalone: false
})

export class IntegerOnlyDirective {

    constructor(private el: ElementRef) { }

    editingKeys = ['ArrowUp', 'ArrowRight', 'ArrowDown', 'ArrowLeft', 'Backspace', 'Delete', 'Home', 'Tab', 'End'];
    controlKeys = ['a','c','v','x','z']; //select all, copy, paste, cut, undo

    @HostListener('keydown', ['$event'])
    onKeyDown(e: KeyboardEvent) {
        if (this.editingKeys.includes(e.key)) {
            return; //allow editing keys
        }
        if (e.ctrlKey && this.controlKeys.includes(e.key)) {
            return; //allow Windows control keystrokes
        }
        if (e.metaKey && this.controlKeys.includes(e.key)) {
            return; //allow Mac control keystrokes
        }
        if (e.key === '-') {
            return; //allow negative numbers
        }
        if (e.key === ' ' || isNaN(Number(e.key))) {
            e.preventDefault(); //block space and non-numbers
        }
    }
    @HostListener('paste', ['$event'])
    onPaste(event: ClipboardEvent) {
        event.preventDefault();
        const pastedInput: string = event.clipboardData
            .getData('text/plain')
            .replace(/\D/g, ''); //digit-only string
        document.execCommand('insertText', false, pastedInput);
    }
}
