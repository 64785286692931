import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export class GeocodeDialogModel {
    //input
    public title: string;

    //output
    public somename: string;
    public enteredName: string;
    public lookupType: string;
    public gridCols;
    public streetColId: number;
    public cityColId: number;
    public stateColId: number;
    public zipColId: number;
    public latitudeColId: number;
    public longitudeColId: number;
    constructor(title: string, gridCols) {
        this.title = title;
        this.gridCols = gridCols;
    }
}

@Component({
    selector: 'app-geocode-dialog',
    templateUrl: './geocode-dialog.component.html',
    styleUrls: ['./geocode-dialog.component.scss'],
    standalone: false
})

export class GeocodeDialog implements OnInit {
    constructor(public dialogRef: MatDialogRef<GeocodeDialog>,
        @Inject(MAT_DIALOG_DATA) public data: GeocodeDialogModel) {
    }

    ngOnInit() { }

    public get Title(): string {
        return this.data.title;
    }

    public get GridCols(): string[] {
        return this.data.gridCols;
    }

    public get LookupType(): string {
        return this.data.lookupType;
    }
    public set LookupType(lookupTypeValue: string) {
        this.data.lookupType = lookupTypeValue;
    }

    public get StreetColId() {
        return this.data.streetColId;
    }
    public set StreetColId(streetColId) {
        this.data.streetColId = streetColId;
    }
    public get CityColId() {
        return this.data.cityColId;
    }
    public set CityColId(cityColId) {
        this.data.cityColId = cityColId;
    }
    public get StateColId() {
        return this.data.stateColId;
    }
    public set StateColId(stateColId) {
        this.data.stateColId = stateColId;
    }
    public get ZipColId() {
        return this.data.zipColId;
    }
    public set ZipColId(zipColId) {
        this.data.zipColId = zipColId;
    }
    public get LatitudeColId() {
        return this.data.latitudeColId;
    }
    public set LatitudeColId(latitudeColId) {
        this.data.latitudeColId = latitudeColId;
    }

    public get LongitudeColId() {
        return this.data.longitudeColId;
    }
    public set LongitudeColId(longitudeColId) {
        this.data.longitudeColId = longitudeColId;
    }

    public get ShowConfiguration() {
        return true;
    }

    public GridHasColumn(colName: string) {
        return (this.data.gridCols.filter(gc => gc.name.toLowerCase() == colName.toLowerCase()).length > 0);
    }

    onStart(): void {
        let returnValues = {
            lookupType: this.data.lookupType,
            streetColumnId: this.data.streetColId,
            cityColumnId: this.data.cityColId,
            stateColumnId: this.data.stateColId,
            zipColumnId: this.data.zipColId,
        }

        this.dialogRef.close(returnValues);
    }

    onDismiss(): void {
        this.dialogRef.close(false);
    }
}
