import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormRendererComponent } from '../components/form-renderer/form-renderer.component';
import { FormField } from '../models/form-builder/form-field.model';
import { ProgressIndicatorService } from './progress-indicator.service';
import { ChangedSelectionFieldValues } from '../models/flexible-selection-fields/changed-selection-field-values.model';
import { ListConstraintColumn } from '../models/flexible-selection-fields/list-constraint-column.model';
import { SelectionFieldValueChangeInstructions } from '../models/flexible-selection-fields/selection-field-value-change-instructions.model';
import { CollectApiServiceBase } from './collect-api-base.service';
import { FormInstanceElement } from '../models/form-builder/form-instance-element.model';
import { environment } from '../../../environments/environment';
import { FormInstance } from '../models/site-content/form-instance.model';
import { FieldIdToSelectedValues, FlexibleSelectionFieldInstructionsRequest } from '../models/flexible-selection-fields/flexible-selection-field-instructions-request.model';
import { FlexibleSelectionFieldType } from '../models/form-builder/form-field-types';
import { Subject, Observable } from "rxjs";
import { GridRowViewModel } from '../models/form-builder/grid-row.model';
import { FormFieldValidationResult } from '../models/flexible-selection-fields/form-field-validation-result.model';

export interface FieldIdToSingleOption { [key: number]: string }

@Injectable()
export class FlexibleSelectionFieldService extends CollectApiServiceBase<SelectionFieldValueChangeInstructions> {

    private formRenderer: FormRendererComponent;
    private cachedGridFieldOptions: ChangedSelectionFieldValues[];
    private autoSelectFieldValuesSubject: Subject<FieldIdToSingleOption> = new Subject<FieldIdToSingleOption>(); // allows changes to be pushed to subscribers
    private autoSelectFieldValues: FieldIdToSingleOption = null; // allows proactive look ups

    // Pharvey - 11/25/2024 - gridRowSelectedValuesByFieldId was introduced to provide a mechanism for GridRows' selected values to be set.
    // Form-level flex fields' selected values can be obtained by searching a formInstance's formInstanceElements but this doesn't
    // work for flex field in grids -- there is no mechanism for one field in a grid row to get the value of another. And so this
    // property was introduced. Each time a selection change is made in a grid, a mapping is added to this property and the whole
    // mapping is sent to the server so that the server can know the whole chain of selected values which it needs for correct behavior.
    // Note: It might be worth converting form-level flex fields to use this same approach for consistency sake, and to enable the server
    // code to be simplified so it doesn't have multiple ways of find selected values
    private gridRowSelectedValuesByFieldId: FieldIdToSelectedValues = {};

    constructor(http: HttpClient, progressIndicatorService: ProgressIndicatorService) {
        super(http, progressIndicatorService, environment.apiUrl, 'flexibleSelectionField', SelectionFieldValueChangeInstructions)
    }

    // Gets flex field instructions for an entire form instance
    public requestFlexibleFieldInstructionsForFormInstance(formInstanceId: number) {
        let url = `${this.url}/${this.endpoint}/getFlexibleFieldInstructionsForFormInstance/${formInstanceId}`;
        this.http.get<SelectionFieldValueChangeInstructions>(url)
            .toPromise()
            .then(instructions => {
                this.updateTargetFields(instructions);
            });
    }

    // Called when the value of a flex field changes. Get instructions for the updating the options of other flex fields
    // which depend on the one whose selection was changed
    public selectionFieldValueChanged(
        formField: FormField,
        formInstance: FormInstance,
        formInstanceElement: FormInstanceElement,
        parentValues: FormInstanceElement[],
        gridRowId: number = 0,
        gridColumnDefs: FormField[] = null): Promise<boolean> {

        if (this.formRenderer.hasDependentFields(formField)) {

            // Whenever a flex field's value is changed, we add a mapping of the field's Id to its new selected value so that the whole chain of selected values can be passed to the server
            // (This is for the benefit of flex fields in grids but could be used for regular fields also)

            this.getAllSelectedValuesToFieldIds(formInstanceElement);

            let payload = new FlexibleSelectionFieldInstructionsRequest(formInstance, formInstanceElement, this.gridRowSelectedValuesByFieldId, gridRowId, null);
            let url = `${this.url}/${this.endpoint}/GetFlexibleFieldInstructionsForField`;
            return this.http.post<SelectionFieldValueChangeInstructions>(url, payload)
                .toPromise()
                .then(instructions => {
                    this.updateTargetFields(instructions, gridColumnDefs);
                    let hasDependentGridFields = this.cachedGridFieldOptions?.length > 0;

                    let iAmNotAGridField = this.cachedGridFieldOptions?.filter(x => { return x.formFieldId == formField.id }).length == 0;
                    return iAmNotAGridField && hasDependentGridFields;
                });
        }
    }

    public validateGridRow(gridRow: GridRowViewModel): Promise<FormFieldValidationResult[]> {
        let url = `${this.url}/${this.endpoint}/ValidateGridRow`;

        return this.http.post<FormFieldValidationResult[]>(url, gridRow)
            .toPromise()
            .then(validationResults => {
                var failures = validationResults.filter(x => x.validationFailureMessage);
                return failures;
            });
    }

    private getAllSelectedValuesToFieldIds(formInstanceElement: FormInstanceElement): FieldIdToSelectedValues {
        // first add the new value to the local hash
        if (formInstanceElement.childFormInstanceElements) {
            this.gridRowSelectedValuesByFieldId[formInstanceElement.formFieldId] = formInstanceElement.childFormInstanceElements.map(x => { return x.textValue; });
        } else {
            this.gridRowSelectedValuesByFieldId[formInstanceElement.formFieldId] = formInstanceElement.getValueAsStringArray();
        }

        // next, create a new hash based on local values and those from form renderer
        let allSelectedValuesByFieldId: FieldIdToSelectedValues = Object.assign({}, this.gridRowSelectedValuesByFieldId);
        for (const key in this.FormRenderer.SelectedValuesByFieldId) {
            allSelectedValuesByFieldId[key] = this.FormRenderer.SelectedValuesByFieldId[key]
        }

        return allSelectedValuesByFieldId;
    }

    // This method is called when a grid row is opened for editing (it is NOT called when flex fields in a row have their selections changed - selectionFieldValueChanged() is always called for that)
    // If gridsUseCachedInstructions is true, the method does NOT make a call to the server but rather uses instructions returned by a previous call to get instructions
    public getFlexibleSelectFieldInstructionsForGridRow(formInstanceId: number, rowData: any, gridColFieldDefs: FormField[], formInstance: FormInstance = null) {
        this.autoSelectFieldValues = {};
        let flexFields = gridColFieldDefs.filter(x => { return x.fieldDefinitionClassName == FlexibleSelectionFieldType })
        if (flexFields.length == 0) return;

        if (flexFields && rowData) {
            // When opening a gridrow for editing, need to build a mapping of that row's fields to their selected values in that row
            // That's what initializeSelectedValuesMappingForGridRow() does
            this.initializeSelectedValuesMappingForGridRow(flexFields, rowData);
            let payload = new FlexibleSelectionFieldInstructionsRequest(formInstance, null, /*null,*/ this.gridRowSelectedValuesByFieldId, 0, gridColFieldDefs, true);

            let url = `${this.url}/${this.endpoint}/GetFlexibleFieldInstructionsForField`;
            this.http.post<SelectionFieldValueChangeInstructions>(url, payload)
                .toPromise()
                .then(instructions => {
                    this.updateTargetFields(instructions);
                    for (let newFieldValues of this.cachedGridFieldOptions) {
                        let formFieldId = newFieldValues.formFieldId;
                        let targetFormField: FormField = gridColFieldDefs.filter(x => { return x.id == formFieldId; })[0];
                        let options = newFieldValues.constraintValues.map(x => { return x.textValue; });
                        if (targetFormField) {
                            targetFormField.setSelectOptions(options.join('|'));
                            this.updateAutoSelectFieldValues(options, targetFormField);
                        }
                    }
                });
        } else {
            for (let newFieldValues of this.cachedGridFieldOptions) {
                let formFieldId = newFieldValues.formFieldId;
                let targetFormField: FormField = gridColFieldDefs.filter(x => { return x.id == formFieldId; })[0];
                if (targetFormField) {
                    let options = newFieldValues.constraintValues.map(x => { return x.textValue; });
                    targetFormField.setSelectOptions(options.join('|'));
                    this.updateAutoSelectFieldValues(options, targetFormField);
                }
            }
        }

        this.autoSelectFieldValuesSubject.next(this.autoSelectFieldValues);
    }

    // empties gridRowSelectedValuesByFieldId and re-populates it from values in rowData
    private initializeSelectedValuesMappingForGridRow(flexFields: FormField[], rowData: any) {
        this.gridRowSelectedValuesByFieldId = {};
        for (let flexField of flexFields) {
            this.gridRowSelectedValuesByFieldId[flexField.id] = rowData[flexField.name]?.split(",");
        }
    }

    // Takes a SelectionFieldValueChangeInstructions object and uses the formRenderer to update the options of all fields referenced in it
    // When the optional gridColumnDefs is passed and if a form-level target field cannot be found, it's searched for in gridColumnDefs
    // If no target field can be found and gridColumnDefs is not passed in, then the assumption is made that the selectable values are
    // are for a grid field and as added to this.cachedGridFieldOptions which can be accessed by Grids in a later call
    private updateTargetFields(
        instructions: SelectionFieldValueChangeInstructions,
        gridColumnDefs: FormField[] = null) {

        this.autoSelectFieldValues = {};
        for (let newFieldValues of instructions.fieldSelectableValues) {
            let formFieldId = newFieldValues.formFieldId;
            let targetFormField: FormField = this.formRenderer.form.formFields.find(x => { return x.id == formFieldId; });
            //let targetElement = this.formRenderer.formInstance.formInstanceElements.find(x => { return x.formFieldId = formFieldId });
            let options = newFieldValues.constraintValues.map(x => { return x.textValue; });
            if (targetFormField) {
                targetFormField.setSelectOptions(options.join('|'));
                this.updateAutoSelectFieldValues(options, targetFormField);
            } else if (gridColumnDefs) {
                let targetGridField = gridColumnDefs.filter(x => { return x.id == formFieldId; })[0];
                if (targetGridField) {
                    targetGridField.setSelectOptions(options.join('|'));
                    this.updateAutoSelectFieldValues(options, targetGridField);
                }
            } else {
                if (this.cachedGridFieldOptions == null) {
                    this.cachedGridFieldOptions = [];
                }
                // if the field wasn't found it means its a field in a grid which is not exposed to formRenderer, so here we cache them ready for when a user
                // clicks on a grid row to edit it, at which point it will use these cached values to populate the options of any flex fields in it
                if (this.cachedGridFieldOptions.indexOf(newFieldValues) == -1) {
                    this.cachedGridFieldOptions.push(newFieldValues);
                }
            }
        }
        this.autoSelectFieldValuesSubject.next(this.autoSelectFieldValues);
    }

    private updateAutoSelectFieldValues(options: string[], targetFormField: FormField) {
        if (options.length == 1) {
            this.autoSelectFieldValues[targetFormField.id] = options[0];
        }
    }

    // TODO - disambiguate this so it's clear there are two SelectedValuesByFieldId - one local to this service, and one for the whole FormInstance in FormRenderer
    public get SelectedValuesByFieldId(): FieldIdToSelectedValues {
        return this.formRenderer.SelectedValuesByFieldId;
    }

    // Allows Flex Field components to subscribe in case any need to auto-select a single value
    public get AutoSelectFieldValuesSubject(): Observable<any> {
        return this.autoSelectFieldValuesSubject.asObservable();
    }
    // Also provide a way to get values, not just subscribe to them
    // This is needed for fields in grids which don't get intialized
    // the same way as form level fields
    public get AutoSelectFieldValues(): any {
        return this.autoSelectFieldValues;
    }

    // For the identifield ListValue constraint, returns its available columns
    public GetColumns(formFieldConstraintId: number): Promise<ListConstraintColumn[]> {
        let url = `${this.url}/${this.endpoint}/GetColumns/${formFieldConstraintId}`;
        return this.http.get<ListConstraintColumn[]>(url)
            .toPromise()
            .then(cols => {
                return cols;
            });
    }

    public SetDependsOnParentFormFieldId(formFieldId, dependsOnParentFormFieldId): Promise<FormField[]> {
        let url = `${this.url}/${this.endpoint}/SetDependsOnParentFormFieldId/${formFieldId}/${dependsOnParentFormFieldId}`;
        return this.http.get<FormField[]>(url)
            .toPromise()
            .then(ffs => {
                return ffs;
            });
    }

    public get FormRenderer(): FormRendererComponent {
        return this.formRenderer;
    }

    public set FormRenderer(value: FormRendererComponent) {
        this.formRenderer = value;
    }
}
