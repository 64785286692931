// Service that gets claims for the currently logged-in user, which are used for show/hide logic and to guard routes
import { Injectable } from '@angular/core';
import { CollectApiServiceBase } from './collect-api-base.service';
import { UserClaims } from '../models/user-claims.model';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Logging } from '../logging';
import { CurrentUserService } from '../../security/current-user.service';
import { CurrentUser } from '../../security/current-user.model';
import { DataCollectionService } from './data-collection.service';
import { User } from '../components/site-editor/site-editor.component';
import { DataCollection } from '../models/site-content/data-collection.model';

@Injectable({
  providedIn: 'root'
})
export class ClaimService {

    constructor(
        private currentUserService: CurrentUserService,
        private dataCollectionService: DataCollectionService) {
        
    }

    // Returns a Promise which resolves to a UserClaims object which the call can use to
    // test for presence of specific claims
    // Used by show/hide logic
    public getClaims(): Promise<UserClaims> {
        return new Promise((resolve, reject) => {
            //return resolve(
            this.currentUserService.loginPromise.then(user => {
                if (this.dataCollectionService.currentSite != null) {
                    return this.dataCollectionService.currentSite.then(site => {
                        let claims = this.buildClaims(user, site);
                        return resolve(claims);
                    });
                } else {
                    let claims = this.buildClaims(user);
                    return resolve(claims);
                }
            })
            //);
        });
    }

    // Returns a Promise resolving to true if the current user has the claim passed, false otherwise
    // Used by Route Guards
    public has(requiredClaim: string, site: DataCollection = null): Promise<boolean> {
        return new Promise((resolve, reject) => {
            // TODO: THIS IS TEMPORARY, REMOVE IT AFTER DEV
            // console.log("MESSAGE FROM ClaimService: userGrantedAllClaims?..." + environment.userGrantedAllClaims);
            if (environment.userGrantedAllClaims) {
                //console.warn("WARNING: ClaimService.has() is using environment.userGrantedAllClaims...")
                return resolve(true);
            }
            if (requiredClaim == "CanViewSitePermissions" && site && site.currentUserCanSeeSimplePermissions) {
                return resolve(true);
            }

            return resolve(
                this.currentUserService.loginPromise.then(user => {
                    let claims = this.buildClaims(user, site);
                    return claims.has(requiredClaim);
                })
            );
        });
    }

    // Takes a CurrentUser object and uses it to build and return a UserClaims object
    private buildClaims(user: CurrentUser, site: DataCollection = null): UserClaims {
        if (user.hasAccessibleSites == null) {
            // 01-11-2021 note:  disabling after discussing via email with Paul.
            //                   Setting accessibleSites, a boolean, to true,
            //                   but the user might still have access to zero sites.
            /*
            this.dataCollectionService.CountAccessibleSites().then(x => {
                user.accessibleSites = (x > 0);
            });
            */
            user.accessibleSites = true;
        }

        var claims = new UserClaims(user, site);
        claims.id = 1; // what's this for?
        return claims;
    }

}
