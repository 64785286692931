import { DecimalPipe } from '@angular/common';
import { Component, OnInit, Renderer2, Type as AngularCoreType } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { DecimalInputFormFieldBaseComponent } from '../num-input-form-field-base/decimal-input-form-field-base.component';


// Note:  please note the 'providers' definition below, as it is needed.
//        Without it, you will get the following exception:
//
//             No value accessor for form control with unspecified name
//
// The above exception gets thrown when a component, in this case our
// base class, implements interface 'ControlValueAccessor' and does not
// provide the 'providers' definition below.  Implementing the
// 'ControlValueAccessor' interface allows a form field component to
// support [(ngMode)], so users of the component can use [(ngModel)].
@Component({
    selector: 'app-currency-form-field',
    templateUrl: './currency-form-field.component.html',
    styleUrls: [
        './currency-form-field.component.scss',
        '../form-fields.scss',
        '../num-input-form-field-base/num-input-form-field-base.component.scss'
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: CurrencyFormFieldComponent,
            multi: true
        }
    ],
    standalone: false
})
export class CurrencyFormFieldComponent extends DecimalInputFormFieldBaseComponent implements OnInit {
    // Properties.
    // Note:  several properties are implemented in my base class.

    // Constructor.
    private readonly decimalPipe: DecimalPipe = new DecimalPipe('en-US');
    private formattedPreviewValue: string = '';
    public hasFocus: boolean = false;

    public constructor(rendererParam: Renderer2) {
        super(rendererParam);

        this.matInputId = this.generateUniqueId('currency');
    }

    // Life cycle methods.
    public ngOnInit(): void {
        let hshProperties = this.getProperties();
        this.onInit.emit(hshProperties);
    }

    // Override a method used to get my class.
    public getFormFieldClass(): AngularCoreType<any> {
        return (CurrencyFormFieldComponent);
    }

    public onChange() {
        this.formattedPreviewValue = this.FormatCurrency(this.FormControl.value);
    }

    public get NumberFormatPreview(): string {
        return this.formattedPreviewValue;
    }

    // Override a protected method.
    protected writeValueTriggered(): void {
        super.writeValueTriggered();

        //VNEXT-706: KLW - Conditional to check if the formcontrol exists, otherwise it will throw an error in the form template when a Currency form field is dragged and dropped upon it
        if (this.controlData.formControl) {
            if (this.FormInstanceElement.decimalValue != null) {
                this.FormInstanceElement.transientOriginalDecimalValue = this.FormInstanceElement.decimalValue;
                this.controlData.formControl.setValue(this.FormatCurrency(this.FormInstanceElement.decimalValue));
            }
        }
    }

    public handleOnFocus(eventData: FocusEvent): void {
        this.hasFocus = true;
        if (this.FormInstanceElement.decimalValue) {
            let v = this.FormInstanceElement.decimalValue.toString();
            this.controlData.formControl.setValue(v.replace(/[^\d.-]/g, ''));
        }
    }

    public handleOnBlur() {
        this.hasFocus = false;
    }
    private FormatCurrency(inputVal): string {
        if (inputVal) {
            let val = parseFloat(inputVal.toString().replace(/,/g, ''));
            if (isNaN(val)) return "";
            return this.decimalPipe.transform(val, '1.2-2');
        }
    }
}
