import { Component, Renderer2 } from '@angular/core';

import { NumericInputFormFieldBaseComponent } from './num-input-form-field-base.component';
//import { IFormFieldComponent } from '../../../interfaces/iform-field-component';
import { GridFormInstanceElementWrapper } from '../../../models/grid/grid-form-instance-element-wrapper.model';
import { FormField } from '../../../models/form-builder/form-field.model';
import { FormInstanceElement, FormInstanceElementValueTypeEnum } from '../../../models/form-builder/form-instance-element.model';

@Component({
    selector: 'app-integer-input-form-field-base',
    templateUrl: './num-input-form-field-base.component.html',
    styleUrls: ['./num-input-form-field-base.component.scss'],
    standalone: false
})
export abstract class IntegerInputFormFieldBaseComponent extends NumericInputFormFieldBaseComponent {
    // Constructor.
    public constructor(protected renderer: Renderer2) {
        super(renderer);
    }

    // Implement methods common to form field component
    // classes that use FormInstanceElement property 'textValue'.
    public pseudoStatic_pasteValue(value: string, elementWrapper: GridFormInstanceElementWrapper, formField: FormField): void {
        elementWrapper.formInstanceElement.IntValue = parseInt(value);
        elementWrapper.formInstanceElement.valueType = FormInstanceElementValueTypeEnum.TypeInteger;
        elementWrapper.standinDisplayValue = elementWrapper.formInstanceElement.IntValue.toString();
    }

    // Handle updates made outside the control.
    public formInstanceElementUpdated(formInstanceElement: FormInstanceElement): void {
        if ((this.controlData != null) && (this.controlData.formControl != null))
            this.controlData.formControl.setValue(formInstanceElement.intValue);
    }
}
