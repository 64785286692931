import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import {
    FileUploadDialogComponent,
    FileUploadDialogKeyValues,
    IFileUploadProgressValueHash
} from '../../dialogs/file-upload/file-upload-dialog.component';

@Component({
    selector: 'app-file-uploader',
    templateUrl: './file-uploader.component.html',
    styleUrls: ['./file-uploader.component.scss'],
    standalone: false
})
export class FileUploaderComponent implements OnInit {
    // Properties.
    @Input() dataCollectionId: number;
    @Input() folderId: number;
    @Input() formInstanceId: number;
    @Input() formFieldId: number;
    @Input() attachmentCount: number;

    @Output() filesUploaded: EventEmitter<IFileUploadProgressValueHash> = new EventEmitter <IFileUploadProgressValueHash>();

    // Constructor.
    constructor(private dialog: MatDialog)
    {
        return;
    }

    // Life cycle methods.
    ngOnInit(): void {
        return;
    }

    // Handle control events.
    public openUploadDialog(): void {
        let keyValues: FileUploadDialogKeyValues = new FileUploadDialogKeyValues();
        keyValues.dataCollectionId = this.dataCollectionId;
        keyValues.folderId = this.folderId;
        keyValues.formInstanceId = this.formInstanceId;
        keyValues.formFieldId = this.formFieldId;
        keyValues.attachmentCount = this.attachmentCount;

        let dialogConfig: MatDialogConfig = new MatDialogConfig();
        dialogConfig.width = '70%';
        dialogConfig.height = '85%';
        dialogConfig.data = keyValues;

        let dialogRef = this.dialog.open(FileUploadDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(result => {
            let hshUploadedFileInfo: IFileUploadProgressValueHash = <IFileUploadProgressValueHash>result;

            this.handleFilesUploaded(hshUploadedFileInfo);
        });

        return;
    }

    // Implement private helper methods.
    private handleFilesUploaded(hshUploadedFileInfo: IFileUploadProgressValueHash): void {
        this.filesUploaded.emit(hshUploadedFileInfo);

        return;
    }
}
