import { Component, OnInit, OnDestroy, AfterViewInit, ElementRef, forwardRef, SkipSelf, Optional, ViewChildren, QueryList, Injectable, Input, Type as AngularCoreType } from '@angular/core';
import { MatInput } from '@angular/material/input';
import { MatCheckbox } from '@angular/material/checkbox';

import { AppInjector } from '../../../app.module';

import { HtmlElementInfo } from '../../models/component-scripting/html-element-info.model';
import { ITestableComponent } from '../../interfaces/itestable-component.interface';
import { ComponentHierarchyService } from '../../services/component-hierarchy.service';
import { ElementCountExpected } from '../../models/component-scripting/element-count-expected.enum';
import { IElementTypeMetadata } from '../../interfaces/component-scripting/ielement-type-metadata';
import { ElementTypeMetadata } from '../../models/component-scripting/element-type-metadata.model';
import { ScriptableElementsToFind } from '../../models/component-scripting/elements-to-find.model';
import { ComponentMethodMetadata, ComponentMethodsMetadata, IComponentNameToMethodsMetadata } from '../../models/component-scripting/component-methods-metadata.model';
import { ComponentMethodsService, EnumMetadata, IEnumMetadataByName } from '../../models/component-scripting/component-methods.service';
import { Scriptable } from '../../models/component-scripting/scriptable-function.annotation';
import { IScriptableElement, ScriptableElement } from '../../models/component-scripting/scriptable-element.model';
import { IEnumReference, EnumReference } from '../../models/component-scripting/enum-reference.model';
import { StringUtil } from '../../utility-classes/string.util';
import { HtmlElementTypeService } from '../../services/html-element-type.service';
import { JQueryBrowserDriverService } from '../../services/jquery-browser-driver.service';
import { HtmlElementTypeNames } from '../../models/component-scripting/html-element-type-names.model';
import { AdditionalElementInfo, IAdditionalElementInfo, INameToPrettyNameMap, IOperationCompletedServiceMap } from '../../models/component-scripting/additional-element-info.model';
import { IDrawerComponentLiaison } from '../../interfaces/idrawer-component-liaison';
import { ScriptableComponentStatus } from '../../enums/component-scripting/scriptable-component-status.enum';
import { HtmlMetadataTagNames } from '../../models/component-scripting/html-element-type-names.model';
import { IBrowserDriver } from '../../interfaces/ibrowser-driver.interface';
 
// Define classes, enumerations used within this file.

 // Package timeout-related properties in a class.
class WatchTimeoutInfo {
    public readonly timeoutInMilliseconds: number = 100;
    public readonly maxTimeoutsLookingForScriptableElements: number = 25;

    public timeoutsCompleted: number = 0;
}

class ScriptableElementsData {
    public scriptableElementsToFind: ScriptableElementsToFind[] = [];
    public componentMethodsFound: boolean = false;

    //public enumMetadataByName: IEnumMetadataByName = {};
}

// Define the scriptable component base class.
@Component({
    selector: 'app-scriptable-base',
    templateUrl: './scriptable-base.component.html',
    styleUrls: ['./scriptable-base.component.scss'],
    standalone: false
})
@Injectable({
    providedIn: 'root' // just before your class
})
export class ScriptableBaseComponent implements OnInit, OnDestroy, AfterViewInit, ITestableComponent {
    // Properties.
    private initializationStatus: ScriptableComponentStatus = ScriptableComponentStatus.Initializing;

    private readonly watchTimeoutInfo: WatchTimeoutInfo = new WatchTimeoutInfo();
    private scriptableElementsInfo: ScriptableElementsData = new ScriptableElementsData();

    // Id management.
    private static nextId: number = 1;
    private componentId: number = ScriptableBaseComponent.nextId++;

    //private static htmlElementTypeService: HtmlElementTypeService = new HtmlElementTypeService();

    // MatInput elements.
    @ViewChildren(MatInput, { read: MatInput }) public matInputs: QueryList<MatInput>;
    @ViewChildren(MatInput, { read: ElementRef }) public matInputElementRefs: QueryList<ElementRef>;
    // MatCheckbox elements.
    @ViewChildren(MatCheckbox, { read: MatCheckbox }) public matCheckboxes: QueryList<MatCheckbox>;
    @ViewChildren(MatCheckbox, { read: ElementRef }) public matCheckboxElementRefs: QueryList<ElementRef>;

    private optionalDrawerComponentLiaison: IDrawerComponentLiaison = null;

    // Constructor.
    protected constructor(private el: ElementRef) {
    }

    protected hasDrawerComponentLiaison(drawerComponentLiaison: IDrawerComponentLiaison) {
        this.optionalDrawerComponentLiaison = drawerComponentLiaison;
    }

    protected get componentHierarchyService(): ComponentHierarchyService {
        let service: ComponentHierarchyService = AppInjector.get(ComponentHierarchyService);
        return service;
    }
    protected get htmlElementTypeService(): HtmlElementTypeService {
        let service: HtmlElementTypeService = AppInjector.get(HtmlElementTypeService);
        return service;
    }
    protected get jqueryBrowserDriverService(): JQueryBrowserDriverService {
        let service: JQueryBrowserDriverService = AppInjector.get(JQueryBrowserDriverService);
        return service;
    }

    // Life cycle methods.
    public ngOnInit(): void {
        this.componentHierarchyService.registerChildComponent(this);

        if (this.optionalDrawerComponentLiaison != null)
            this.optionalDrawerComponentLiaison.drawerComponentInitialized(this);

        if (this.scriptableElementsInfo.scriptableElementsToFind.length > 0)
            this.findScriptableElements();
        else {
            //ScriptableBaseComponent.findAnyScriptableComponentMethods(this.scriptableElementsToFind);
            this.InitializationStatus = ScriptableComponentStatus.InitializationComplete;
            this.notifyInitializationCompleted();
        }
    }
    protected reFindScriptableElements(): void {
        if (this.scriptableElementsInfo.scriptableElementsToFind.length > 0) {
            for (let index: number = 0; index < this.scriptableElementsInfo.scriptableElementsToFind.length; index++)
                this.scriptableElementsInfo.scriptableElementsToFind[index].elementsFound = false;

            this.initializationStatus = ScriptableComponentStatus.Initializing;
            this.watchTimeoutInfo.timeoutsCompleted = 0;
            this.findScriptableElements();
        }
    }

    public ngAfterViewInit(): void {
    }

    public ngOnDestroy(): void {
        this.componentHierarchyService.removeComponent(this);
    }

    // ITestableComponent methods.
    public get tagName(): string {
        let tag: string = 'null';

        if ((this.el != null) && (this.el.nativeElement != null))
            tag = this.el.nativeElement.tagName.toLowerCase();

        return tag;
    }
    public get id(): number {
        return this.componentId;
    }

    // Define methods for querying a testable component's elements.
    public get elementTypes(): string[] {
        let elementTypes: string[] = [];

        // Get any component methods.
        /*
        if (!this.scriptableElementsInfo.componentMethodsFound) {
            this.findAnyScriptableComponentMethods(this.scriptableElementsInfo.scriptableElementsToFind);
            this.scriptableElementsInfo.componentMethodsFound = true;
        }
        */
        this.getAnyComponentMethods();

        // Get the 
        let mapOfElementTypes = {};
        for (let index: number = 0; index < this.scriptableElementsInfo.scriptableElementsToFind.length; index++) {
            let elementsToFind: ScriptableElementsToFind = this.scriptableElementsInfo.scriptableElementsToFind[index];
            //let elementType: string = (elementsToFind.elementTypeMetadata.prettyElementTypeTitle != null ? elementsToFind.elementTypeMetadata.prettyElementTypeTitle : elementsToFind.elementTypeMetadata.metadataKey);
            let elementType: string = elementsToFind.elementTypeMetadata.metadataKey;
            //let elementSubTypes: string[] = this.getSubtypesForElementType(elementType);

            if (mapOfElementTypes[elementType] == null) {
                mapOfElementTypes[elementType] = elementType;
                elementTypes.push(elementType);
            }
        }

        return elementTypes;
    }
    /*
    public get elementTypeInfo(): ElementTypeInfo[] {
        let allElementTypeInfos: ElementTypeInfo[] = [];

        // Get any component methods.
        this.getAnyComponentMethods();

        // Get the 
        let mapOfElementTypes = {};
        for (let index: number = 0; index < this.scriptableElementsInfo.scriptableElementsToFind.length; index++) {
            let elementsToFind: ScriptableElementsToFind = this.scriptableElementsInfo.scriptableElementsToFind[index];
            let elementType: string = elementsToFind.elementTypeMetadata.metadataKey;
            //let elementSubTypes: string[] = this.getSubtypesForElementType(elementType);
            let elementSubtype: string = (elementsToFind.additionalElementInfo.elementSubtypeNames != null) && 

            if (mapOfElementTypes[elementType] == null) {
                mapOfElementTypes[elementType] = elementType;
                elementTypes.push(elementType);
            }
        }

        return allElementTypeInfos;
    }
    */

    private getAnyComponentMethods(): void {
        if (!this.scriptableElementsInfo.componentMethodsFound) {
            this.findAnyScriptableComponentMethods(this.scriptableElementsInfo.scriptableElementsToFind);
            this.scriptableElementsInfo.componentMethodsFound = true;
        }
    }

    /*
    public getSubtypesForElementType(elementType: string): string[] {
        let subtypes: string[] = [];
        //let allElementsHaveSubtype: boolean = true;

        for (let index: number = 0; index < this.scriptableElementsInfo.scriptableElementsToFind.length; index++) {
            let elementsToFind: ScriptableElementsToFind = this.scriptableElementsInfo.scriptableElementsToFind[index];

            if ((elementsToFind.elementTypeMetadata.metadataKey == elementType) && (elementsToFind.elements != null) && (elementsToFind.elements['length'] != null)) {
                if (elementsToFind.additionalElementInfo.elementSubtypeNames != null) {
                    for (let elIndex: number = 0; elIndex < elementsToFind.elements['length']; elIndex++) {
                        let elementSubtype: string = elementsToFind.additionalElementInfo.elementSubtypeNames.length > elIndex ? elementsToFind.additionalElementInfo.elementSubtypeNames[elIndex] : null;
                        if ((elementSubtype != null) && (subtypes.find(st => st == elementSubtype) == null))
                            subtypes.push(elementSubtype);
                    }
                }
            }
        }

        return subtypes;
    }
    */

    public getElementsOfType(elementType: string): HtmlElementInfo[] {
        let elements: HtmlElementInfo[] = [];

        let elementTypeMetadata: ElementTypeMetadata = ScriptableBaseComponent.getMetadataForElementType(this.htmlElementTypeService, elementType);

        for (let index: number = 0; index < this.scriptableElementsInfo.scriptableElementsToFind.length; index++) {
            let elementsToFind: ScriptableElementsToFind = this.scriptableElementsInfo.scriptableElementsToFind[index];

            if ((elementsToFind.elementTypeMetadata.metadataKey == elementType) && (elementsToFind.elements != null) && (elementsToFind.elements['length'] != null)) {
                for (let elIndex: number = 0; elIndex < elementsToFind.elements['length']; elIndex++) {
                    let element: object = elementsToFind.elements[elIndex];
                    //let elementTitle: string = elementTypeMetadata.getTitle(this.jqueryBrowserDriverService, element);
                    let elementTitle: string;
                    // TO DO:  need to EITHER use property 'customTextValues' OR 'names', not both, probably the latter.
                    if ((elementsToFind.additionalElementInfo != null) && (elementsToFind.additionalElementInfo.customTextValues != null) && (elementsToFind.additionalElementInfo.customTextValues.length > elIndex))
                        elementTitle = elementsToFind.additionalElementInfo.customTextValues[elIndex]
                    else if ((elementsToFind.additionalElementInfo != null) && (elementsToFind.additionalElementInfo.names != null) && (elementsToFind.additionalElementInfo.names.length > elIndex))
                        elementTitle = elementsToFind.additionalElementInfo.names[elIndex];
                    else
                        elementTitle = elementTypeMetadata.getTitle(this.jqueryBrowserDriverService, element, elementsToFind.additionalElementInfo);

                    if ((elementTitle != null) && (elementTitle.trim() != '')) {
                        if (elementsToFind.additionalElementInfo == null)
                            elementsToFind.additionalElementInfo = new AdditionalElementInfo({});
                        if (elementsToFind.additionalElementInfo.names == null)
                            elementsToFind.additionalElementInfo.names = [];

                        if (elementsToFind.additionalElementInfo.names.length <= elIndex)
                            elementsToFind.additionalElementInfo.names.push(elementTitle);
                    } else if ((elementsToFind.additionalElementInfo != null) && (elementsToFind.additionalElementInfo.names != null) && elementsToFind.additionalElementInfo.names.length > elIndex) {
                        elementTitle = elementsToFind.additionalElementInfo.names[elIndex];
                    }

                    let singularElementType: string = StringUtil.singular(elementType);
                    let elementInfo: HtmlElementInfo = new HtmlElementInfo(elementTypeMetadata, element, singularElementType, elementTitle);
                    // Any additional information for this element?
                    if (elementsToFind.additionalElementInfo != null)
                        elementInfo.additionalElementInfo = elementsToFind.additionalElementInfo;
                    // Get the element's subtype, if any.
                    if ((elementsToFind.additionalElementInfo != null) && (elementsToFind.additionalElementInfo.elementSubtypeNames != null) && (elementsToFind.additionalElementInfo.elementSubtypeNames.length > elIndex))
                        elementInfo.elementSubtype = elementsToFind.additionalElementInfo.elementSubtypeNames[elIndex];
                    else if ((elementsToFind.additionalElementInfo != null) && (elementsToFind.additionalElementInfo.elementSubtypeName != null))
                        elementInfo.elementSubtype = elementsToFind.additionalElementInfo.elementSubtypeName;
                    // Get the element's display tab name.
                    this.setDisplayTabNameForElementTypeSubtype(elementInfo);

                    elements.push(elementInfo);
                }
            }
        }

        return elements;
    }

    protected setDisplayTabNameForElementTypeSubtype(htmlElementInfo: HtmlElementInfo): void {
        // Note:  this initial implementation simply sets the element subtype.
        if (htmlElementInfo.elementSubtype != null) {
            if ((htmlElementInfo.elementSubtype == 'Properties') || (htmlElementInfo.elementSubtype == 'Favorites') || (htmlElementInfo.elementSubtype == 'Roles'))
                htmlElementInfo.displayTabName = 'Properties, etc.';
            else if ((htmlElementInfo.elementSubtype == 'Folder') || (htmlElementInfo.elementSubtype == 'Form'))
                htmlElementInfo.displayTabName = 'Folders and Forms';
            else
                htmlElementInfo.displayTabName = htmlElementInfo.elementSubtype;
        }            
        else
            htmlElementInfo.displayTabName = htmlElementInfo.elementTypeMetadata.prettyElementTypeTitle != null ? htmlElementInfo.elementTypeMetadata.prettyElementTypeTitle : htmlElementInfo.elementType;
    }

    // Configuration methods available to derived classes.
    protected element(anElementSelector: string): IScriptableElement {
        return new ScriptableElement(anElementSelector, this);
    }
    public elementHasLinks(anElementSelector: string, optionalAdditionalElementInfo: AdditionalElementInfo = null): void {
        this.setupSearchForElements(anElementSelector, HtmlElementTypeNames.links_metadataKey, optionalAdditionalElementInfo);
    }
    public elementHasButtons(anElementSelector: string, optionalAdditionalElementInfo: AdditionalElementInfo = null): void {
        this.setupSearchForElements(anElementSelector, HtmlElementTypeNames.buttons_metadataKey, optionalAdditionalElementInfo);
    }
    public elementHasButtonsWithMatIcons(anElementSelector: string, optionalAdditionalElementInfo: AdditionalElementInfo = null): void {
        this.setupSearchForElements(anElementSelector, HtmlElementTypeNames.buttonsWithMatIcons_metadataKey, optionalAdditionalElementInfo);
    }
    public elementHasMatListItems(anElementSelector: string, optionalAdditionalElementInfo: AdditionalElementInfo = null): void {
        this.setupSearchForElements(anElementSelector, HtmlElementTypeNames.matListItems_metadataKey, optionalAdditionalElementInfo);
    }
    public elementHasMatIcons(anElementSelector: string, optionalAdditionalElementInfo: AdditionalElementInfo = null): void {
        this.setupSearchForElements(anElementSelector, HtmlElementTypeNames.matIcons_metadataKey, optionalAdditionalElementInfo);
    }
    public elementHasMatSelect(anElementSelector: string, optionalAdditionalElementInfo: AdditionalElementInfo = null): void {
        this.setupSearchForElements(anElementSelector, HtmlElementTypeNames.matSelect_metadataKey, optionalAdditionalElementInfo);
    }
    public elementHasInput(anElementSelector: string, optionalAdditionalElementInfo: AdditionalElementInfo = null): void {
        //this.setupSearchForElements(anElementSelector, 'input');
        this.setupSearchForElements(anElementSelector, HtmlElementTypeNames.input_metadataKey, optionalAdditionalElementInfo);
    }
    public elementHasInputs(anElementSelector: string, optionalAdditionalElementInfo: AdditionalElementInfo = null): void {
        //this.setupSearchForElements(anElementSelector, 'input');
        this.setupSearchForElements(anElementSelector, HtmlElementTypeNames.inputs_metadataKey, optionalAdditionalElementInfo);
    }
    public elementHasMatCheckBox(anElementSelector: string, optionalAdditionalElementInfo: AdditionalElementInfo = null): void {
        this.setupSearchForElements(anElementSelector, HtmlElementTypeNames.matCheckBox_metadataKey, optionalAdditionalElementInfo);
    }
    public elementHasMatList(anElementSelector: string, optionalAdditionalElementInfo: AdditionalElementInfo = null): void {
        this.setupSearchForElements(anElementSelector, HtmlElementTypeNames.matList_metadataKey, optionalAdditionalElementInfo);
    }

    // Methods for additional additional information for specified elements.
    public hasInnerTextSelector(anElementSelector: string, innerTextSelector: string): void {
        let additionalElementInfo: AdditionalElementInfo = this.getLastAdditionalElementInfoFor(anElementSelector);
        additionalElementInfo.innerTextSelector = innerTextSelector;
    }

    public elementHasCustomPreprocessingFunction(anElementSelector: string, preprocessingFunction: (driver: IBrowserDriver, element: object, elementTypeMetadata: IElementTypeMetadata, additionalElementInfo: IAdditionalElementInfo) => void): void {
        let additionalElementInfo: AdditionalElementInfo = this.getLastAdditionalElementInfoFor(anElementSelector);
        additionalElementInfo.customPreprocessingFunction = preprocessingFunction;
    }
    public elementHasCustomGetTextFunction(anElementSelector: string, getTextFunction: (driver: IBrowserDriver, element: object, existingTextValue: string, additionalElementInfo: IAdditionalElementInfo) => string): void {
        let additionalElementInfo: AdditionalElementInfo = this.getLastAdditionalElementInfoFor(anElementSelector);
        additionalElementInfo.customGetTextForFunction = getTextFunction;
    }
    public elementHasGetClickableElementFunction(anElementSelector: string, getClickableElementFunction: (element: object) => object): void {
        let additionalElementInfo: AdditionalElementInfo = this.getLastAdditionalElementInfoFor(anElementSelector);
        additionalElementInfo.getClickableElementFunction = getClickableElementFunction;
    }

    public elementHasName(anElementSelector: string, name: string): void {
        let additionalElementInfo: AdditionalElementInfo = this.getLastAdditionalElementInfoFor(anElementSelector);
        additionalElementInfo.name = name;
        additionalElementInfo.names = [name];
    }
    public elementHasNames(anElementSelector: string, names: string[]): void {
        let additionalElementInfo: AdditionalElementInfo = this.getLastAdditionalElementInfoFor(anElementSelector);
        additionalElementInfo.names = names;
    }
    public elementHasNameToPrettyNamesMap(anElementSelector: string, nameToPrettyNameMap: INameToPrettyNameMap): void {
        let additionalElementInfo: AdditionalElementInfo = this.getLastAdditionalElementInfoFor(anElementSelector);
        additionalElementInfo.nameToPrettyNameMap = nameToPrettyNameMap;
    }
    public elementHasStandardNameToPrettyStandardName(anElementSelector: string, standardNameToPrettyStandardName: INameToPrettyNameMap): void {
        let additionalElementInfo: AdditionalElementInfo = this.getLastAdditionalElementInfoFor(anElementSelector);
        additionalElementInfo.standardNameToPrettyStandardNameMap = standardNameToPrettyStandardName;
    }
    public elementHasElementSubtypesMaps(anElementSelector: string, elementsSubtypeMap: INameToPrettyNameMap): void {
        let additionalElementInfo: AdditionalElementInfo = this.getLastAdditionalElementInfoFor(anElementSelector);
        additionalElementInfo.elementsSubtypeMap = elementsSubtypeMap;
    }
    public elementHasAddedDateTimeSuffix(anElementSelector: string, hasAddedDateTimeSuffix: boolean = true): void {
        let additionalElementInfo: AdditionalElementInfo = this.getLastAdditionalElementInfoFor(anElementSelector);
        additionalElementInfo.hasAddedDateTimeSuffix = hasAddedDateTimeSuffix;
    }
    public elementHasOperationCompletedServiceMap(anElementSelector: string, operationCompletedServiceMap: IOperationCompletedServiceMap): void {
        let additionalElementInfo: AdditionalElementInfo = this.getLastAdditionalElementInfoFor(anElementSelector);
        additionalElementInfo.operationCompletedServiceMap = operationCompletedServiceMap;
    }

    public elementHasToggleCheckboxMethodName(anElementSelector: string, methodName: string): void {
        let additionalElementInfo: AdditionalElementInfo = this.getLastAdditionalElementInfoFor(anElementSelector);
        additionalElementInfo.toggleCheckboxMethodName = methodName;
    }
    public elementHasSubtype(anElementSelector: string, subtype: string): void {
        let additionalElementInfo: AdditionalElementInfo = this.getLastAdditionalElementInfoFor(anElementSelector);
        additionalElementInfo.elementSubtypeName = subtype;
    }

    public elementAllowsZeroElements(anElementSelector: string): void {
        let additionalElementInfo: AdditionalElementInfo = this.getLastAdditionalElementInfoFor(anElementSelector);
        additionalElementInfo.allowZeroElements = true;
    }
    public elementHasMaxTimeoutsLookingForScriptableElements(anElementSelector: string, maxTimeoutsLookingForScriptableElements: number): void {
        let additionalElementInfo: AdditionalElementInfo = this.getLastAdditionalElementInfoFor(anElementSelector);
        additionalElementInfo.maxTimeoutsLookingForScriptableElements = maxTimeoutsLookingForScriptableElements;
    }

    public usesEnum(enumName: string): IEnumReference {
        return new EnumReference(enumName, this);
    }
    public enumHasValues(enumName: string, values: string[]): void {
        let enumMetadata: EnumMetadata = new EnumMetadata(enumName, values);
        //this.scriptableElementsInfo.enumMetadataByName[enumName] = enumMetadata;

        ComponentMethodsService.registerOrUpdateEnum(enumName, enumMetadata);
    }
    public enumHasPrettyNames(enumName: string, prettyNames: string[]): void {
        //let enumMetadata: EnumMetadata = this.scriptableElementsInfo.enumMetadataByName[enumName];
        let enumMetadata: EnumMetadata = ComponentMethodsService.getEnumMetadata(enumName);
        if (enumMetadata == null)
            ScriptableBaseComponent.raiseException(`enumHasPrettyNames():  cannot assign pretty names to unknown enum '${enumName}'.`);

        enumMetadata.prettyNames = prettyNames;

        ComponentMethodsService.registerOrUpdateEnum(enumName, enumMetadata);
    }

    // Define a method for preprocessing elements defined in a conmponent's list view.
    // Define a custom get text function that will be moved into a list view component file.
    public setupSearchForListViewElements(toolbarMatIconsNameToPrettyNameMap: INameToPrettyNameMap, elementSubtypeMap: INameToPrettyNameMap = null, allowZeroContentItems: boolean = true) {//maxTimeoutsLookingForScriptableElements: number = null): void {
        /*
        let toolbarScriptableElement: IScriptableElement = this.element('#listViewRightIcons').hasMatIcons();
        if (toolbarMatIconsNameToPrettyNameMap != null)
            toolbarScriptableElement = toolbarScriptableElement.withNameToPrettyNameMap(toolbarMatIconsNameToPrettyNameMap).hasCustomPreprocessorFunction(this.preprocessListViewToolbarMatIcons).hasGetClickableElementFunction(this.getClickableListViewToolbarElementFunction);
        toolbarScriptableElement = toolbarScriptableElement.hasButtonsWithMatIcons();
        */

        let toolbarScriptableElement: IScriptableElement = this.element('#listViewRightIcons').hasButtonsWithMatIcons();
        if (toolbarMatIconsNameToPrettyNameMap != null)
            toolbarScriptableElement = toolbarScriptableElement.withNameToPrettyNameMap(toolbarMatIconsNameToPrettyNameMap).hasCustomPreprocessorFunction(this.preprocessListViewToolbarMatIcons);

        let standardNamesToPrettyStandardNames = {
            'info': 'Properties',
            'bootstrap-star': 'Favorites',
            'person-fill': 'Roles'
        };

        let scriptableElement: IScriptableElement = this.element('#listViewContent').hasMatIcons().withStandardNameToPrettyStandardNameMap(standardNamesToPrettyStandardNames);
        if (elementSubtypeMap != null)
            scriptableElement = scriptableElement.withElementsSubtypeMap(elementSubtypeMap);
        if (allowZeroContentItems)
            scriptableElement = scriptableElement.allowsZeroElements();
        scriptableElement = scriptableElement.hasCustomPreprocessorFunction(this.preprocessListViewMatIcons);
    }
    protected preprocessListViewToolbarMatIcons = (driver: IBrowserDriver, elements: object, elementTypeMetadata: IElementTypeMetadata, additionalElementInfo: IAdditionalElementInfo) => {
        if ((elements['length'] != null) && (elements['length'] > 0)) {
            additionalElementInfo.customTextValues = [];
            additionalElementInfo.elementSubtypeNames = [];

            for (let index: number = 0; index < elements['length']; index++) {
                let element: object = elements[index];

                let existingTextValue: string = elementTypeMetadata.getTitle(driver, element);
                if (existingTextValue != null)
                    existingTextValue = existingTextValue.trim();

                let customTextValue: string;
                if ((additionalElementInfo.nameToPrettyNameMap != null) && (additionalElementInfo.nameToPrettyNameMap[existingTextValue]))
                    customTextValue = additionalElementInfo.nameToPrettyNameMap[existingTextValue];
                else
                    customTextValue = existingTextValue;

                additionalElementInfo.customTextValues.push(customTextValue);
                additionalElementInfo.elementSubtypeNames.push('Toolbar Buttons');
            }
        }
    }
    /*
    protected preprocessListViewToolbarButtons = (driver: IBrowserDriver, elements: object, elementTypeMetadata: IElementTypeMetadata, additionalElementInfo: IAdditionalElementInfo) => {
    }
    */

    protected preprocessListViewMatIcons = (driver: IBrowserDriver, elements: object, elementTypeMetadata: IElementTypeMetadata, additionalElementInfo: IAdditionalElementInfo) => {
        if ((elements['length'] != null) && (elements['length'] > 0)) {
            additionalElementInfo.customTextValues = [];
            additionalElementInfo.elementSubtypeNames = [];

            for (let index: number = 0; index < elements['length']; index++) {
                let element: object = elements[index];

                let existingTextValue: string = elementTypeMetadata.getTitle(driver, element);
                if (existingTextValue != null)
                    existingTextValue = existingTextValue.trim();

                let customTextValue: string = existingTextValue;
                let elementSubtype: string = '';

                // First handle the 'info' and 'bootstrap-star' icons that are pretty commonly used with a list view component.
                if ((existingTextValue == 'info') || (existingTextValue == 'bootstrap-star') || (existingTextValue == 'person-fill')) {
                    // Handle the 'info' and 'bootstrap-star' icons.
                    let ancestor: object = driver.getAncestor(element, 5);
                    if (ancestor != null) {
                        let namePlateDiv: object = driver.findElementIn(ancestor, 'div.tile-namePlate');
                        if (namePlateDiv != null) {
                            let nameDiv: object = driver.findElementIn(namePlateDiv, 'div.tile-name');
                            if (nameDiv != null) {
                                let listItemName: string = nameDiv['innerText'];
                                if ((listItemName != null) && (listItemName.trim() != '')) {
                                    if ((additionalElementInfo != null) && (additionalElementInfo.standardNameToPrettyStandardNameMap != null) && (additionalElementInfo.standardNameToPrettyStandardNameMap[existingTextValue] != null)) {
                                        let prettyStandardName: string = additionalElementInfo.standardNameToPrettyStandardNameMap[existingTextValue];

                                        customTextValue = prettyStandardName + ': ' + listItemName;
                                        elementSubtype = prettyStandardName;
                                    } else {
                                        let customTextValue: string = listItemName.trim();
                                        elementSubtype = existingTextValue;
                                    }
                                }
                            }
                        }
                    }
                //} else if ((existingTextValue == 'collections') || (existingTextValue == 'library_books') || (existingTextValue == 'folder')) {
                } else if ((existingTextValue != null) && (existingTextValue.trim() != '')) {
                    // Handle any icon that is used to depict the type of element defined in a list view.
                    let ancestor: object = driver.getAncestor(element, 3);
                    if (ancestor != null) {
                        let namePlateDiv: object = driver.findElementIn(ancestor, 'div.tile-namePlate');
                        if (namePlateDiv != null) {
                            let nameDiv: object = driver.findElementIn(namePlateDiv, 'div.tile-name');
                            if (nameDiv != null) {
                                let listItemName: string = nameDiv['innerText'];
                                customTextValue = listItemName.trim();

                                if ((listItemName != null) && (listItemName.trim() != '')) {
                                    if ((additionalElementInfo != null) && (additionalElementInfo.elementsSubtypeMap != null))
                                        elementSubtype = additionalElementInfo.elementsSubtypeMap[existingTextValue];
                                    else {
                                        elementSubtype = null;
                                    }
                                }
                            }
                        }
                    }
                } else {
                    //console.log('Need to handle another icon name ...');
                }

                additionalElementInfo.customTextValues.push(customTextValue);
                additionalElementInfo.elementSubtypeNames.push(elementSubtype);
            }
        }
    }

    protected getClickableListViewToolbarElementFunction = (element: object): object => {
        let result: object = null;

        if ((element['parentNode'] != null) && (element['parentNode']['parentNode'] != null))
            result = element['parentNode']['parentNode'];
        else
            result = element;

        return result;
    }

    // Error handling.
    protected throwError(error: string): void {
        throw error;
    }

    // Callback methods that can be overriden by derived classes.
    protected scriptableElementInitialized(scriptableElementObject: object): void {
        // Note:  this default implementation does nothing by design.
    }

    protected elementsInitialized(elementSelector: string, elements: object, elementTypeMetadata: ElementTypeMetadata) {
        let message: string = `elementsInitialized():  found ${elements['length']} ${elementTypeMetadata.htmlElementSelector}(s).`;
        this.logMessage(message);
    }

    protected initializationCompleted(): void {
        let message: string = `initializationCompleted():  status = '${this.InitializationStatus}'.`;
        this.logMessage(message);

        if (this.initializationStatus == ScriptableComponentStatus.InitializationFailed) {
            //let errorMessage: string = `Initialization failed for component '${this.tagName}'.`;
            //alert(errorMessage);
        }
    }

    public get InitializationStatus(): ScriptableComponentStatus {
        return this.initializationStatus;
    }
    protected set InitializationStatus(value: ScriptableComponentStatus) {
        this.initializationStatus = value;
    }

    public getMatInputWithElement(element: object): MatInput {
        let resultMatInput: MatInput = null;

        if ((this.matInputs != null) && (this.matInputElementRefs != null) &&
            (this.matInputs.length == this.matInputElementRefs.length)) {
            let matInputsArray: MatInput[] = this.matInputs.toArray();
            let matInputElementRefsArray: ElementRef[] = this.matInputElementRefs.toArray();

            if ((matInputsArray != null) && (matInputsArray.length > 0)) {
                for (let index: number = 0; index < matInputsArray.length; index++) {
                    let matInput: MatInput = matInputsArray[index];
                    let matInputElementRef: ElementRef = matInputElementRefsArray[index];

                    if (element == matInputElementRef.nativeElement) {
                        resultMatInput = matInput;

                        break;
                    }
                }
            }
        }

        return resultMatInput;
    }

    // Helper methods.
    private findScriptableElements(): void {
        let timeout = setInterval(() => {
            this.watchTimeoutInfo.timeoutsCompleted++;

            let remainingElementsToFind: ScriptableElementsToFind[] = this.scriptableElementsInfo.scriptableElementsToFind.filter(se => !se.elementsFound);
            let allElementsFound: boolean = this.handleFindElementsTimeout(remainingElementsToFind);

            if (this.initializationStatus == ScriptableComponentStatus.InitializationFailed) {
                this.handleInitializedCompleted(timeout, ScriptableComponentStatus.InitializationFailed);
            } else if (allElementsFound) {
                this.handleInitializedCompleted(timeout, ScriptableComponentStatus.InitializationComplete);
            } else if (this.watchTimeoutInfo.timeoutsCompleted >= this.watchTimeoutInfo.maxTimeoutsLookingForScriptableElements) {
                let canKeepLooking: boolean = true;

                for (let index: number = 0; index < remainingElementsToFind.length; index++) {
                    let remainingElementsInfo: ScriptableElementsToFind = remainingElementsToFind[index];

                    if ((remainingElementsInfo.additionalElementInfo != null) &&
                        (remainingElementsInfo.additionalElementInfo.allowZeroElements == true))
                    {
                        remainingElementsInfo.elementsFound = true; // Will get picked up on the next timeout.
                    } else if ((remainingElementsInfo.additionalElementInfo == null) ||
                        (remainingElementsInfo.additionalElementInfo.maxTimeoutsLookingForScriptableElements == null) ||
                        (this.watchTimeoutInfo.timeoutsCompleted >= remainingElementsInfo.additionalElementInfo.maxTimeoutsLookingForScriptableElements))
                    {
                        canKeepLooking = false;

                        let debugElements: any = null;
                        for (let index: number = 0; index < remainingElementsToFind.length; index++) {
                            let elementsToFind = remainingElementsToFind[index];
                            debugElements = this.jqueryBrowserDriverService.findElementsIn(elementsToFind.anElementSelector, elementsToFind.elementTypeMetadata.htmlElementSelector);
                        }
                         
                        break;
                    }
                }

                if (!canKeepLooking)
                    this.handleInitializedCompleted(timeout, ScriptableComponentStatus.InitializationFailed);
            }            
        }, this.watchTimeoutInfo.timeoutInMilliseconds);
    }

    private handleInitializedCompleted(timeout: any, status: ScriptableComponentStatus): void {
        clearInterval(timeout);
        this.initializationStatus = status; // ScriptableComponentStatus.InitializationFailed;
        this.initializationCompleted();
        this.notifyInitializationCompleted();
    }

    private handleFindElementsTimeout(remainingElementsToFind: ScriptableElementsToFind[]): boolean {
        let findElementsWorkCompleted: boolean = true; // Will set to false if we find this not to be true.

        for (let index: number = 0; index < remainingElementsToFind.length; index++) {
            let elementsToFind: ScriptableElementsToFind = remainingElementsToFind[index];

            elementsToFind.elements = this.jqueryBrowserDriverService.findElementsIn(elementsToFind.anElementSelector, elementsToFind.elementTypeMetadata.htmlElementSelector);
            if (this.canClearIntervalFor(elementsToFind.elements, elementsToFind.elementTypeMetadata)) {
                elementsToFind.elementsFound = true;

                this.processAnyAdditionalElementInfoFor(elementsToFind);

                this.elementsInitialized(elementsToFind.anElementSelector, elementsToFind.elements, elementsToFind.elementTypeMetadata);
            } else
                findElementsWorkCompleted = false;
        }

        return findElementsWorkCompleted;
    }

    private findAnyScriptableComponentMethods(listOfScriptableElements: ScriptableElementsToFind[]): void {
        //let allClassMethods: IComponentNameToMethodsMetadata = ComponentMethodsService.getAllComponentMethods();
        let myClassName: string = this.constructor.name;
        let componentMethodsMetadata: ComponentMethodsMetadata = ComponentMethodsService.getComponentMethodsMetadataFor(myClassName);

        if ((componentMethodsMetadata != null) && (componentMethodsMetadata.methodsMetadata.length > 0)) {
            let methodsElementTypeMetadata: ElementTypeMetadata = this.htmlElementTypeService.getMetadataForElementType(HtmlElementTypeNames.componentMethods_metadataKey);

            let methodsScriptableElements: ScriptableElementsToFind = new ScriptableElementsToFind('na', methodsElementTypeMetadata);
            methodsScriptableElements.elements = componentMethodsMetadata.methodsMetadata;
            methodsScriptableElements.elementsFound = true;

            listOfScriptableElements.push(methodsScriptableElements);
        }
    }

    private canClearIntervalFor(elements: object, typeMetadata: ElementTypeMetadata): boolean {
        let canClear: boolean = elements != null;

        if (typeMetadata.elementCountExpected == ElementCountExpected.Singular) {
            canClear = elements['length'] == 1;

            if ((!canClear) && (elements['length'] > 1))
                this.initializationStatus = ScriptableComponentStatus.InitializationFailed;
        } else if (typeMetadata.elementCountExpected == ElementCountExpected.Singular_or_Plural) {
            canClear = elements['length'] >= 1;
        } else if (typeMetadata.elementCountExpected == ElementCountExpected.Plural) {
            canClear = elements['length'] > 1;
        }

        return canClear;
    }

    private logMessage(message: string): void {
        if (this.componentHierarchyService.LogToConsole) {
            console.log(message);            
        }            
    }

    private setupSearchForElements(anElementSelector: string, metadataKey: string, optionalAdditionalElementInfo: AdditionalElementInfo = null): void {
        let elementTypesMetadata = this.htmlElementTypeService.getElementTypeMetadata();
        let elementTypeMetadata: ElementTypeMetadata = elementTypesMetadata.find(md => md.metadataKey == metadataKey);

        if (elementTypeMetadata == null)
            ScriptableBaseComponent.raiseException(`setupSearchForElements():  cannot find element type metadata for key '${metadataKey}'.`);

        let searchCriteria: ScriptableElementsToFind = new ScriptableElementsToFind(anElementSelector, elementTypeMetadata, optionalAdditionalElementInfo);
        this.scriptableElementsInfo.scriptableElementsToFind.push(searchCriteria);
    }

    private notifyInitializationCompleted(): void {
        this.componentHierarchyService.componentInitialized(this, this.InitializationStatus);
    }

    private getLastAdditionalElementInfoFor(anElementSelector: string): AdditionalElementInfo {
        // Note:  this method finds the LAST instance of AdditionalElementInfo for a given HTML selector.
        //
        //        This ensures that the method returns the one that the caller is presently configuring.
        //let elementsInfo: ScriptableElementsToFind = this.scriptableElementsInfo.scriptableElementsToFind.find(sei => sei.anElementSelector == anElementSelector);
        let listOfElementsInfo: ScriptableElementsToFind[] = this.scriptableElementsInfo.scriptableElementsToFind.filter(sei => sei.anElementSelector == anElementSelector);
        if ((listOfElementsInfo == null) || (listOfElementsInfo.length == 0))
            throw `ScriptableBaseComponent.getAdditionalElementInfoFor():  cannot find any search criteria for element select '${anElementSelector}'.`;
        let elementsInfo: ScriptableElementsToFind = listOfElementsInfo[listOfElementsInfo.length - 1];

        if (elementsInfo.additionalElementInfo == null)
            elementsInfo.additionalElementInfo = new AdditionalElementInfo({});

        return elementsInfo.additionalElementInfo;
    }

    private processAnyAdditionalElementInfoFor(elementsToFind: ScriptableElementsToFind): void {
        let testValue: string = null; // Use to test operations performed in this method.

        if (elementsToFind.additionalElementInfo != null) {
            let numElements: number = 0;
            if ((elementsToFind.elements != null) && (elementsToFind.elements['length'] != null))
                numElements = elementsToFind.elements['length'];

            // If provided, execute a pre-processing function.
            if ((elementsToFind.additionalElementInfo.customPreprocessingFunction != null) && (numElements > 0)) {
                elementsToFind.additionalElementInfo.customPreprocessingFunction(this.jqueryBrowserDriverService, elementsToFind.elements, elementsToFind.elementTypeMetadata, elementsToFind.additionalElementInfo);
            }

            // If provided, set the display name(s) assign to this element.
            if ((elementsToFind.additionalElementInfo.name != null) && (numElements > 0)) {
                this.jqueryBrowserDriverService.setDataAttributeValue(elementsToFind.elements[0], HtmlMetadataTagNames.elementName_dataAttribute, elementsToFind.additionalElementInfo.name);
                testValue = this.jqueryBrowserDriverService.getDataAttributeValue(elementsToFind.elements[0], HtmlMetadataTagNames.elementName_dataAttribute);
            }                

            if ((elementsToFind.additionalElementInfo.names != null) && (numElements > 0)) {
                let numNames: number = elementsToFind.additionalElementInfo.names.length;

                let nameIndex: number = 0;
                while ((nameIndex < numNames) && (nameIndex < numElements)) {
                    let name: string = elementsToFind.additionalElementInfo.names[nameIndex];
                    this.jqueryBrowserDriverService.setDataAttributeValue(elementsToFind.elements[0], HtmlMetadataTagNames.elementName_dataAttribute, name);
                    testValue = this.jqueryBrowserDriverService.getDataAttributeValue(elementsToFind.elements[0], HtmlMetadataTagNames.elementName_dataAttribute);

                    nameIndex++;
                }
            }                
        }
    }

    private static getMetadataForElementType(htmlElementTypeService: HtmlElementTypeService, metadataKey: string): ElementTypeMetadata {
        let elementTypesMetadata = htmlElementTypeService.getElementTypeMetadata();
        let elementTypeMetadata: ElementTypeMetadata = elementTypesMetadata.find(md => md.metadataKey == metadataKey);

        if (elementTypeMetadata == null)
            ScriptableBaseComponent.raiseException(`getMetadataForElementType():  cannot find element type metadata for key '${metadataKey}'.`);

        return elementTypeMetadata;
    }

    private static raiseException(errorMessage: string): void {
        throw errorMessage;
    }
}
