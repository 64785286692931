import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ColumnComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { State, toODataString } from "@progress/kendo-data-query";
import { plainToClass } from 'class-transformer';
import { BehaviorSubject, Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { environment } from '../../../environments/environment';
import { FormFieldProcessingPhaseEnum } from '../enums/form-field-processing-phase.enum';
import { IGridRow } from '../interfaces/grid-row.interface';
import { IFieldDefinitionLogic } from '../interfaces/ifield-definition-logic.interface';
import { FieldDefinition } from '../models/form-builder/field-definition.model';
import { FormField } from '../models/form-builder/form-field.model';
import { FormInstanceElement } from '../models/form-builder/form-instance-element.model';
import { GridRowViewModel } from '../models/form-builder/grid-row.model';
import { GridConfig } from '../models/grid/grid-config.model';
import { GridAllModesDataSource } from '../models/grid/grid-data-source.model';
import { GridFormInstanceElementWrapper } from '../models/grid/grid-form-instance-element-wrapper.model';
import { IGetCellDisplayValue } from '../models/grid/grid-interfaces';
import { GridRowDef } from '../models/grid/grid-row.model';
import { FieldDefinitionService } from './field-definition.service';
import { FlexibleSelectionFieldService } from './flexible-selection-field.service';

export const KENDO_DATA_GRID_ROW_KEY: string = '__gridRow';
export const GRID_COLUMN_ID_KEY: string = 'gridColumnId';

/*
    ============================================ IMPORTANT NOTE: ============================================ 
    This service propagates changes made in the UI to a user's _pending_ grid data on the server
    It does _not_ make changes to the saved, "live" data -- that is done when a FormInstance is saved, same as it has always been done 
*/

@Injectable({
    providedIn: 'root'
})
export class KendoGridService extends BehaviorSubject<GridDataResult>{
    // Properties.
    private url = environment.apiUrl;
    private endpoint: string = 'api/kendoGrid';
    private dataResult: GridDataResult;

    private loading = false;
    public get Loading() {
        return this.loading;
    }

    public constructor(private http: HttpClient, private fieldDefinitionService: FieldDefinitionService, private flexibleSelectionFieldService: FlexibleSelectionFieldService) {
        super(<GridDataResult>{ data: [], total: 0 })
    }

    // Methods.
    //public lastResult(): GridDataResult {
    //    return this.dataResult;
    //}

    public read(gridConfig: GridConfig, fieldDefinitionService: FieldDefinitionService, getCellDisplayValue: IGetCellDisplayValue, formInstanceElementId: number, gridState: State): void {
        this.loading = true;
        this.fetch("Get", gridConfig, fieldDefinitionService, getCellDisplayValue, formInstanceElementId, gridState)
            .pipe(
                tap((data) => {
                    this.dataResult = data;
                    this.loading = false;
                })
            )
            .subscribe((data) => {
                super.next(data);
            });
    }

    // PJH - added for VNEXT-1371 to allow the "Import Excel Data into Grid" dialog to get the pending edits it just created
    public readPending(gridConfig: GridConfig, fieldDefinitionService: FieldDefinitionService, getCellDisplayValue: IGetCellDisplayValue, formInstanceElementId: number, gridState: State): void {
        this.loading = true;
        this.fetch("GetPending", gridConfig, fieldDefinitionService, getCellDisplayValue, formInstanceElementId, gridState)
            .pipe(
                tap((data) => {
                    this.dataResult = data;
                    this.loading = false;
                })
            )
            .subscribe((data) => {
                super.next(data);
            });
    }

    // TODO: Use a param object rather than all these params...
    public addOrUpdateRow(
        formInstanceElementId: number,
        formInstanceId: number,
        formFieldId: number,
        selectedGridRow: GridRowDef,
        rowIndex: number,
        cols: any,
        dataItem: any,
        isNew: boolean,
        gridConfig: GridConfig,
        getCellDisplayValue: IGetCellDisplayValue,
        currentRowCount: number = 0)
    {
        let gridRowViewModel = this.BuildGridRowViewModel(formInstanceId, formInstanceElementId, formFieldId, selectedGridRow, rowIndex, cols, currentRowCount);
        return this.flexibleSelectionFieldService.validateGridRow(gridRowViewModel).then(validationFailures => {
            if (validationFailures.length > 0) {
                let msg = validationFailures.map(x => x.validationFailureMessage).join(", ");
                alert(msg);
            } else {
                if (isNew || selectedGridRow.DatabaseId == 0) {
                    return this.addRow(gridRowViewModel, gridConfig, getCellDisplayValue);
                } else {
                    return this.updateRow(gridRowViewModel, selectedGridRow, dataItem);
                }
            }
        });
        
    }

    public removeRow(gridRowDatabaseId: number) {
        let baseUrl = this.url.endsWith('/') ? this.url : `${this.url}/`;
        let url = `${baseUrl}${this.endpoint}/${gridRowDatabaseId}`;

        const findIndex = (dataItem) => { return dataItem.__gridRow.databaseId === gridRowDatabaseId };
        let indexToDelete = this.dataResult.data.findIndex(findIndex);

        return this.http.delete(url).toPromise().then(x => {
            this.dataResult.data.splice(indexToDelete, 1); // remove from the UI
        });
    }

    public addRow(gridRow: GridRowViewModel, gridConfig: GridConfig, getCellDisplayValue: IGetCellDisplayValue) {
        let baseUrl = this.url.endsWith('/') ? this.url : `${this.url}/`;
        let url = `${baseUrl}${this.endpoint}`;
        if (!this.dataResult) this.reset();

        return this.http.post(url, gridRow).toPromise().then(x => {
            let wrappedGridRow: object = this.getWrappedGridRow(x, gridConfig, this.fieldDefinitionService, getCellDisplayValue, [x])
            this.dataResult.data.push(wrappedGridRow);
            super.next(this.dataResult);
        });

    }

    public updateRow(gridRow: GridRowViewModel, selectedGridRow: GridRowDef, dataItem: any) {
        if (dataItem) {
            let row = dataItem['__gridRow'];
            if (row) {
                let id = dataItem['__gridRow'].databaseId

                gridRow.id = id;

                gridRow.id = id;

                let col = 0;
                // update the dataItem which is the object Kendo Grid is using to display the row
                for (let prop in gridRow.cellDataHash) {
                    dataItem[prop] = this.getElementValue(
                        gridRow.cellDataHash[prop] as FormInstanceElement,
                        selectedGridRow.GridConfig.ColumnDefs[col++],
                        row,
                        selectedGridRow.GridConfig.ColumnDefs
                    );
                }

                let baseUrl = this.url.endsWith('/') ? this.url : `${this.url}/`;
                let url = `${baseUrl}${this.endpoint}/${gridRow.id}`;
                return this.http.put(url, gridRow).toPromise().then(x => { });
            }
        }
        else {
            console.log("dataItem is null for updateRow in the kendo grid service");
        }
    }

    public repositionRow(formInstanceElementId: number, originalIndexOfMovedRow: number, movedRowId: number, originalIndexOfTargetRow: number, targetRowId: number, position: string) {
        let baseUrl = this.url.endsWith('/') ? this.url : `${this.url}/`;
        let url = `${baseUrl}${this.endpoint}/${formInstanceElementId}/reorderRows`;
        let payload = {
            originalIndexOfMovedRow: originalIndexOfMovedRow,
            movedRowId: movedRowId,
            originalIndexOfTargetRow: originalIndexOfTargetRow,
            targetRowId: targetRowId,
            position: position
        }

        return this.http.post(url, payload).toPromise().then(x => {
            // now update the data for the UI
            let uiIndexOfMovedRow = this.dataResult.data.findIndex((dataItem) => { return dataItem.__gridRow.databaseId === movedRowId });
            let uiIndexOfTargetRow = this.dataResult.data.findIndex((dataItem) => { return dataItem.__gridRow.databaseId === targetRowId });

            let newIndex = uiIndexOfTargetRow;
            let direction = uiIndexOfMovedRow > uiIndexOfTargetRow ? "up" : "down";
            if (direction == "down" && position == "before") newIndex--;
            else if (direction == "up" && position == "after") newIndex++;

            this.reorderRowsInKendoGridData(uiIndexOfMovedRow, newIndex);

            super.next(this.dataResult);
        });
    }

    private reorderRowsInKendoGridData(uiIndexOfMovedRow: number, newIndex: number) {
        let movedRow = this.dataResult.data.splice(uiIndexOfMovedRow, 1)[0]; // first remove the moved Row
        let before = this.dataResult.data.slice(0, newIndex); // grab everything before its new location in the array
        let after = this.dataResult.data.slice(newIndex); // grab everything after its new location in the array

        this.dataResult.data = [
            ...before,
            movedRow,
            ...after
        ];
    }

    public resetItem(dataItem: object): void {
        if (!dataItem) {
            return;
        }

        // find orignal data item
        const originalDataItem = this.dataResult.data.find(
            (item) => item['id'] === dataItem['id']
        );

        // revert changes
        Object.assign(originalDataItem, dataItem);

        super.next(this.dataResult);
    }

    private getElementValue(el: FormInstanceElement, formField, gridRow: IGridRow, gridRowColumnDefs: FormField[]): any {
        let fieldDefClientLogic: IFieldDefinitionLogic = this.fieldDefinitionService.getFieldClientLogicHandler(formField.fieldDefinitionClassName);
        return fieldDefClientLogic.getDisplayValue(formField, el, gridRow, null, gridRowColumnDefs);
    }

    private BuildGridRowViewModel(formInstanceId: number, formInstanceElementId: number, formFieldId: number, selectedGridRow: GridRowDef, rowIndex: number, cols: ColumnComponent[], currentRowCount: number): GridRowViewModel {
        let gridRowViewModel = new GridRowViewModel();
        gridRowViewModel.formInstanceElementId = formInstanceElementId;
        gridRowViewModel.formInstanceId = formInstanceId;
        gridRowViewModel.formFieldId = formFieldId;
        gridRowViewModel.id = selectedGridRow.DatabaseId;
        gridRowViewModel.rowIndex = currentRowCount > 0 ? currentRowCount : rowIndex; // for VNEXT-1371, new  rows need to set their rowIndex based on count of rows already in the grid
        gridRowViewModel.cellDataHash = {};

        let columnHeaders = [];
        for (const col of cols) {
            if (col.field)
                columnHeaders.push(col.field);
        }
        let wrappers: GridFormInstanceElementWrapper[] = selectedGridRow.FormInstanceElementWrappers;
        for (let i = 0; i < wrappers.length; i++) {
            let wrapper = wrappers[i];
            let el = wrappers[i].formInstanceElement;
            gridRowViewModel.cellDataHash[wrapper.fieldName] = el;
        }
        return gridRowViewModel;
    }

    private reset() {
        this.dataResult = <GridDataResult>{ data: [], total: 0 };
    }

    public get DataResult(): GridDataResult {
        return this.dataResult;
    }

    private fetch(
        method: string, // Get or GetPending -- this is the api endpoint method
        gridConfig: GridConfig,
        fieldDefinitionService: FieldDefinitionService,
        getCellDisplayValue: IGetCellDisplayValue,
        formInstanceElementId: number,
        gridState: State,
        action = "",
        data?: object[]): Observable<GridDataResult> {
        let qs = toODataString(gridState);
        qs = qs.replace(/\$/g, '');
        let baseUrl = this.url.endsWith('/') ? this.url : `${this.url}/`;
        let url = `${baseUrl}${this.endpoint}/${method}/${formInstanceElementId}`;

        return <Observable<GridDataResult>><unknown>this.http.post<GridDataResult>(url, gridState)
            .pipe(
                map((res) => {
                    let wrappedGridRows: object[] = [];
                    if ((res != null) && (res.data.length > 0)) {
                        for (let index: number = 0; index < res.data.length; index++) {
                            let untypedViewModel: object = res.data[index];
                            let wrappedGridRow: object = this.getWrappedGridRow(untypedViewModel, gridConfig, fieldDefinitionService, getCellDisplayValue, res.data);

                            wrappedGridRows.push(wrappedGridRow);
                        }
                    }
                    return <GridDataResult>{
                        data: wrappedGridRows,
                        total: res.total
                    }
                })
            );
    }

    private getWrappedGridRow(untypedViewModel: object, gridConfig: GridConfig, fieldDefinitionService: FieldDefinitionService, getCellDisplayValue: IGetCellDisplayValue, res: object[]) {
        let typedViewModel: GridRowViewModel = plainToClass(GridRowViewModel, untypedViewModel);
        let gridRow: GridRowDef = this.unpackGridRowFromViewModel(gridConfig, fieldDefinitionService, getCellDisplayValue, typedViewModel, res.length);
        let wrappedGridRow: object = this.wrapGridRowAsObject(gridConfig, gridRow, fieldDefinitionService);
        return wrappedGridRow;
    }

    private serializeModels(data?: object[]): string {
        return data ? `&models=${JSON.stringify([data])}` : "";
    }

    private unpackGridRowFromViewModel(gridConfig: GridConfig, fieldDefinitionService: FieldDefinitionService, getCellDisplayValue: IGetCellDisplayValue, gridRowVM: GridRowViewModel, totalGridRows: number): GridRowDef {
        let gridRow: GridRowDef = GridAllModesDataSource.createGridRowFromGridViewModelObect(gridConfig, fieldDefinitionService, getCellDisplayValue, gridRowVM, 0, totalGridRows);

        return gridRow;
    }

    private wrapGridRowAsObject(gridConfig: GridConfig, gridRow: GridRowDef, fieldDefinitionService: FieldDefinitionService): object {
        let resultObject: object = {};

        resultObject[KENDO_DATA_GRID_ROW_KEY] = gridRow;

        let colDefs: FormField[] = gridConfig.ColumnDefs;
        for (let index: number = 0; index < colDefs.length; index++) {
            let colDef: FormField = colDefs[index];

            let cellValue: string = ''; // Default for now.
            let formInstanceElement: FormInstanceElement = gridRow.getFormInstanceElement(colDef);
            if (formInstanceElement != null) {
                let fieldDefinition: FieldDefinition = fieldDefinitionService.getFieldDefinition(colDef.fieldDefinitionClassName);
                let fieldLogic: IFieldDefinitionLogic = fieldDefinition.customLogicHandler;
                cellValue = fieldLogic.getDisplayValue(colDef, formInstanceElement, gridRow, FormFieldProcessingPhaseEnum.LOADING_DATA, colDefs);
                // EXPERIMENTAL
                //console.log('==== DONE WITH getDisplayValue()');
                //console.log(formInstanceElement);
                //if (colDef.fieldDefinitionClassName == FormulaFieldType) {
                //    console.log("       ==> SETTING formInstanceElement");
                //    formInstanceElement.valueType = FormInstanceElementValueTypeEnum.TypeDouble;
                //    formInstanceElement.doubleValue = parseFloat(cellValue);
                //    console.log(formInstanceElement);
                //}
            }
            let name = colDef.name;
            resultObject[name] = cellValue;
        }
        return resultObject;
    }
}

/*
@Injectable({
    providedIn: 'root'
})
export class KendoGridServiceV1 extends BehaviorSubject<GridDataResult> {
    // Properties.
    private endpoint: string = 'kendoGrid';
    private url = environment.apiUrl;

    constructor(private http: HttpClient, private progressIndicatorService: ProgressIndicatorService) {
        //super(http, progressIndicatorService, environment.apiUrl, 'formInstance', FormInstance)
        super(null);
    }

    
    public query(state: State, formInstanceElementId: number): Observable<GridDataResult> {
        let url = `${this.url}/${this.endpoint}/${formInstanceElementId}`;

        return <Observable<GridDataResult>>this.http.get(url).pipe(
            map(
                (response) => {
                    let gridRows = <GridRowDef[]><unknown>response;

                    return <GridDataResult>{
                        data: gridRows,
                        total: gridRows != null ? gridRows.length : 0
                    }
                }
            ),
            tap(() => { })
        );
    }       

    // Protected methods.
    public handleError(error: Response | any): Promise<any> {
        Logging.log(error);
        if (this != null) {
            //this.hideProgressIndicator();
        }
        return Promise.reject(error.message || error);
    }

}
*/
