import { GridConfig } from './grid-config.model';
import { INumericValuesHashByFieldName, IGridRow, IValuesByFieldName } from '../../interfaces/grid-row.interface';
import { GridFormInstanceElementWrapper } from './grid-form-instance-element-wrapper.model';
import { IFormFieldNameToFormInstanceElementWrapper, IGetCellDisplayValue } from './grid-interfaces';
import { FormField } from '../form-builder/form-field.model';
import { FormInstanceElement } from '../form-builder/form-instance-element.model';
import { FormFieldProcessingPhaseEnum } from '../../enums/form-field-processing-phase.enum';
import { FieldDefinition } from '../form-builder/field-definition.model';
import { FieldDefinitionService } from '../../services/field-definition.service';

// Define constants used below.
export const GRID_ROW_ID_KEY: string = 'gridRowId';
export const GRID_COLUMN_ID_KEY: string = 'gridColumnId';

export class GridRowDef implements IGridRow {
    // Instance data.
    private rowSelectedStyle: string;
    private rowUnselectedStyle: string;

    private clientId: number = -1;
    private databaseId: number = 0;
    private isPendingEditFor_DatabaseId: number = 0;

    private iRowIndex: number = -1;

    private isSelected: boolean = false;
    private isExpanded: boolean = false;
    private isInvalid: boolean = false;

    private gridConfig: GridConfig = null;
    private arrFormInstanceElementWrappers: GridFormInstanceElementWrapper[] = null;
    private hshFormInstanceElementsByName: IFormFieldNameToFormInstanceElementWrapper = {};

    // Define a property that is available to users of this class, as a convenience, but is not used by this class.
    public activeFormInstanceElementWrappers: GridFormInstanceElementWrapper[] = null;

    // Constructor.
    public constructor(gridConfigParam: GridConfig,
        iClientIdParam: number,
        iRowIndexParam: number,
        iDatabaseIdParam: number,
        isPendingEditFor_DatabaseId: number,
        igetDisplayValue: IGetCellDisplayValue = null
    ) { // 10-02-2020:  added this second, optional parameter.
        this.gridConfig = gridConfigParam;

        this.clientId = iClientIdParam;
        this.iRowIndex = iRowIndexParam;
        this.databaseId = iDatabaseIdParam;
        this.isPendingEditFor_DatabaseId = isPendingEditFor_DatabaseId;

        let columnDefs: FormField[] = this.gridConfig.ColumnDefs;
        //console.log('GridRow.constructor()');
        //console.log(columnDefs);

        let bHasCalculatedField: boolean = false;
        let aiCalculatedColumnIndexes: number[] = [];
        this.arrFormInstanceElementWrappers = [];

        for (let iColumnDef: number = 0; iColumnDef < columnDefs.length; iColumnDef++) {
            let columnDef: FormField = columnDefs[iColumnDef];

            // Create the form instance element.
            let formInstanceElement: FormInstanceElement = new FormInstanceElement();

            // Create the form instance element's transient values.
            formInstanceElement.transientValuesHash = {};
            formInstanceElement.transientValuesHash[GRID_ROW_ID_KEY] = this.ClientId

            // Create the form instance element wrapper.
            let formInstanceElementWrapper: GridFormInstanceElementWrapper =
                new GridFormInstanceElementWrapper(formInstanceElement, iColumnDef, columnDef);


            if (igetDisplayValue) { // 10-02-2020:  added this if block.
                let bIsCalculatedField: boolean =
                    igetDisplayValue.isCalculatedField(iColumnDef, columnDef);

                if (!bIsCalculatedField) {
                    // Get this field's value now.
                    formInstanceElementWrapper.standinDisplayValue =
                        igetDisplayValue.getCellDisplayValue(
                            iColumnDef,
                            columnDef,
                            formInstanceElementWrapper,
                            this,
                            FormFieldProcessingPhaseEnum.CREATING_DATA); // Is this correct???
                } else {
                    bHasCalculatedField = true; // Will get these values in a second pass.
                    aiCalculatedColumnIndexes.push(iColumnDef);
                }
            }

            this.arrFormInstanceElementWrappers.push(formInstanceElementWrapper);

            this.hshFormInstanceElementsByName[columnDef.name] = formInstanceElementWrapper;
        }

        // Do we have any calculated fields; that is, do
        // we require a second pass through the columns ?
        if (bHasCalculatedField) {
            for (let iCalcColIndex: number = 0;
                iCalcColIndex < aiCalculatedColumnIndexes.length;
                iCalcColIndex++) {
                let iColIndex: number = aiCalculatedColumnIndexes[iCalcColIndex];

                let columnDef: FormField = columnDefs[iColIndex];
                let fieWrapper: GridFormInstanceElementWrapper = this.arrFormInstanceElementWrappers[iColIndex]

                // 12-07-2022 note:  made changes to the following line of code after replacing all instances of 'var' with 'let',
                //                   as the use of 'let' showed that the code was referencing a value that was out of ordinary scope.
                //formInstanceElementWrapper.standinDisplayValue =
                fieWrapper.standinDisplayValue =
                    igetDisplayValue.getCellDisplayValue(iColIndex,
                        columnDef,
                        //formInstanceElementWrapper,
                        fieWrapper,
                        this,
                        FormFieldProcessingPhaseEnum.LOADING_DATA); // Is this correct???
            } // for
        } // if

        // Done.
        return;
    }

    // FOR KENDO
    public get GridConfig(){
        return this.gridConfig;
    }

    // Implement interface IGridRow.
    public getNumericValuesHashByFieldName(fieldDefinitionService: FieldDefinitionService): INumericValuesHashByFieldName {
        let hshNumericValues: INumericValuesHashByFieldName = {};

        for (let iCol: number = 0; iCol < this.gridConfig.ColumnDefs.length; iCol++) {
            //for (let iCol: number = 0; iCol < this.gridConfig.ColumnDefsWithVirtualFormFields.length; iCol++) {
            let colDef: FormField = this.gridConfig.getColumnDef(iCol);
            let fieWrapper: GridFormInstanceElementWrapper = (iCol < this.arrFormInstanceElementWrappers.length ? this.arrFormInstanceElementWrappers[iCol] : null);

            let fieldDefinition: FieldDefinition = fieldDefinitionService.getFieldDefinition(colDef.fieldDefinitionClassName);

            //if (fieWrapper && colDef.IsNumeric) {
            if ((fieWrapper != null) && fieldDefinition.isNumeric) {
                //let fieValueName: string = colDef.FormInstanceElementPropName;
                let fieValueName: string = fieldDefinition.formInstanceElementPropertyName;
                let numericValue: number = fieWrapper.formInstanceElement[fieValueName];

                if ((numericValue === undefined) || (numericValue === null)) {
                    numericValue = 0;
                }

                hshNumericValues[colDef.name] = numericValue;

                hshNumericValues[`col${iCol + 1}`] = numericValue;

                if (colDef.displayName) {
                    let displayVariableName: string = GridRowDef.displayNameToVariableName(colDef);

                    hshNumericValues[displayVariableName] = numericValue;
                }
            }
        }

        return (hshNumericValues);
    }

    // Added for VNEXT-1419 - does same thing as getNumericValuesHashByFieldName() but for all values
    // not just numeric (perhaps this could be combined into a single method which takes a "numericOnly" flag)
    public getValuesByFieldName(fieldDefinitionService: FieldDefinitionService): IValuesByFieldName {
        let values: IValuesByFieldName = {};

        for (let iCol: number = 0; iCol < this.gridConfig.ColumnDefs.length; iCol++) {
            let colDef: FormField = this.gridConfig.getColumnDef(iCol);
            let fieWrapper: GridFormInstanceElementWrapper = (iCol < this.arrFormInstanceElementWrappers.length ? this.arrFormInstanceElementWrappers[iCol] : null);

            let fieldDefinition: FieldDefinition = fieldDefinitionService.getFieldDefinition(colDef.fieldDefinitionClassName);

            if (fieWrapper != null) {
                let fieValueName: string = fieldDefinition.formInstanceElementPropertyName;
                let value: any = fieWrapper.formInstanceElement[fieValueName];

                if (!value) {
                    value = null;
                }

                values[colDef.name] = value;

                values[`col${iCol + 1}`] = value;

                if (colDef.displayName) {
                    let displayVariableName: string = GridRowDef.displayNameToVariableName(colDef);

                    values[displayVariableName] = value;
                }
            }
        }

        return values;
    }

    private static displayNameToVariableName(formFieldParam: FormField): string {
        let variableName: string = formFieldParam.displayName.toLowerCase().replace(/ /g, '_');

        return (variableName);
    }

    // Getter methods.
    public get HshFormInstanceElementsByName(): IFormFieldNameToFormInstanceElementWrapper {
        return this.hshFormInstanceElementsByName;
    }
    public get RowSelectedStyle(): string {
        return this.rowSelectedStyle;
    }

    public get RowUnselectedStyle(): string {
        return this.rowUnselectedStyle;
    }

    public get ClientId(): number {
        return (this.clientId);
    }

    public get DatabaseId(): number {
        return (this.databaseId);
    }

    public get IsPendingEditFor_DatabaseId(): number {
        return this.isPendingEditFor_DatabaseId;
    }

    public get RowIndex(): number {
        return (this.iRowIndex);
    }

    public set RowIndex(iRowIndexParam: number) {
        this.iRowIndex = iRowIndexParam;

        return;
    }

    public get IsSelected(): boolean {
        return (this.isSelected);
    }

    public get IsExpanded(): boolean {
        return this.isExpanded;
    }
    public set IsExpanded(value: boolean) {
        this.isExpanded = value;
    }

    public get IsInvalid(): boolean {
        return (this.isInvalid);
    }

    public get FormInstanceElementWrappers(): GridFormInstanceElementWrapper[] {
        return (this.arrFormInstanceElementWrappers);
    }

    public set FormInstanceElementWrappers(value: GridFormInstanceElementWrapper[]) {
        this.arrFormInstanceElementWrappers = value;
    }

    // Setter methods.
    public set RowSelectedStyle(style: string) {
        this.rowSelectedStyle = style;
    }

    public set RowUnselectedStyle(style: string) {
        this.rowUnselectedStyle = style;
    }

    public set ClientId(clientIdParam: number) {
        this.clientId = clientIdParam;
    }

    public set DatabaseId(databaseIdParam: number) {
        this.databaseId = databaseIdParam;
    }

    public set IsSelected(isSelectedParam: boolean) {
        this.isSelected = isSelectedParam;
    }

    public set IsInvalid(isInvalidParam: boolean) {
        this.isInvalid = isInvalidParam;
    }

    // Accessor methods.
    public getFormFieldByColumnName(strCellName: string): FormField {
        let formField: FormField = this.gridConfig.getColumnDefByName(strCellName);

        return (formField);
    }

    public getFormInstanceElement(gridColumnDef: FormField): FormInstanceElement {
        let formInstanceElementWrapper: GridFormInstanceElementWrapper =
            this.hshFormInstanceElementsByName[gridColumnDef.name];

        return (formInstanceElementWrapper ? formInstanceElementWrapper.formInstanceElement : null);
    }

    public getFormInstanceElementWrapper(gridColumnDef: FormField): GridFormInstanceElementWrapper {
        let formInstanceElementWrapper: GridFormInstanceElementWrapper = this.hshFormInstanceElementsByName[gridColumnDef.name];

        return (formInstanceElementWrapper);
    }

    public getFormInstanceElementWrapperByCellName(cellName: string): GridFormInstanceElementWrapper {
        let formInstanceElementWrapper: GridFormInstanceElementWrapper =
            this.hshFormInstanceElementsByName[cellName];

        return (formInstanceElementWrapper);
    }

    public getTotalColumnCount(): number {
        return this.gridConfig.ColumnDefs.length + 1; // plus one is for the actions columns
        //return this.gridConfig.ColumnDefsWithVirtualFormFields.length + 1;
    }
}
