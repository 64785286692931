import { ErrorHandler, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';

// Material Design Modules
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MatProgressBarModule } from '@angular/material/progress-bar';
//import { MatPaginatorModule } from '@angular/material/paginator';

import {
    DateAdapter,
    MAT_DATE_FORMATS,
    MatNativeDateModule,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
//import { MatMomentDateModule } from '@angular/material-moment-adapter';
//import { MatStepperModule } from '@angular/material/stepper';
//import { DemoMaterialModule } from './material-module';
//import { TreeFlatOverviewExample } from './app/tree-flat-overview-example';
//import { MatTreeModule } from '@angular/material/tree';
import { ContextMenuModule } from 'primeng/contextmenu';
import { TreeModule } from 'primeng/tree';

import { CurrentUserService } from './security/current-user.service';
import { CommunicationService } from './shared/services/communication.service';
import { NavigationService } from './shared/services/navigation.service';
//import { ClipboardService } from './shared/services/clipboard.service';
import { CasService } from "./security/cas.service";
import { HttpRequestInterceptor } from './shared/http-request-interceptor';

import { BrowserCheckComponent } from "./security/browser-check/browser-check.component";
import { LogInOutComponent } from "./security/loginout/loginout.component";
import { PageNotFoundComponent } from './shared/components/page-not-found.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { SharedModule } from './modules/shared.module';
import { LoadingSpinnerComponent } from './shared/components/loading-spinner/loading-spinner.component';
import { SideNavContainerComponent } from './shared/components/side-nav-container/side-nav-container.component';
import { AlertDialogComponent } from './shared/dialogs/alert/alert-dialog.component';
import { CollectionDetailDialog } from './shared/dialogs/collection-detail/collection-detail.dialog';
import { ConfirmationDialogComponent } from './shared/dialogs/confirmation/confirmation-dialog.component';
import { GridPasteKendoDialog } from './shared/dialogs/grid-paste-kendo/grid-paste-kendo.dialog';
import { GridPasteDialog } from './shared/dialogs/grid-paste/grid-paste.dialog';
import { NewFolderDialog } from './shared/dialogs/new-folder/new-folder.dialog';
import { RoleMembershipDialog } from './shared/dialogs/role-membership/role-membership.dialog';
import { LoaderIntercepter } from './shared/loader-intercepter';
import { DataCollectionResolver } from './shared/resolvers/data-collection.resolver';
import { FormInstanceResolver } from './shared/resolvers/form-instance.resolver';
import { MenuResolver } from './shared/resolvers/menu.resolver';
import { SiteResponseManagementResolver } from './shared/resolvers/site-response-management.resolver';
import { AdminService } from './shared/services/admin.service';
import { DataCollectionService } from './shared/services/data-collection.service';
import { FolderService } from './shared/services/folder.service';
import { FormInstanceService } from './shared/services/form-instance.service';
import { LoaderService } from './shared/services/loader.service';
import { MenuService } from './shared/services/menu.service';
import { SiteResponseManagementService } from './shared/services/site-response-management.service';
import { AdminComponent } from './views/admin/admin.component';
import { ErrorComponent } from './views/error/error.component';
import { HomeComponent } from './views/home/home.component';
import { HttpErrorComponent } from './views/http-error/http-error.component';
import { LandingPageComponent } from './views/landing-page/landing-page.component';
import { SiteComponent } from './views/site/site.component';
//import { FormulaFormFieldComponent } from './shared/components/form-fields/formula-form-field/formula-form-field.component';
import { NewFormInstanceDialog } from './shared/dialogs/new-form-instance/new-form-instance.dialog';
import { TestConfigurationService } from './shared/services/test-configuration.service';
//import { WorkflowsComponent } from './views/site/workflows/workflows.component';
import { WorkflowResolver } from './shared/resolvers/workflow.resolver';
import { KeepAliveService } from './shared/services/keep-alive.service';
import { WorkflowService } from './shared/services/workflow.service';
//import { FolderTreeResolver } from './shared/resolvers/folder-tree.resolver';
import { ClaimGuard } from './route-guards/claim.route-guard';
import { ProgressIndicatorComponent } from './shared/components/progress-indicator/progress-indicator.component';
import { RoleDetailsDialog } from './shared/dialogs/role-details/role-details.dialog';
import { GlobalErrorHandler } from './shared/global-error-handler';
import { ClaimResolver } from './shared/resolvers/claim.resolver';
import { AsyncJobService } from './shared/services/async-job.service';
import { AttachmentService } from './shared/services/attachment.service';
import { BrowserStorageService } from './shared/services/browserstorage.service';
import { ClaimService } from './shared/services/claim.service';
import { ProgressIndicatorService } from './shared/services/progress-indicator.service';
import { SettingsService } from './shared/services/settings.service';
//import { FileUploaderComponent } from './shared/components/file-uploader/file-uploader.component';
import { FileUploadDialogComponent } from './shared/dialogs/file-upload/file-upload-dialog.component';
import { SelectOptionsDialog } from './shared/dialogs/select-options/select-options.dialog';
//import { PermissionsDialog } from './shared/dialogs/permissions/permissions.dialog'; 
import { UnsavedChangesGuard } from './route-guards/unsaved-changes.route-guard';
import { CopySiteDialog } from './shared/dialogs/copy-site/copy-site.dialog';
import { ExportSiteDialog } from './shared/dialogs/export-site/export-site.dialog';
import { ImportSiteDialog } from './shared/dialogs/import-site/import-site.dialog';
import { DragDropFilesDirective } from './shared/directives/drag-and-drop-files.directive';
import { CopySiteService } from './shared/services/copy-site.service';
import { DeleteOnConfirmDialogService } from './shared/services/delete-on-confirm-dialog.service';
import { ExcelExportService } from './shared/services/excel-export.service';
import { ExcelImportService } from './shared/services/excel-import.service';
import { ExportDataService } from './shared/services/export-data.service';
import { ExportMetadataService } from './shared/services/export-metadata.service';
import { FormBuilderService } from './shared/services/form-builder.service';
import { ImportDataService } from './shared/services/import-data.service';
import { ImportMetadataService } from './shared/services/import-metadata.service';
import { PermissionsPreviewService } from './shared/services/permissions-preview.service';
 
import { CUSTOM_DATE_FORMATS, CustomDatePickerAdapter } from './shared/adapters/date-adapter';
import { CopySiteAndTransformComponent } from './shared/components/copy-site-and-transform/copy-site-and-transform.component';
import { NotificationCenterComponent } from './shared/components/notification-center/notification-center.component';
import { ConfigureRolePermissionsDialog } from './shared/dialogs/configure-role-permissions/configure-role-permissions.dialog';
import { CopyFormInstanceDialog } from './shared/dialogs/copy-form-instance/copy-form-instance.dialog';
import { CopyFormDialog } from './shared/dialogs/copy-form/copy-form.dialog';
import { MoveListItemDialog } from './shared/dialogs/move-list-item/move-list-item.dialog';
import { SubscribeToWorkflowTransitionDialog } from './shared/dialogs/subscribe/subscribe-to-workflow-transition/subscribe-to-workflow-transition.dialog';
import { VnextReleaseFeaturesDialog } from './shared/dialogs/vnext-release-features/vnext-release-features.dialog';
import { WorkflowTransitionsComponent } from './shared/dialogs/workflow-transitions/workflow-transitions.component';
import { DragDropService } from './shared/services/drag-drop.service';
import { EventSubscriptionService } from './shared/services/event-subscription.service';
//import { FieldDefinitionService } from './shared/services/field-definition.service';
import { FormFieldTypeAndNameService } from './shared/services/form-field-type-and-name.service';
import { GeocodeService } from './shared/services/geocode.service';
import { NotificationService } from './shared/services/notification.service';
import { AttributionsComponent } from './views/attributions/attributions.component';
import { DocumentPublishingComponent } from './views/site/document-publishing/document-publishing.component';
//import { ScriptableBaseComponent } from './shared/components/scriptable-base/scriptable-base.component';
import { SelectedComponentInfoComponent } from './shared/components/selected-component-info/selected-component-info.component';
import { SelectedNodeElementComponent } from './shared/components/selected-node-element/selected-node-element.component';
import { RouterLinkComponent } from './shared/components/wrappers/router-link/router-link.component';
import { EditValueDialog } from './shared/dialogs/edit-value/edit-value.dialog';
import { ComponentHierarchyService } from './shared/services/component-hierarchy.service';
import { HtmlElementTypeService } from './shared/services/html-element-type.service';
//import { ComponentHierarchyComponent } from './shared/components/component-hierarchy/component-hierarchy.component';
import { BulkWorkflowDialog } from './shared/dialogs/bulk-workflow/bulk-workflow.dialog';
import { UITestCenterDialog } from './shared/dialogs/ui-test-center/ui-test-center.dialog';
//import { SelectedServiceInfoComponent } from './shared/components/selected-service-info/selected-service-info.component';
//import { SelectedServiceMethodComponent } from './shared/components/selected-service-method/selected-service-method.component';
import { GridModule } from '@progress/kendo-angular-grid';
import { CrossSiteUserFavoriteFormsComponent } from './shared/components/cross-site-user-favorite-forms/cross-site-user-favorite-forms.component';
import { SiteGridComponent } from './shared/components/site-grid/site-grid.component';
import { EditInputParametersDialog } from './shared/dialogs/edit-input-parameters/edit-input-parameters.dialog';
import { CrossSiteUserFavoriteFormsRemoteBindingDirective } from './shared/directives/cross-site-user-favorite-forms.directive';
import { SiteGridRemoteBindingDirective } from './shared/directives/site-grid-remote-binding.directive';
import { ExcelTemplateService } from './shared/services/excel-template.service';
import { RoleService } from './shared/services/role.service';
import { SiteETLService } from './shared/services/site-etl.service';
import { SiteIconService } from './shared/services/site-icon.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GridPresetFiltersDialog } from './shared/dialogs/grid-preset-filters/grid-preset-filters-dialog.component';
 
//import { GridModule } from '@progress/kendo-angular-grid';

export let AppInjector: Injector;

@NgModule({
    imports: [
        GridModule,
        SharedModule,
        BrowserModule,
        BrowserAnimationsModule,
        MatTooltipModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        MatProgressBarModule,
        //MatPaginatorModule, 
        MatDatepickerModule,
        MatNativeDateModule,
        TreeModule,
        ContextMenuModule,
        //GridModule,
        //MatStepperModule
        //MatMomentDateModule
        //DemoMaterialModule
        //MatTreeModule 
    ],
    //entryComponents: [TreeFlatOverviewExample],
    declarations: [
        // System Components
        AppComponent,
        LogInOutComponent,
        BrowserCheckComponent,
        PageNotFoundComponent,
        //TreeFlatOverviewExample,
        // Views
        HomeComponent,
        ErrorComponent,
        AdminComponent,
        SiteComponent,
        // Shared
        SideNavContainerComponent,
        LandingPageComponent,
        CollectionDetailDialog,
        NewFolderDialog,
        LoadingSpinnerComponent,
        ConfirmationDialogComponent,
        AlertDialogComponent,
        GridPasteDialog,
        GridPasteKendoDialog,
        GridPresetFiltersDialog,
        HttpErrorComponent,
        RoleMembershipDialog,
        NewFormInstanceDialog,
        RoleDetailsDialog,
        ProgressIndicatorComponent,
        FileUploadDialogComponent,
        SelectOptionsDialog,
        CopySiteDialog,
        ExportSiteDialog,
        ImportSiteDialog,
        DragDropFilesDirective,
        ConfigureRolePermissionsDialog,
        CopyFormDialog,
        CopyFormInstanceDialog,
        CopySiteAndTransformComponent,
        AttributionsComponent,
        DocumentPublishingComponent,
        MoveListItemDialog,
        WorkflowTransitionsComponent,
        //TEAMS-192: KLW - Reference to Workflow Transitions component
        VnextReleaseFeaturesDialog,
        NotificationCenterComponent,
        SubscribeToWorkflowTransitionDialog,
        RouterLinkComponent,
        EditValueDialog,
        SelectedNodeElementComponent,
        SelectedComponentInfoComponent,
        //ComponentHierarchyComponent,
        UITestCenterDialog,
        BulkWorkflowDialog,
        EditInputParametersDialog,
        SiteGridComponent,
        SiteGridRemoteBindingDirective,
        CrossSiteUserFavoriteFormsComponent,
        CrossSiteUserFavoriteFormsRemoteBindingDirective
    ],
    providers: [   
        //FieldDefinitionService, //Kevin - causing ?undefined? error
        { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { width: '500px', autoFocus: true, hasBackdrop: true } },
        // see https://weblog.west-wind.com/posts/2019/Apr/07/Creating-a-custom-HttpInterceptor-to-handle-withCredentials
        { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
        LoaderService,
        //FieldDefinitionService,
        KeepAliveService,
        AsyncJobService,
        { provide: HTTP_INTERCEPTORS, useClass: LoaderIntercepter, multi: true },
        CurrentUserService,
        CasService,
        AdminService,
        MenuService,
        ClaimService,
        CommunicationService,
        NavigationService,
        DataCollectionService,
        FolderService,
        DataCollectionResolver,
        MenuResolver,
        ClaimResolver,
        ClaimGuard, //VNEXT-1430: KLW - Due to changes from Angular 14 to 15, extra commas in the module definitions are no longer tolerated
        UnsavedChangesGuard,
        FormInstanceResolver,
        FormInstanceService,
        SiteResponseManagementService,
        SiteResponseManagementResolver,
        TestConfigurationService,
        WorkflowService,
        WorkflowResolver,
        //FolderTreeResolver,
        SettingsService,
        BrowserStorageService,
        ProgressIndicatorService,
        AttachmentService,
        DeleteOnConfirmDialogService,
        CopySiteService,
        PermissionsPreviewService,
        DragDropService,
        ImportMetadataService,
        ExportMetadataService,
        ImportDataService,
        ExportDataService,
        ExcelExportService,
        // ExcelImportService,
        // FormBuilderService, // Note:  defining FormBuilderService here to make sure it is implemented as a singleton.
        // FieldDefinitionService, //Kevin - causing ?undefined? error
        GeocodeService,
        FormFieldTypeAndNameService,
        NotificationService,
        EventSubscriptionService,
        ComponentHierarchyService,
        HtmlElementTypeService,
        ExcelTemplateService,
        SiteIconService,
        SiteETLService,
        RoleService,
        ExcelImportService,
        FormBuilderService, 
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        { provide: DateAdapter, useClass: CustomDatePickerAdapter },
        { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS }
    ],
    //bootstrap: [TreeFlatOverviewExample]
    bootstrap: [AppComponent]
})
//export let AppInjector: Injector;
export class AppModule {
    constructor(private injector: Injector) {
        AppInjector = this.injector;
    }
}
