import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Clipboard } from '@angular/cdk/clipboard';

import { AsyncJobService } from '../../services/async-job.service';
import { ImportDataService } from '../../services/import-data.service';
import { CurrentSiteService } from '../../services/current-site.service';
import { ImportAsyncJobDialogBase } from '../async-job-base/import-async-job-base.dialog';
import { AsyncJob } from '../../models/async-job.model';
import { IJobSummaryText } from '../../models/export-import/i-job-summary-text';
import { ImportExcelDataIntoGridMetrics } from '../../models/export-import/import-excel-data-into-grid-metrics.model';
import { JOB_COMPLETED_MESSAGE } from '../async-job-base/async-job-base.dialog';

export class ImportGridDataDialogInitInfo {
    public constructor(public formInstanceElementId: number, public file: File, public fileName: string, public asyncJob: AsyncJob, public truncateExistingGridRows: boolean = true)
    {
    }
}

@Component({
    selector: 'app-import-grid-data',
    templateUrl: './import-grid-data.dialog.html',
    styleUrls: [
        '../async-job-base/async-job-base.dialog.scss',
        './import-grid-data.dialog.scss'
    ],
    standalone: false
})
export class ImportGridDataDialog extends ImportAsyncJobDialogBase implements OnInit {
    // Properties.
    private importMetrics: ImportExcelDataIntoGridMetrics = null;
    private showSaveImportedDataMessage: boolean = false;

    // Constructor.
    public constructor(public dialogRef: MatDialogRef<ImportGridDataDialog>,
        @Inject(MAT_DIALOG_DATA) public initInfo: ImportGridDataDialogInitInfo,
        private clipboard: Clipboard,
        public dialog: MatDialog,
        protected importDataService: ImportDataService,
        private injectedAsyncJobService: AsyncJobService)
    {
        super(injectedAsyncJobService);

        dialogRef.disableClose = true; // Disable closing the dialogue when the user clicks on the page.
    }

    // Life cycle methods.
    public ngOnInit(): void {
        // If the async job was started outside of this dialogue, monitor it.
        if (this.initInfo.asyncJob != null)
            this.startMonitoringJob(this.initInfo.asyncJob, this.jobCompletedOrUpdatedArrowFunction);
    }

    // Implement a base class's abstract method.
    public get ComponentClassName(): string {
        return 'ImportGridDatDialog';
    }

    // Define methods caled by my HTML code.
    public get SelectedFileName(): string {
        let charCount: number = this.initInfo.fileName != null ? this.initInfo.fileName.length : 0;
        return charCount <= 45 ? this.initInfo.fileName : this.initInfo.fileName.substring(0, 45) + ' ...';
    }

    public get ShowStartImportButton(): boolean {
        return this.initInfo.asyncJob == null;
    }
    public get DisableStartImportButton(): boolean {
        return this.initInfo.asyncJob != null;
    }

    public get DismissButtonTitle(): string {
        return 'OK';
    }

    public get ImportStatus(): string {
        //let importStatus: string = super.ImportStatus;

        let status: string = null;

        if ((!this.jobInProgress) && (this.asyncJobInfo == null)) {
            // We haven't started a copy job.
            status = 'Not started';
        } else if (this.jobInProgress) {
            // A copy job is in progress.
            status = 'Import in progress';
        } else if ((!this.jobErrorOccurred) && (this.importMetrics.numRowsFailedValidation == 0)) {
            // A copy completed without error.
            status = 'Import completed successfully';
        } else if (this.jobErrorOccurred) {
            // A copy error occurred.
            status = 'An import error occurred';
        } else {
            status = this.importMetrics.numRowsFailedValidation == 1 ? 'A data validation error occurred' : 'Data validation errors occurred.';
        }

        return status;
    }
    public get DetailedSaveResultMessage(): string {
        let message: string = '';

        if (this.JobCompleted) {
            if ((!this.JobErrorOccurred) && (this.importMetrics.numRowsFailedValidation == 0))
                message = 'Please click the OK button to review your data in the grid, then make sure to click the Save button at the bottom of the screen to ensure your data is saved appropriately.';
            else
                message = `Some data failed validation, causing no rows to import. Please resolve the validation error${this.importMetrics.numRowsFailedValidation > 1 ? 's' : ''} and re-import.`;
        } else
            message = 'A status message will display here after the import completes.';

        return message;
    }

    public get ExecutionSummaryText(): string {
        let summaryText: string = this.deriveExecutionSummaryText(this.importMetrics);

        return (summaryText);
    }

    // Override base class methods.
    public AllowMultipleFiles(): boolean {
        return (false);
    }

    //showSaveImportedDataMessage
    /*
    public get ShowSaveImportedDataMessage(): boolean {
        if (!this.showSaveImportedDataMessage)
            this.showSaveImportedDataMessage = (this.ImportStatus == JOB_COMPLETED_MESSAGE) && (this.importMetrics.numRowsFailedValidation == 0);

        return this.showSaveImportedDataMessage;
    }
    */

    public get TruncateExistingGridRows(): boolean {
        return this.initInfo.truncateExistingGridRows;
    }
    public set TruncateExistingGridRows(value: boolean) {
        this.initInfo.truncateExistingGridRows = value;
    }

    // Handle control events.
    public truncateExistingGridRowsChanged(): void {
        // Note:  this method can be handy for debugging; otherwise, it is a NOOP by design.
    }

    public startExcelGridImport(): void {
        this.importDataService.importExcelFileIntoGrid(this.initInfo.file, this.initInfo.formInstanceElementId, this.initInfo.truncateExistingGridRows).then(asyncJob => {
            this.initInfo.asyncJob = asyncJob;

            this.startMonitoringJob(this.initInfo.asyncJob, this.jobCompletedOrUpdatedArrowFunction);
        });
    }

    public dismissClicked(): void {
        this.dialogRef.close(this.initInfo);
    }
    public cancelClicked(): void {
        this.dialogRef.close(null);
    }

    public copyJobErrorTextToClipboard(): void {
        if (this.jobErrorText != null) {
            this.clipboard.copy(this.jobErrorText);
        }
    }

    // Protected methods.
    protected deriveExecutionSummaryText(metrics: IJobSummaryText): string {
        let summaryText: string = '';

        if (this.jobErrorOccurred && (this.jobErrorText != null) && (this.jobErrorText.trim() != '')) {
            summaryText = this.jobErrorText;

            if (metrics != null) {
                if ((metrics.getErrorLog() != null) && (metrics.getErrorLog().trim() != '')) {
                    summaryText += "\r\n";
                    summaryText += metrics.getErrorLog();
                } else if ((metrics.getWarningLog() != null) && (metrics.getWarningLog().trim() != '')) {
                    summaryText += "\r\n";
                    summaryText += metrics.getWarningLog();
                }
            }
        } else if (metrics != null) {
            summaryText = metrics.getJobSummaryText();
        }

        return (summaryText);
    }

    protected jobCompletedOrUpdatedArrowFunction = (asyncJob: AsyncJob): void => {
        if ((this.jobCompletedMessageText != null) && (this.jobCompletedMessageText.trim() != '')) {
            this.importMetrics = ImportGridDataDialog.parseMetrics(this.jobCompletedMessageText);
        } else if ((asyncJob.stdOut != null) && (asyncJob.stdOut.trim() != '')) {
            this.importMetrics = ImportGridDataDialog.parseMetrics(asyncJob.stdOut);
        }
    }

    private static parseMetrics(jobMetricsJson: string): ImportExcelDataIntoGridMetrics {
        let jobCompletedMessageJson: Object = JSON.parse(jobMetricsJson);
        let importMetrics: ImportExcelDataIntoGridMetrics = new ImportExcelDataIntoGridMetrics(jobCompletedMessageJson);

        return (importMetrics);
    }

}
