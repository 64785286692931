import { FormField } from '../form-field.model';
import { FormInstanceElement } from '../form-instance-element.model';
import { NumericFieldDefinitionLogicBase } from './numeric-compare-base-field-def-logic';
import { IGridRow } from '../../../interfaces/grid-row.interface';
import { FormFieldProcessingPhaseEnum } from '../../../enums/form-field-processing-phase.enum';
import { FormFieldBaseComponent } from '../../../components/form-fields/form-field-base/form-field-base.component';
import { FormInstanceGridRow } from '../../site-content/form-instance-grid-row.model';

export class IntegerFieldDefLogic extends NumericFieldDefinitionLogicBase {
    // Constructor.
    public constructor() {
        super();
    };

    // pasteDataForInto():  only allow the pasting of values that are valid for a given form field.
    public pasteDataForInto(formField: FormField, formInstanceElement: FormInstanceElement, pasteValue: string): void {
        if (pasteValue != null) {
            let intValue: number = parseInt(pasteValue);
            formInstanceElement.intValue = intValue;
        }

        // TO DO:  ADD VALIDATION TO THIS METHOD.
    }

    // Sorting.
    public compareAscendingFor(formField: FormField, value1: FormInstanceElement, value2: FormInstanceElement): number {
        let compareResult: number = 0;

        if ((value1.intValue != null) && (value2.intValue != null)) {
            if (value1.intValue == value2.intValue)
                compareResult = 0;
            else if (value1.intValue > value2.intValue)
                compareResult = 1;
            else
                compareResult = -1;
        } else if (value1.intValue != null) {
            compareResult = 1;
        } else {
            compareResult = -1;
        }

        return compareResult;
    }

    public getDisplayValue(formFieldParam: FormField, formInstanceElementParam: FormInstanceElement, gridRow: IGridRow, processingPhase: FormFieldProcessingPhaseEnum, gridRowColumnDefs: FormField[] = null): string {
        if (!formInstanceElementParam.UserUpdatedData) {
            FormFieldBaseComponent.setDefaultIntegerValueFor(formFieldParam, formInstanceElementParam);
        }

        let strValue: string =
            (formInstanceElementParam.intValue ?
                new Intl.NumberFormat('en-us', { minimumFractionDigits: 0 }).format(formInstanceElementParam.intValue) :
                '0');

        return (strValue);
    }

    public getNumericValue(formFieldParam: FormField, otherFormFields: FormField[], formInstanceElementParam: FormInstanceElement, gridRow: IGridRow, processingPhase: FormFieldProcessingPhaseEnum): number {
        if (!formInstanceElementParam.UserUpdatedData) {
            FormFieldBaseComponent.setDefaultIntegerValueFor(formFieldParam, formInstanceElementParam);
        }

        let numValue: number = 0;
        if (formInstanceElementParam.intValue) {
            if (typeof formInstanceElementParam.intValue == 'number') {
                numValue = formInstanceElementParam.intValue;
            } else {
                numValue = parseInt((<any>formInstanceElementParam.intValue).toString());
                //VNEXT-1430: KLW - Due to changes from Angular 14 to 15, NaN cannot be use directly and instead we must use the method from the Numbers class
                //Number.IsNan()
                if (Number.isNaN(numValue))
                    numValue = 0;
            }
        }
        return numValue;
    }

    // Implement protected methods.
    protected formatValueForCompare(value: any): any {
        let result: number = 0;

        if (value != null)
            result = parseInt(value.toString());

        return result;
    }

    public includeInFormTemplateInstancesGrid(): boolean {
        return true;
    }

    public unpackTemplateInstancesRow(formField: FormField, colValue: FormInstanceElement, row: FormInstanceGridRow): any {
        if (!colValue) {
            console.log("null colValue received in unpackTemplateInstancesRow for formField " + formField.name);
        }
        let fieldValue: any = colValue?.intValue != null ? colValue.intValue : 0;  
        return fieldValue;
    }

    public get canParticipateInFlexFieldBehavior(): boolean {
        return true;
    }
}
