import { Component, OnInit, Input, SimpleChange } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { Menu } from '../../shared/models/navigation/menu.model';
import { UserClaims } from '../../shared/models/user-claims.model';
import { Logging } from '../../shared/logging';

declare let $: any;

@Component({
    selector: 'home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    standalone: false
})

export class HomeComponent implements OnInit {
    @Input()
    public navMenu: Menu;

    public userClaims: UserClaims;

    constructor(private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.route.data
            .subscribe((data: { navMenu: Menu, claims: UserClaims }) => {
                this.navMenu = data.navMenu;
                this.userClaims = data.claims;
            });
    }
}
