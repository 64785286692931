export class ConfirmationDialogModel {
    constructor(public title: string, public message: string, public dialogOptions?: IConfirmationDialog) { }
}

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { IConfirmationDialog } from '../../interfaces/confirmation-dialog.interface';

@Component({
    selector: 'app-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.scss'],
    standalone: false
})

export class ConfirmationDialogComponent implements OnInit {
    title: string;
    message: string;
    dialogOptions: IConfirmationDialog

    constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogModel) {
        this.title = data.title;
        this.message = data.message;
        this.dialogOptions = data.dialogOptions;
    }

    ngOnInit() {
        if (this.dialogOptions?.seconds || this.dialogOptions?.minutes)
            this.startTimer();
    }

    startTimer() {
        let intervalId = setInterval(() => { 
            if (this.dialogOptions.seconds - 1 == -1) {
                this.dialogOptions.minutes -= 1;
                this.dialogOptions.seconds = 59
            }
            else this.dialogOptions.seconds -= 1
            if (this.dialogOptions.minutes === 0 && this.dialogOptions.seconds == 0) clearInterval(intervalId)
        }, 1000)
    }

    onConfirm(): void {
        this.dialogRef.close(true);
    }

    onDismiss(): void {
        this.dialogRef.close(false);
    }

    public get ConfirmButtonText(): string {
        let yes = this.dialogOptions?.confirmButtonText;
        return yes ? yes : (this.dialogOptions?.showOk ? 'OK' : 'Yes');
    }
    public get DismissButtonText(): string {
        let no = this.dialogOptions?.dismissButtonText;
        return no ? no : 'No';
    }
}
