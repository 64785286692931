import { ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { ProgressBarMode } from '@angular/material/progress-bar';

import {
    ProgressStatusUpdateEnum,
    ProgressUpdateInfo,
    IProgressStatusUpdateListener,
    ProgressIndicatorService
} from '../../services/progress-indicator.service';
import { ProgressBarConstants } from '../../enums/progress-bar-constants.enum';
import { CurrentSiteService } from '../../services/current-site.service';

@Component({
    selector: 'app-progress-indicator',
    templateUrl: './progress-indicator.component.html',
    styleUrls: ['./progress-indicator.component.scss'],
    standalone: false
})
export class ProgressIndicatorComponent implements OnInit, OnDestroy, IProgressStatusUpdateListener {
    // Properties.
    private mode: ProgressBarMode = ProgressBarConstants.BUFFER_MODE;
    private color: ThemePalette = ProgressBarConstants.THEME_PALETTE_WARN; 
    private progressUpdateInfo: ProgressUpdateInfo = null;
    private statusText: string = null;

    public progressListenerId: number; // Reuqired by IProgressStatusUpdateListener

    // Constructor.
    constructor(private progressIndicatorService: ProgressIndicatorService, 
                renderer: Renderer2, 
                private changeDetector: ChangeDetectorRef,
                private currentSiteService: CurrentSiteService //VNEXT-1066: KLW - Properties of the Banner
                )
    {
        // Example of removing/adding a CSS class using Renderer2:
        //
        //     this.renderer.removeClass(this.messageSpan.nativeElement, 'message-span-invisible-font');
        //     this.renderer.addClass(this.messageSpan.nativeElement, 'message-span-visible-font');

        return;
    }

    // Life cycle methods.
    public ngOnInit(): void {
        // Register for progress events.
        this.progressIndicatorService.registerListener(this);

        return;
    }

    public ngOnDestroy(): void {
        // Remove myself as a progress listener.
        return;
    }

    // Implement the IProgressStatusUpdateListener method.
    public progressUpdated(statusMessage: ProgressStatusUpdateEnum,
        statusTextParam: string,
        statusUpdate: ProgressUpdateInfo): void {
        if (statusTextParam) {
            this.statusText = statusTextParam;
        }

        switch (statusMessage) {
            case ProgressStatusUpdateEnum.PROGRESS_UPDATED:
                this.progressUpdateInfo = statusUpdate;
                break;

            case ProgressStatusUpdateEnum.PROGRESS_COMPLETED:
                this.statusText = null;
                this.progressUpdateInfo = null;
                break;

            default:
                break;
        }
        this.changeDetector.detectChanges(); // prevents "Expression has changed after it was checked" error (https://angular.io/errors/NG0100)
        return;
    }

    // Accessor methods.
    public get ProgressUpdateInfo(): ProgressUpdateInfo {
        return (this.progressUpdateInfo);
    }

    public get StatusText(): string {
        return (this.statusText);
    }

    public get Mode(): ProgressBarMode {
        return (this.mode);
    }

    public get Value(): number {
        return (this.progressUpdateInfo ? this.progressUpdateInfo.statusValue : 0);
    }
    
    public get BufferValue(): number {
        return (this.progressUpdateInfo ? this.progressUpdateInfo.bufferValue : 0);
    }

    public get Color(): ThemePalette {
        return (this.color);
    }

    //VNEXT-1066: KLW - Properties of the Banner
    public get SiteName(): string{
        let retVal: string = '';

        if (this.currentSiteService.Site)
            retVal = this.currentSiteService.Site.name;

        return retVal;
    }
}
