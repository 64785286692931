import { Component, OnInit, AfterViewInit, ViewEncapsulation, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CurrentUserService } from '../../../security/current-user.service';

@Component({
    selector: 'app-form',
    templateUrl: './printable-form.component.html',
    styleUrls: ['./printable-form.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class PrintableFormComponent implements OnInit, AfterViewInit {
    // Instance data.
    formInstanceHtml: string = null;

    // Constructor.
    constructor(private route: ActivatedRoute,
        private currentUserService: CurrentUserService,
        private router: Router) { }


    public ngOnInit(): void {
        this.route.data
            .subscribe((data: { formInstanceHtml: string }) => {
                this.formInstanceHtml = data.formInstanceHtml;
            });
    }

    public ngAfterViewInit(): void {
        window.print();
    }

    public get FormInstanceHtml() {
        return this.formInstanceHtml;
    }

    @HostListener("window:afterprint", [])
    onWindowAfterPrint() {
        console.log('... afterprint');
        window.close();
    }
}
