import { Component, OnInit, Output, EventEmitter, ViewChild, SimpleChanges } from '@angular/core';
//import { Type } from 'class-transformer';

import { FormFieldBaseComponent } from '../form-field-base/form-field-base.component';
import { HeaderValuePair } from '../../../models/csv-data/header-value-pair.model';
import { CascadingDropDownFormFieldConfig } from '../../../models/cascading-dropdown/cascading-dropdown-config.model';
import { FormFieldPropertyEnum } from '../../../models/form-builder/form-field-property-enum.model';
import { FormFieldOnInitPropertyEnum } from '../../../models/form-builder/form-field-on-init-output-property.enum';
import { FormField, DisplayFormatEnum } from '../../../models/form-builder/form-field.model';
import { CascadingDropDownFormFieldData, CachedDropDownValuesForHeader } from '../../../models/cascading-dropdown/cascading-dropdown-data.model';
import { CsvOptionsFileData } from '../../../models/csv-data/csv-options-file-data.model';
import { CurrentUserService } from '../../../../security/current-user.service';
import { FormModeEnum } from '../../../enums/form-mode.enum';
import { FormFieldConfigurationSaveReason } from '../../../enums/form-field-save-config-reason.enum';
import { Form } from '../../../models/form-builder/form.model';

class ValuesFromPriorDropDownConfig {
    public textValues: string[];

    public constructor(numHeaders: number) {
        this.textValues = [];
        for (let index: number = 0; index < numHeaders; index++)
            this.textValues.push('');
    }
}

// Define a class used for storing header/value pairs.
//import { HeaderValuePair } from '../../../models/csv-data/header-value-pair.model';
// Define a class used as the result of the loadCsvOptionsFile() method.
//import { CsvOptionsFileData } from '../../../models/csv-data/csv-options-file-data.model';


// Note:  this class presently serves as a base class for the following components:
//
//            DropDownFormFieldComponent
//            CascadingDropDownFormFieldComponent
//
//       This file contains the definition of data structure class HeaderValuePair
//       and provides a method for parsing CSV files.

// Note:  even though this component has a @Component decorator, it will never be
//        instantiated as its own component.
@Component({
    selector: 'app-drop-down-form-field-base',
    templateUrl: './empty.html',
    styleUrls: ['./drop-down-form-field-base.component.scss', '../form-fields.scss'],
    standalone: false
})
export abstract class CascadingDropDownFormFieldBaseComponent extends FormFieldBaseComponent {
    // Properties.
    @Output() onInit = new EventEmitter();

    private readonly formFieldProperties: string[] = null; // Now assigned in the constructor.

    private readonly displayFormats: string[] =
        [
            DisplayFormatEnum.VERTICAL,
            DisplayFormatEnum.HORIZONTAL
        ];

    // Notes:  this class provides the vast majority of the logic for concrete component classes
    //         CascadingDropDownFormFieldComponent and GridCascadingDropDownFormFieldComponent.
    //
    //         Presently the HTML code for both of the concrete classes resides in file
    //         cascading-drop-down-form-field.component.html, but that code will probably be
    //         moved to the .html file in this directory.
    //
    //         As the logic in the cascading dropdown components can be detailed, I am working
    //         to restrict access to the three instance variables immediately following (myConfig,
    //         dropDownData, lastHeaderSelectedIndex) as follows:
    //
    //             1. Private -- make the following three properties private so access to them
    //                           only occurs in this file;
    //             2. Protected -- provide access methods for the two derived, concrete classes;
    //             3. Public -- define methods, implemented in this and the grid cascading
    //                          component class, for access to these proeprties by .html code.
    private myConfig: CascadingDropDownFormFieldConfig = null;
    private dropDownData: CascadingDropDownFormFieldData = null;
    //private lastHeaderSelectedIndex: number = -1;
    protected jsonConfig: string = null; // Added 10-31-2022 to in not sending this configuration string to the server unnecessarily.

    protected strError: string = null;
    //protected savingToServer: boolean = false;
    private valuesFromPriorDropDownConfig: ValuesFromPriorDropDownConfig = null;

    //private formFieldClone: FormField = null;

    @ViewChild('file') file;

    // Constructor.
    public constructor(private currentUserService: CurrentUserService) {
        super();

        this.formFieldProperties = [
            FormFieldPropertyEnum.NAME,
            FormFieldPropertyEnum.FIELD_GROUP,
            FormFieldPropertyEnum.REQUIRED,
            FormFieldPropertyEnum.DISPLAY_NAME,
            FormFieldPropertyEnum.HELP_TEXT,
            FormFieldPropertyEnum.TOOL_TIP,
            FormFieldPropertyEnum.SELECT_OPTIONS_FILE,
            FormFieldPropertyEnum.DISPLAY_FORMAT,
            FormFieldPropertyEnum.INSTRUCTIONS_TEXT,
            FormFieldPropertyEnum.GRID_COLUMN_WIDTH
        ];
        if ((this.currentUserService.user != null) && this.currentUserService.user.isSystemAdmin)
            this.formFieldProperties.push(FormFieldPropertyEnum.SHOW_CASCADING_DROPDOWN_INDICES_IN_PREVIEW_MODE);

        return;
    }

    // Life cycle methods (called by my derived classes).
    public ngOnInit(): void {
        this.saveJsonConfig(this.FormField.jsonConfig);
        // If we have an existing grid configuration, use it.
        this.parseConfig();

        // Let my parent know what properties I support.
        let hshProperties = this.getProperties();
        this.onInit.emit(hshProperties);

        return;
    }

    public ngOnChanges(changes: SimpleChanges) {
    }

    public ngOnDestroy(): void {
        if (this.myConfig)
            delete this.myConfig;

        return;
    }

    // Implement an abstract method.
    public getProperties(): any {
        let formFieldProperties = (this.hasDisplayFormatProperty ? this.formFieldProperties : this.formFieldProperties.filter(p => p != FormFieldPropertyEnum.DISPLAY_FORMAT));
        let hshProperties =
        {
            component: this,
            formField: this.FormField,
            properties: formFieldProperties, //this.formFieldProperties,
            displayFormatValues: this.displayFormats,

            propertyUpdateRequired: true,
            displayPropertiesEdit: this.displayPropertiesEditIcon,

            saveConfigurationRequired: true, // Added 10-26-2022
            saveDataRequired: true // Note:  this is used only for diagnostic purposes.
        };

        // Make sure my configuration has been parsed.
        if (this.myConfig == null)
            this.parseConfig();

        // Calculate a required height based on any drop-down headers.
        let minHeight: number = this.minHeightRequired;

        hshProperties[FormFieldOnInitPropertyEnum.REQUIRED_PREVIEW_INSTANCE_MODE_HEIGHT] = minHeight;
        hshProperties[FormFieldOnInitPropertyEnum.REQUIRED_PREVIEW_INSTANCE_MODE_HEIGHT_UNIT] = 'px';

        let dropdownHeaders = this.DropDownHeaders;
        hshProperties[FormFieldOnInitPropertyEnum.ROWS_OF_TEXT_TO_DISPLAY_IN_UNSELECTED_GRID_ROW] = dropdownHeaders.length;

        return (hshProperties);
    }
    protected get hasDisplayFormatProperty(): boolean {
        return true;
    }

    protected get displayPropertiesEditIcon(): boolean {
        return true;
    }

    protected get minHeightRequired(): number {
        let minHeight: number = 100; // px
        let dropdownHeaders = this.DropDownHeaders;
        if ((dropdownHeaders != null) && (dropdownHeaders.length > 0)) {
            minHeight =
                50 // Header
                + (dropdownHeaders.length * 60) // Space for all drop-down fields.
                + ((dropdownHeaders.length - 1) * 25); // Space for filler between drop-down fields.
        }

        return minHeight;
    }

    // Accessor methods.
    public get ReadyToRender(): boolean {
        //return ((this.Config != null) && (this.Data != null));
        return this.HasConfig && this.HasData;
    }

    public get DisplayConfigTip(): boolean {
        let displayTip = ((this.DropDownHeaders.length == 0) && (this.FormField.primaryFieldName == null));
        return displayTip;
    }
    /*
    public get DisplaySavingTip(): boolean {
        return this.savingToServer;
    }
    */

    public get AdditionalMatFormFieldStyles(): string {
        return '';
    }

    public get IsHorizontal(): boolean {
        return (this.FormField.displayFormat === 'Horizontal');
    }
    public IsHorizontalAndNotFirstSelection(index: number): boolean {
        return ((this.FormField.displayFormat === 'Horizontal') && (index > 0));
    }

    public get DropDownHeaders(): string[] {
        return (this.Config ? this.Config.dropDownHeaders : []);
    }
    public get DropDownValues(): number[] {
        return (this.Data != null ? this.Data.dropDownValues : []);
    }

    public get HeaderIndexOffset(): number {
        return 0; // The grid cascading dropdown form field will override this value.
    }
    public DropDownOptionsForHeader(iHeader: number, useCaching: boolean = true): HeaderValuePair[] {
        let options: HeaderValuePair[] = [];

        // Do we have cached values?
        //
        // Note:  caching is temporarily disabled pending the testing of logic to clear cached values for subsequent headers when the prior header value changes.
        //
        // Question:  Why do we want to cache dropdown values?
        //     Answer:  this method gets called rather frequently from the template by Angular, making it difficult to troubleshoot a call for a particular header.
        //              By caching a header's values once they have been computed once, many fewer calls process beyond the following two lines, making troubleshooting
        //              such a call much easier.
        if (false && useCaching && (this.Data.cachedHeaders[iHeader].headerValuePairs != null))
            return this.Data.cachedHeaders[iHeader].headerValuePairs;

        // Do we have a previously selected text value that is not consistent/available with the current configuration?
        /*
        let previouslySelectedHeaderValuePair: HeaderValuePair = null;
        if (this.valuesFromPriorDropDownConfig != null) {
            previouslySelectedHeaderValuePair = new HeaderValuePair(-1, this.myConfig.dropDownHeaders[iHeader], this.valuesFromPriorDropDownConfig.textValues[iHeader]);
            options.push(previouslySelectedHeaderValuePair);
        }
        */

        // Handle the first select field in a unique way.
        if ((iHeader === 0) && (this.Config.dropDownOptionsAsHierarchy != null) && (this.Config.dropDownOptionsAsHierarchy.length > 0)) {
            // Do we have a previously selected text value that is not consistent/available with the current configuration?
            let previouslySelectedHeaderValuePair: HeaderValuePair = null;
            if ((this.valuesFromPriorDropDownConfig != null) && (this.valuesFromPriorDropDownConfig.textValues != null) &&
                (this.valuesFromPriorDropDownConfig.textValues[iHeader] != null) && (this.valuesFromPriorDropDownConfig.textValues[iHeader].trim() != '')) {
                previouslySelectedHeaderValuePair = new HeaderValuePair(-1, this.Config.dropDownHeaders[iHeader], this.valuesFromPriorDropDownConfig.textValues[iHeader]);
                options.push(previouslySelectedHeaderValuePair);
            }

            for (let iOption: number = 0; iOption < this.Config.dropDownOptionsAsHierarchy.length; iOption++) {
                let headerValuePair: HeaderValuePair = this.Config.dropDownOptionsAsHierarchy[iOption];

                options.push(headerValuePair);
            }
        } else if ((this.Config.dropDownOptionsAsHierarchy != null) && (iHeader < this.Config.dropDownHeaders.length)) {
            if ((this.Data != null) && (this.Data.dropDownValues != null) && (iHeader - 1 < this.Data.dropDownValues.length)) {
                // If the selection for the prior header is zero, meaning no value has yet
                // been selected, then there will be no values to select for this header.
                if (this.Data.dropDownValues[iHeader - 1] !== 0) {
                    // Get any previous selected values for all headers after the first/zeroeth header.
                    let previouslySelectedHeaderValuePairs: HeaderValuePair[] = [];
                    for (let index: number = 0; index < this.Config.dropDownHeaders.length; index++) {
                        //if ((this.valuesFromPriorDropDownConfig != null) && (this.valuesFromPriorDropDownConfig.textValues != null) && (index > 0) && (this.valuesFromPriorDropDownConfig.textValues[index] != null) && (this.valuesFromPriorDropDownConfig.textValues[index].trim() != ''))
                        if ((this.valuesFromPriorDropDownConfig != null) && (this.valuesFromPriorDropDownConfig.textValues != null) && (this.valuesFromPriorDropDownConfig.textValues[index] != null) && (this.valuesFromPriorDropDownConfig.textValues[index].trim() != ''))
                            previouslySelectedHeaderValuePairs[index] = new HeaderValuePair(-1, this.Config.dropDownHeaders[index], this.valuesFromPriorDropDownConfig.textValues[index]);
                        else
                            previouslySelectedHeaderValuePairs[index] = null;
                    }

                    // Get the value for the prior header.
                    //options = CascadingDropDownFormFieldBaseComponent.findOptionsValuesForHeader(iHeader, this.Config.dropDownOptionsAsHierarchy, this.Data.dropDownValues);
                    options = CascadingDropDownFormFieldBaseComponent.findOptionsValuesForHeader(iHeader, this.Config.dropDownOptionsAsHierarchy, this.Data.dropDownValues, 0, previouslySelectedHeaderValuePairs);
                } // if
            } // if
        } // else if

        // Cache options.
        if ((this.Data.cachedHeaders != null) && (this.Data.cachedHeaders.length > iHeader)) {
            if (iHeader > 0)
                this.Data.cachedHeaders[iHeader].previousHeaderSelectedIndex = this.Data.dropDownValues[iHeader - 1];
            this.Data.cachedHeaders[iHeader].headerValuePairs = options;
        }

        // Return options.
        return (options);
    }

    private static getPreviouslySelectedHeaderValuePair(dropDownHeaders: string[], valuesFromPriorDropDownConfig: ValuesFromPriorDropDownConfig, iHeader: number): HeaderValuePair {
        let previouslySelectedHeaderValuePair: HeaderValuePair = null;
        if ((valuesFromPriorDropDownConfig != null) && (valuesFromPriorDropDownConfig.textValues.length > iHeader) &&
            (valuesFromPriorDropDownConfig.textValues[iHeader] != null) && (valuesFromPriorDropDownConfig.textValues[iHeader].trim() != '')) {
            previouslySelectedHeaderValuePair = new HeaderValuePair(-1, dropDownHeaders[iHeader], valuesFromPriorDropDownConfig.textValues[iHeader]);
        }

        return previouslySelectedHeaderValuePair;
    }

    public HeaderSelectionDisabled(headerIndex: number, headerValuePair: HeaderValuePair): boolean {
        let disabled: boolean = this.mode == 'design';

        if (!disabled) {
            if (headerIndex > this.LastHeaderSelectedIndex + 1)
                disabled = true;
        }

        return disabled;
    }

    public get UsesMainFieldLabel(): boolean {
        //return this.mode == 'design';
        return true; // Note:  this is the default behavior.  It can be overriden by derived classes.
    }

    public get Error(): string {
        return (this.strError);
    }

    public get IsPrimaryCascadingField(): boolean {
        return true;
    }
    public get IsSecondaryCascadingField(): boolean {
        return false;
    }

    public get HasConfig(): boolean {
        return true;
    }
    public get Config(): CascadingDropDownFormFieldConfig {
        return this.myConfig;
    }
    public get HasData(): boolean {
        return true;
    }
    public get Data(): CascadingDropDownFormFieldData {
        //return this.myData;
        return this.dropDownData;
    }
    public get LastHeaderSelectedIndex(): number {
        //return this.lastHeaderSelectedIndex;
        return (this.Data != null ? this.Data.lastHeaderSelectedIndex : -1);
    }
    public set LastHeaderSelectedIndex(value: number) {
        //this.lastHeaderSelectedIndex = value;
        if (this.HasData)
            this.Data.lastHeaderSelectedIndex = value;
    }
    // End methods called by HTML code.

    // Protected access methods.
    protected get ProtectedAccessConfig(): CascadingDropDownFormFieldConfig {
        return this.myConfig;
    }
    protected get ProtectedAccessDropDownData(): CascadingDropDownFormFieldData {
        return this.dropDownData;
    }
    protected get ProtectedAccessLastHeaderSelectedIndex(): number {
        //return this.lastHeaderSelectedIndex;
        return (this.Data != null ? this.Data.lastHeaderSelectedIndex : -1);
    }
    protected set ProtectedAccessLastHeaderSelectedIndex(value: number) {
        //this.lastHeaderSelectedIndex = value;
        if (this.HasData)
            this.Data.lastHeaderSelectedIndex = value;
    }

    // Handle configuration events.
    public getSelectedValue(value: string, optionIndex: number, headerIndex: number): string {
        // Note:  this method can be difficult to debug, so, at least for the time being,
        //        this method will log a warning if it encounters a problematic condition.
        let result: string = null;
        let warning: string = null;

        if ((this.Data != null) &&
            (this.Data.dropDownValues != null) && (headerIndex < this.Data.dropDownValues.length) &&
            (this.Data.dropDownSavedValues != null) && (headerIndex < this.Data.dropDownSavedValues.length)) {
            let selectedOptionIndex = this.Data.dropDownValues[headerIndex];
            let savedValue = this.Data.dropDownSavedValues[headerIndex];

            let needToShowIndicesInValues: boolean = ((this.FormField.transientShowCascadingDropdownIndicesInPreviewMode == true) && (this.mode == 'preview'));
            if (selectedOptionIndex == optionIndex) {
                //return savedValue || value;
                if (savedValue != null) {
                    // We have a saved value, so use it.
                    result = savedValue;
                } else if (needToShowIndicesInValues) {
                    result = `${optionIndex}: ${value}`;
                } else {
                    result = value;
                }
            } else {
                if (needToShowIndicesInValues) {
                    result = `${optionIndex}: ${value}`;
                } else {
                    result = value;
                }
            }
        } else if (this.Data == null)
            warning = 'getSelectedValue():  this.myData is null.';
        else if (this.Data.dropDownValues == null)
            warning = 'getSelectedValue():  this.myData.dropDownValues is null.';
        else if (headerIndex >= this.Data.dropDownValues.length)
            warning = 'getSelectedValue():  headerIndex >= this.myData.dropDownValues.length.';
        else if (this.Data.dropDownSavedValues == null)
            warning = 'getSelectedValue():  this.myData.dropDownSavedValues is null.';
        else if (headerIndex >= this.Data.dropDownSavedValues.length)
            warning = 'getSelectedValue():  headerIndex >= this.myData.dropDownSavedValues.length.';

        // Is there a warning?
        if (warning != null) {
            return value; // Perform the default/expected behavior.
        }

        return result;
    }

    // Handle the propertyUpdated() callback
    // that was requested in the onOnit() data:
    //
    //     propertyUpdateRequired: true
    public propertyUpdated(formField: FormField, propertyName: string): void {
        if ((propertyName == FormFieldPropertyEnum.SELECT_OPTIONS_FILE) ||
            (propertyName == FormFieldPropertyEnum.SELECTED_CASCADING_DROPDOWN_CONSTRAINT)) {
            // Copy values.
            if (formField.jsonConfig != this.FormField.jsonConfig)
                this.FormField.jsonConfig = formField.jsonConfig;
            if (formField.csvOptionsData != this.FormField.csvOptionsData)
                this.FormField.csvOptionsData = formField.csvOptionsData;

            if (this.FormField.csvOptionsData != null) {
                this.processCsvOptionsFile(this.FormField.csvOptionsData);
                this.csvOptionsFileProcessed();
            } else if ((this.FormField.jsonConfig != null) && (this.FormField.jsonConfig.trim() != '')) {
                this.saveJsonConfig(this.FormField.jsonConfig);
                //this.myConfig = CascadingDropDownFormFieldConfig.parseConfig(this.FormField);
                this.myConfig = CascadingDropDownFormFieldConfig.parseJsonConfig(this.jsonConfig);
                this.dropDownData = CascadingDropDownFormFieldData.createEmptyDataFromConfig(this.Config);
            }
            else {
                // Need to reset the field's state to have no cascading dropdown config.
                this.myConfig = null;
            }
        }

        return;
    }

    protected csvOptionsFileProcessed(): void {
        // Note:  a derived class can override this method to do something meaningful to that class.

        // Now that the CSV data has been processed, it can be set to null.
        //this.formField.csvOptionsData = null;
        this.saveJsonConfig(this.FormField.jsonConfig);
    }

    protected formInstanceElementReceived(): void {
        if (this.Mode === 'preview') {
        } else if (this.Mode === 'instance') {
            if (this.FormInstanceElement.textValue && (this.FormInstanceElement.textValue.trim() !== '')) {
                this.unpackFormInstanceElement();
            }
        }

        return;
    }

    //TEAMS-561: KLW - Implement the first instance of writeValueTrigger which will eventually replace formInstanceElementReceived
    protected writeValueTriggered(): void {
        if (this.Mode === 'preview') {
        } else if (this.Mode === 'instance') {
            if (this.FormInstanceElement.textValue && (this.FormInstanceElement.textValue.trim() !== '')) {
                this.unpackFormInstanceElement();
            }
        }

        return;
    }

    public saveConfiguration(form: any, reasonForSave: FormFieldConfigurationSaveReason): void {
        if ((this.mode == FormModeEnum.DESIGN) && (this.jsonConfig != null)) {
            this.FormField.cascadingDropdownConstraintValue = this.jsonConfig;
            if (reasonForSave == FormFieldConfigurationSaveReason.SavingFormToServer) {
                this.preparingToSaveConfigToServer(<Form>form);
            }
        }

        return;
    }
    public saveConfigurationCompleted(form: any): void {
        //this.formFieldClone = null;
    }
    protected preparingToSaveConfigToServer(form: Form): void {
        this.FormField.jsonConfig = null;

        if ((form != null) && (form.formFields != null)) {
            // Replace my form field in the form being saved with a clone.
            //this.formFieldClone = <FormField>this.formField.clone();
        }
    }

    public saveData(formInstance: any): void {
        // Note:  this method is only used for diagnostic purposes.
        // Clear any cached dropdown values.
        if (this.HasConfig && this.HasData) {
            //for (let index: number = 0; index < this.Config.dropDownHeaders.length; index++)
            //    this.Data.cachedHeaders[index] = new CachedDropDownValuesForHeader(index);
            this.Data.emptyCache(this.Config);
            if (this.Data.cachedHeaders != null)
                this.Data.cachedHeaders = undefined;

            if (this.Data.formInstanceElements != null)
                this.Data.formInstanceElements = null;

            this.saveChangedDataToFormInstanceElement();
        }
    }
    public saveCompleted(formInstance: any): void {
        // Note:  this method is only used for diagnostic purposes.
        return;
    }

    // Note:  breaking out method unpackFormInstanceElement() from method formInstanceElementReceived()
    //        so that a derived class, e.g. the grid cascading dropdown class, can override this method.
    protected unpackFormInstanceElement(): void {
        // VNEXT-409 IMPLEMENTATION NODE:  if the value coming from the server is not in the list of available values:
        //
        //                                 1. Add an additional selectable value, with an index value of -1, to each
        //                                    header's list of selectable values;
        //                                 2. Set all of the selected index values to -1;
        //
        //                                 3. Add value selected logic, in another method or methods, to handle
        //                                    the selection of the -1 value differently:
        //
        //                                       If the first headers -1 value is selected, set all other header
        //                                       selected index values to -1.
        this.dropDownData = CascadingDropDownFormFieldData.deserializeDropDownData(this.FormInstanceElement.textValue, this.Config);

        if ((this.dropDownData.dropDownTextValues != null) && (this.dropDownData.dropDownTextValues.length > 0) &&
            (this.dropDownData.dropDownTextValues[0] != null) && (this.dropDownData.dropDownTextValues[0].trim() != '')) {
            //let index: number = 0;
            let textValue: string = this.dropDownData.dropDownTextValues[0];
            //let dropDownOptionsForHeader: HeaderValuePair[] = this.DropDownOptionsForHeader(index, false);
            let dropDownOptionsForHeader: HeaderValuePair[] = this.Config.dropDownOptionsAsHierarchy;
            let dropDownOption: HeaderValuePair = dropDownOptionsForHeader.find(o => o.ValueText == textValue);

            if (dropDownOption == null) {
                this.valuesFromPriorDropDownConfig = new ValuesFromPriorDropDownConfig(this.myConfig.dropDownHeaders.length);
                for (let index: number = 0; index < this.myConfig.dropDownHeaders.length; index++) {
                    textValue = this.dropDownData.dropDownTextValues[index];
                    if ((textValue != null) && (textValue.trim() != '')) {
                        this.valuesFromPriorDropDownConfig.textValues[index] = textValue;
                        //this.dropDownData.dropDownValues[index] = -1; // This is the index for previously selected values for an old configuration.
                        this.dropDownData.setDropDownValue(index, -1);
                    } else
                        break;
                }
            }
        }
        /*
        else {
            // Use the original, index-based values.
            for (let index: number = 0; index < this.dropDownData.dropDownValues.length - 1; index++) {
                let dropDownValue: number = this.dropDownData.dropDownValues[index];

                if (dropDownValue > 0) {
                    if (index > this.lastHeaderSelectedIndex)
                        this.lastHeaderSelectedIndex = index;
                } else {
                    break;
                }
            }
        }
        */
        // Set property 'lastHeaderSelectedIndex';
        for (let index: number = 0; index < this.dropDownData.dropDownValues.length - 1; index++) {
            let dropDownValue: number = this.dropDownData.dropDownValues[index];

            if (dropDownValue > 0) {
                //if (index > this.lastHeaderSelectedIndex)
                if (index > this.LastHeaderSelectedIndex)
                    //this.lastHeaderSelectedIndex = index;
                    this.LastHeaderSelectedIndex = index;
            } else {
                break;
            }
        }

        // Clear any cached dropdown values.
        this.dropDownData.emptyCache(this.Config);
    }

    public handlingEditPropertiesClickEvent(): void {
        if (this.myConfig != null) {
            if ((this.FormField.jsonConfig == null) || (this.FormField.jsonConfig.trim() == ''))
                this.FormField.jsonConfig = JSON.stringify(this.myConfig);
        }
    }

    // Define methods for managing controls.
    public dropDownValueChanged(iHeaderParam: number): void {
        // 11-07-2022 new code:  save the corresponding text value.
        let dropDownValuesForHeader: HeaderValuePair[] = this.DropDownOptionsForHeader(iHeaderParam);
        let localDropDownData: CascadingDropDownFormFieldData = this.Data;

        if ((localDropDownData.dropDownValues != null) && (iHeaderParam < localDropDownData.dropDownValues.length) &&
            (localDropDownData.dropDownValues[iHeaderParam] > 0) && (localDropDownData.dropDownValues[iHeaderParam] <= dropDownValuesForHeader.length)) {
            let selectedOneBasedIndexValue: number = localDropDownData.dropDownValues[iHeaderParam];
            let selectedHeaderValuePair: HeaderValuePair = dropDownValuesForHeader[selectedOneBasedIndexValue - 1];
            localDropDownData.dropDownTextValues[iHeaderParam] = selectedHeaderValuePair.ValueText;
        } else {
            localDropDownData.dropDownTextValues[iHeaderParam] = '';
        }

        // Clear the cache for headers after the current header.
        this.Data.clearCacheValuesPastHeader(iHeaderParam);

        // Zero out the selections for any subsequent headers.
        // Note:  the following, commented out logic is not yet working ... need to investigate, fix.
        let lastHeaderSelected: number = iHeaderParam;
        for (let iHeader: number = iHeaderParam + 1; iHeader < this.Data.dropDownValues.length; iHeader++) {
            let headerOptions = this.DropDownOptionsForHeader(iHeader, false);

            if (headerOptions.length == 1) {
                this.Data.dropDownValues[iHeader] = headerOptions[0].Index;
                this.Data.dropDownTextValues[iHeader] = headerOptions[0].ValueText;
                this.headerValueChanged(iHeader, headerOptions[0].Index);
                lastHeaderSelected = iHeader;
            }
            else {
                //this.Data.dropDownValues[iHeader] = 0;
                this.Data.setDropDownValue(iHeader, 0);
                this.Data.dropDownTextValues[iHeader] = '';
                this.headerValueChanged(iHeader, 0);
            }
        }
        this.LastHeaderSelectedIndex = lastHeaderSelected;
        this.Data.lastHeaderSelectedIndex = lastHeaderSelected;

        // Update my form instance element's 'TextValue' attribute with
        // JSON data that corresponds to the current selections.
        this.saveChangedDataToFormInstanceElement();

        // Clear some cached values.
        for (let index: number = iHeaderParam + 1; index < this.Data.cachedHeaders.length; index++)
            this.Data.cachedHeaders[index] = new CachedDropDownValuesForHeader(index);

        return;
    }
    protected headerValueChanged(iHeader: number, selectedValue: number): void {
        // Note:  this method can be overriden by derived classes.
    }
    protected saveChangedDataToFormInstanceElement(): void {
        // Update my form instance element's 'TextValue' attribute with
        // JSON data that corresponds to the current selections.
        this.FormInstanceElement.TextValue = JSON.stringify(this.Data);
    }

    public handleOnModelChange(): void {
        // this sets all "saved" values to null
        this.Data.dropDownSavedValues = CascadingDropDownFormFieldData.createDropDownSavedValuesFromHeaders(this.Data.dropDownHeaders);
        super.handleOnModelChange();
    }

    // Define/implement protected methods.
    protected parseConfig(): void {
        if ((this.jsonConfig != null) && (this.jsonConfig.trim() != '')) {
            this.myConfig = CascadingDropDownFormFieldConfig.parseJsonConfig(this.jsonConfig);

            /*
            if (this.mode == FormModeEnum.INSTANCE) {
                // Set the config values to null as we do not need them anymore.
                this.formField.jsonConfig = null;
                this.formField.cascadingDropdownConstraintValue = null;
            }
            */

            if (this.FormInstanceElement.textValue && (this.FormInstanceElement.textValue.trim() !== '')) {
                this.unpackFormInstanceElement();
            } else {
                this.dropDownData = CascadingDropDownFormFieldData.createEmptyDataFromConfig(this.myConfig);
            }
        }
    }
    //protected static doParseConfig(formField: FormField): CascadingDropDownFormFieldConfig {
    protected doParseConfig(formField: FormField): CascadingDropDownFormFieldConfig {
        let config: CascadingDropDownFormFieldConfig = null;

        if ((formField != null) && (formField.jsonConfig != null) && (formField.jsonConfig.trim() !== '')) {
            //this.saveJsonConfig(this.formField.jsonConfig);
            this.saveJsonConfig(formField.jsonConfig);
            //config = CascadingDropDownFormFieldConfig.parseConfig(formField);
            config = CascadingDropDownFormFieldConfig.parseJsonConfig(this.jsonConfig);
        }

        return config;
    }

    //protected static findOptionsValuesForHeader(iHeader: number, ancestorHeaderValuePairs: HeaderValuePair[], ancestorDropDownValues: number[], iCurrentAncestor: number = 0): HeaderValuePair[] {
    protected static findOptionsValuesForHeader(iHeader: number, ancestorHeaderValuePairs: HeaderValuePair[], ancestorDropDownValues: number[], iCurrentAncestor: number, previouslySelectedHeaderValuePairs: HeaderValuePair[]): HeaderValuePair[] {
        // Initialize the result array.
        let resultHeaderValuePairs: HeaderValuePair[] = [];

        // Find the ancestor for the current header/level.
        let iAncestorDropDownValue = ancestorDropDownValues[iCurrentAncestor];
        //let ancestorPreviouslySelectedHeaderValuePair: HeaderValuePair = CascadingDropDownFormFieldBaseComponent.getPreviouslySelectedValueForHeader(iCurrentAncestor, previouslySelectedHeaderValuePairs, ancestorDropDownValues[iCurrentAncestor]);
        let ancestorPreviouslySelectedHeaderValuePair: HeaderValuePair = CascadingDropDownFormFieldBaseComponent.getPreviouslySelectedValueForHeader(iHeader - 1, previouslySelectedHeaderValuePairs, ancestorDropDownValues[iCurrentAncestor]);
        /*
        if ((previouslySelectedHeaderValuePairs != null) && (ancestorDropDownValues[iCurrentAncestor] == -1) && (previouslySelectedHeaderValuePairs.length > iCurrentAncestor) && (previouslySelectedHeaderValuePairs[iCurrentAncestor] != null))
            ancestorPreviouslySelectedHeaderValuePair = previouslySelectedHeaderValuePairs[iCurrentAncestor];
        */
        //let previouslySelectedHeaderValuePair: HeaderValuePair = CascadingDropDownFormFieldBaseComponent.getPreviouslySelectedValueForHeader(iCurrentAncestor + 1, previouslySelectedHeaderValuePairs, ancestorDropDownValues[iCurrentAncestor]);
        let previouslySelectedHeaderValuePair: HeaderValuePair = CascadingDropDownFormFieldBaseComponent.getPreviouslySelectedValueForHeader(iHeader, previouslySelectedHeaderValuePairs, ancestorDropDownValues[iCurrentAncestor]);
        /*
        if ((previouslySelectedHeaderValuePairs != null) && (ancestorDropDownValues[iCurrentAncestor] == -1) && (previouslySelectedHeaderValuePairs.length > iCurrentAncestor + 1) && (previouslySelectedHeaderValuePairs[iCurrentAncestor + 1] != null))
            previouslySelectedHeaderValuePair = previouslySelectedHeaderValuePairs[iCurrentAncestor + 1];
        */

        /*
        for (let iAncestor: number = 0; iAncestor < ancestorHeaderValuePairs.length; iAncestor++) {
            let ancestorHeaderValuePair: HeaderValuePair = ancestorHeaderValuePairs[iAncestor];

            if (ancestorHeaderValuePair.Index === iAncestorDropDownValue) {
                let iNextAncestor: number = iCurrentAncestor + 1;

                if (iNextAncestor < iHeader) {
                    resultHeaderValuePairs = CascadingDropDownFormFieldBaseComponent.findOptionsValuesForHeader(iHeader, ancestorHeaderValuePair.ChildValuePairs, ancestorDropDownValues, iCurrentAncestor + 1);
                } else {
                    resultHeaderValuePairs = ancestorHeaderValuePair.ChildValuePairs;
                }

                break;
            }
        }
        */
        if ((ancestorPreviouslySelectedHeaderValuePair != null) && (previouslySelectedHeaderValuePair != null)) {
            resultHeaderValuePairs = [previouslySelectedHeaderValuePair];
        } else {
            let ancestorHeaderValuePair: HeaderValuePair = ancestorHeaderValuePairs.find(hvp => hvp.Index == iAncestorDropDownValue);

            if (ancestorHeaderValuePair != null) {
                let iNextAncestor: number = iCurrentAncestor + 1;
                if (iNextAncestor < iHeader)
                    //resultHeaderValuePairs = CascadingDropDownFormFieldBaseComponent.findOptionsValuesForHeader(iHeader, ancestorHeaderValuePair.ChildValuePairs, ancestorDropDownValues, iCurrentAncestor + 1);
                    resultHeaderValuePairs = CascadingDropDownFormFieldBaseComponent.findOptionsValuesForHeader(iHeader, ancestorHeaderValuePair.ChildValuePairs, ancestorDropDownValues, iCurrentAncestor + 1, previouslySelectedHeaderValuePairs);
                else
                    resultHeaderValuePairs = ancestorHeaderValuePair.ChildValuePairs;
            }
        }

        return resultHeaderValuePairs;
    }

    private static getPreviouslySelectedValueForHeader(headerIndex: number, previouslySelectedHeaderValuePairs: HeaderValuePair[], currentAncestorSelectedIndex: number): HeaderValuePair {
        let previouslySelectedHeaderValuePair: HeaderValuePair = null;
        if ((previouslySelectedHeaderValuePairs != null) && (currentAncestorSelectedIndex == -1) && (previouslySelectedHeaderValuePairs.length > headerIndex) && (previouslySelectedHeaderValuePairs[headerIndex] != null))
            previouslySelectedHeaderValuePair = previouslySelectedHeaderValuePairs[headerIndex];
        return previouslySelectedHeaderValuePair;
    }

    protected createEmptyDataFromConfig(): void {
        this.dropDownData = CascadingDropDownFormFieldData.createEmptyDataFromConfig(this.myConfig);
    }

    // Private methods.
    private processCsvOptionsFile(csvOptions: CsvOptionsFileData): void {
        this.myConfig = CascadingDropDownFormFieldConfig.createConfigFromCsvOptionsData(csvOptions);
        this.dropDownData = CascadingDropDownFormFieldData.createEmptyDataFromConfig(this.myConfig);

        // Update my configurations.
        this.FormField.jsonConfig = JSON.stringify(this.myConfig);

        return;
    }

    protected saveJsonConfig(jsonConfig: string): void {
        if ((jsonConfig != null) && (jsonConfig.trim() != ''))
            this.jsonConfig = jsonConfig;

        // For now, set the JSON config string sto null only in 'instance' mode.
        if (this.FormField != null) {
            this.FormField.csvOptionsData = null;
            this.jsonConfigSaved();
        }
    }

    protected jsonConfigSaved(): void {
        if (this.mode == FormModeEnum.INSTANCE) {
            this.FormField.jsonConfig = null;
            this.FormField.cascadingDropdownConstraintValue = null;
        }
    }
}
